import { FormattedMessage, useIntl } from 'react-intl';
import { type TabProps } from '@m/alchemy-ui';
import { type IActivity } from '../../../../../common/interfaces';
import { TabbedPanel } from '../TabbedPanel/TabbedPanel';
import { Alert, List } from '../common/styles';
import { ActivityItem } from './ActivityItem';

interface IActivityProps {
  data: IActivity[];
  panelSize: number;
  hasWideSibling: boolean;
}

export const Activity = ({ data, panelSize, hasWideSibling }: IActivityProps) => {
  const intl = useIntl();

  const activities = data?.map((activity) => (
    <ActivityItem key={activity.event.id} data={activity} panelSize={panelSize} hasWideSibling={hasWideSibling} />
  ));
  const tabs = [
    {
      label: intl.formatMessage({ id: 'activity', defaultMessage: 'Activity' }),
      value: 'activity',
      layout: 'left',
      isActive: true
    } as TabProps
  ];
  const noActivitiesMessage = (
    <FormattedMessage id="page.thereIsNoActivityToShowYet." defaultMessage="There is no activity to show yet." />
  );

  const composedTabViewData = () =>
    activities?.length === 0 ? (
      <Alert>{noActivitiesMessage}</Alert>
    ) : (
      <>
        <List>{activities}</List>
      </>
    );

  return (
    <div data-testid="activity-tab-panel">
      <TabbedPanel tabs={tabs} content={composedTabViewData()} defaultActiveTab="activity" />
    </div>
  );
};
