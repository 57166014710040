import { useCallback, useEffect, useState } from 'react';
import { PlayPauseButton } from './PlayPauseButton';
import { CarouselContainer, CarouselSlides, Dot, DotsContainer, NavButton } from './Carousel.styled';

interface CarouselProps {
  slides: JSX.Element[];
}

export const Carousel = ({ slides }: CarouselProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  const nextSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  }, [slides.length]);

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  useEffect(() => {
    if (isPlaying) {
      const timer = setInterval(nextSlide, 5000);
      return () => clearInterval(timer);
    }
    return () => {};
  }, [isPlaying, nextSlide]);

  return (
    <div id="carousel-container">
      <CarouselContainer data-testid="carousel-container">
        <CarouselSlides data-testid="carousel-slides" currentSlide={currentSlide}>
          {slides}
        </CarouselSlides>
        <NavButton data-testid="carousel-left-nav-arrow" left onClick={prevSlide}>
          {String.fromCodePoint(10_094)}
        </NavButton>
        <NavButton data-testid="carousel-right-nav-arrow" onClick={nextSlide}>
          {String.fromCodePoint(10_095)}
        </NavButton>
      </CarouselContainer>
      <DotsContainer data-testid="carousel-dot-container">
        <PlayPauseButton isPlaying={isPlaying} togglePlayPause={togglePlayPause} />
        {slides.map((_, index) => (
          <Dot
            key={_.key}
            active={currentSlide === index}
            onClick={() => setCurrentSlide(index)}
            data-testid={`carousel-dot-${index}`}
          />
        ))}
      </DotsContainer>
    </div>
  );
};
