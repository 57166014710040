import { Flex, OverflowScroller, Tab, type TabProps, Tabs } from '@m/alchemy-ui';
import { useId, useState } from 'react';
import styled from 'styled-components';
import { ContentContainer, Footer, TabbedPanelContainer } from './TabbedPanel.styled';

interface ITabbedPanel {
  tabs: TabProps[];
  header?: JSX.Element;
  content: JSX.Element;
  footer?: JSX.Element;
  defaultActiveTab?: string;
  customMaxHeight?: number;
  onTabChange?: (tabName: string) => void;
}

export const TabbedPanel = ({
  tabs,
  content,
  footer,
  defaultActiveTab,
  header,
  customMaxHeight,
  onTabChange = () => {}
}: ITabbedPanel) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  return (
    <TabbedPanelContainer data-testid="tabbed-panel-container">
      <Tabs data-testid="tabbed-panel-tabs">
        <OverflowScrollerStyled id={`tabbed-overflow-scroller_${useId()}`}>
          <Flex style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {tabs.map((tab) => (
              <Tab
                data-testid={`tabbed-panel-tab-${tab.value}`}
                key={tab.value}
                layout="left"
                label={tab.label}
                value={tab.value}
                isActive={activeTab === tab.value}
                onClick={() => {
                  onTabChange(tab.value);
                  setActiveTab(tab.value);
                }}
              />
            ))}
          </Flex>
        </OverflowScrollerStyled>
      </Tabs>
      <div>{header}</div>
      <ContentContainer data-testid={`tabbed-panel-content-${activeTab}-items`} customMaxHeight={customMaxHeight}>
        {content}
      </ContentContainer>
      <Footer>{footer}</Footer>
    </TabbedPanelContainer>
  );
};

// Fix for overflowing tabs requested - https://jira.medallia.com/browse/DES-4298
// Workaround to hide hardcoded scroll in OverflowScroller, to remove, read below comment
const OverflowScrollerStyled = styled(OverflowScroller)`
  > div:last-of-type {
    overflow: hidden;
  }
`;

// Use of OverflowScroller/Flex is a workaround for a missing scrolled tab feature in Tabs component,
// replace Tabs with below Tabs and enable scrolled, when fixed by Alchemy team
// After restore - remember to keep data-testid !!!
/*
<Tabs
  tabs={tabs}
  onTabClick={(_, tabData) => {
    const tabName = tabData.value;
    onTabChange(tabName);
    setActiveTab(tabName);
  }}
  activeTabValue={activeTab}
/>;
*/
