import { borderRadius, Button, color, MenuButton, typeStyle, units } from '@m/alchemy-ui';
import styled from 'styled-components';

export const LanguageSelector = styled(MenuButton)`
  position: relative;
  margin-left: ${units(1)};
  padding: 0 ${units(1)};
  background: ${color('hint', { palette: 'illustrativeDeepPurple' })};
  color: ${color('typeBody')};
  font: ${typeStyle('captionS')};
  font-size: ${units(1.5)};
  text-transform: uppercase;
  &:hover {
    /* !important here as hover is set by 'data-whatinput' which is on <html> */
    /* Could possibly include illustrativeDeepPurple in theme or create new theme just for this */
    background: ${color('hint', { palette: 'illustrativeDeepPurple' })} !important;
    text-decoration: underline;
  }
  svg {
    display: none;
  }
`;

export const AllButton = styled(Button)`
  overflow: hidden;
`;

export const LanguageSelectorMenu = styled.div`
  position: absolute;
  top: calc(${units(3)} * -1);
  border-radius: ${borderRadius('large')};
  background: ${color('hint', { palette: 'illustrativeDeepPurple' })};
`;

export const LanguageSelectorMenuItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  button {
    display: block;
    width: 100%;
    border: 0;
    border-radius: ${borderRadius('large')};
    color: ${color('typeBody')};
    font: ${typeStyle('captionS')};
    font-size: ${units(1.5)};
    text-transform: uppercase;
    &:hover,
    &:focus {
      /* See above note on important */
      background: ${color('hint', { palette: 'illustrativeDeepPurple' })} !important;
      text-decoration: underline;
    }
  }
  &:first-child {
    button:first-child {
      &:hover,
      &:focus {
        border-radius: ${borderRadius('large')} 0 0 0;
      }
    }
  }
  &:last-child {
    button:first-child {
      &:hover,
      &:focus {
        border-radius: 0 0 0 ${borderRadius('large')};
      }
    }
  }
  &:hover {
    ${AllButton} {
      display: block;
    }
  }
  ${AllButton} {
    display: none;
    position: absolute;
    right: ${units(1)};
    width: auto;
    transform: translateX(100%);
    border-radius: 0 ${borderRadius('large')} ${borderRadius('large')} 0;
    background: ${color('hint', { palette: 'illustrativeDeepPurple' })};
    z-index: -1;
  }
`;
