import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, DateRangePicker, LoadingIndicator } from '@m/alchemy-ui';
import { Chart } from 'react-google-charts';
import { type GoogleDataTableColumn, type GoogleDataTableRow } from 'react-google-charts/dist/types';
import { useIntl } from 'react-intl';
import { GET_ACTIVITY, GET_COUNTRIES, type GetActivityQuery, type GetCountriesQuery } from '../../graphql/queries';
import { defaultTypes, StatType, StatTypeOptions, StatTypeScoreTitle } from '../../common/enums';
import { useUserFields } from '../../hooks';
import { CommunityContext } from '../../context';
import { type StatResultType } from '../../common/core-api';
import { CustomFilters } from './CustomFilters';
import { type UserFieldType } from './types';
import { CheckboxFilter, Score } from './components';
import { Grid, GridCol, GridHeader, GridRow } from './Analytics.styled';
import * as Styled from './components/SearchFilters.styled';

export const ActivityOverview = () => {
  const intl = useIntl();
  const community = useContext(CommunityContext);
  const { data: communityUserFields } = useUserFields();

  const formatDate = (date: Date) => date.toISOString().slice(0, 10);

  let initialEndDate = new Date(Date.now());
  initialEndDate = new Date(formatDate(initialEndDate).replace(/-/g, '/'));
  const [endDate, setEndDate] = useState(initialEndDate);
  const initialStartDate = new Date(Date.now());
  initialStartDate.setDate(initialStartDate.getDate() - 28);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  const defaultUserFieldOptions: UserFieldType = {};
  const [userFieldOptions, setUserFieldOptions] = useState(defaultUserFieldOptions);

  const [countries, setCountries] = useState<string[]>([]);
  const [skip, setSkip] = useState<boolean>(false);
  const [types, setTypes] = useState<number[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<number[]>([]);
  const [statsData, setStatsData] = useState<GetActivityQuery>({ stats: [] });

  const { data: countriesList } = useQuery<GetCountriesQuery>(GET_COUNTRIES, {
    skip,
    variables: { id: community.id }
  });

  useEffect(() => {
    if (countriesList?.community) {
      countriesList.community && setCountries(countriesList.community.countries as string[]);
    }
  }, [countriesList]);

  const variables = {
    type: types.length === 0 ? defaultTypes : types,
    startDate,
    endDate,
    filters: { userFields: JSON.stringify(userFieldOptions) }
  };

  const { loading, data } = useQuery<GetActivityQuery>(GET_ACTIVITY, {
    skip,
    variables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (!skip && data?.stats) {
      setSelectedTypes(types);
      setStatsData(data);
      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);
    }
  }, [skip, data, types, startDate, endDate]);

  if (loading && !data) {
    return (
      <Styled.LoadingContainer>
        <LoadingIndicator inline />
      </Styled.LoadingContainer>
    );
  }

  const getScoreTitle = (title: string): string => {
    switch (title) {
      case StatTypeScoreTitle.VOTE:
        return intl.formatMessage({ id: 'vote', defaultMessage: 'Vote' });
      case StatTypeScoreTitle.IDEA_CREATED:
        return intl.formatMessage({ id: 'ideaCreated', defaultMessage: 'Idea' });
      case StatTypeScoreTitle.COMMENT_CREATED:
        return intl.formatMessage({ id: 'commentCreated', defaultMessage: 'Comment' });
      case StatTypeScoreTitle.POST_VIEW:
        return intl.formatMessage({ id: 'postView', defaultMessage: 'Post View' });
      case StatTypeScoreTitle.PAGE_VIEW:
        return intl.formatMessage({ id: 'pageView', defaultMessage: 'Page view' });
      case StatTypeScoreTitle.USER_JOIN:
        return intl.formatMessage({ id: 'userJoin', defaultMessage: 'User join' });
      default:
        return '';
    }
  };

  const columns = [
    { type: 'date', label: intl.formatMessage({ id: 'date', defaultMessage: 'Date' }) },
    { type: 'number', label: intl.formatMessage({ id: 'vote', defaultMessage: 'Vote' }) },
    { type: 'number', label: intl.formatMessage({ id: 'ideaCreated', defaultMessage: 'Idea created' }) },
    { type: 'number', label: intl.formatMessage({ id: 'commentCreated', defaultMessage: 'Comment created' }) },
    { type: 'number', label: intl.formatMessage({ id: 'postView', defaultMessage: 'Post view' }) },
    { type: 'number', label: intl.formatMessage({ id: 'pageView', defaultMessage: 'Page view' }) },
    { type: 'number', label: intl.formatMessage({ id: 'userJoin', defaultMessage: 'User join' }) }
  ];

  const rows = [];
  const finalStats = Array.from({ length: columns.length - 1 }).fill(0) as number[];

  const dates: { [index: string]: number[] } = {};
  const dt = new Date(selectedStartDate.toString());
  // we fill in the entire date range here
  dates[formatDate(dt)] = Array.from({ length: columns.length - 1 }).fill(0) as number[];
  while (formatDate(dt) !== formatDate(selectedEndDate)) {
    dt.setDate(dt.getDate() + 1);
    dates[formatDate(dt)] = Array.from({ length: columns.length - 1 }).fill(0) as number[];
  }

  if (statsData?.stats) {
    statsData.stats.forEach((result: StatResultType) => {
      if (result?.date && result.date in dates && result?.type) {
        dates[result.date][result.type - 1] += result.total;
        finalStats[result.type - 1] += result.total;
      }
    });
  }

  for (const key in dates) {
    if (Object.hasOwn(dates, key)) {
      const tempRow = ([new Date(key.replace(/-/g, '/'))] as unknown as number[]).concat(
        statsData?.stats ? dates[key] : (Array.from({ length: columns.length - 1 }).fill(0) as number[])
      );
      rows.push(tempRow);
    }
  }

  const chartOptions = {
    displayAnnotations: false,
    displayZoomButtons: false,
    zoomStartTime: new Date(rows[0][0]),
    zoomEndTime: selectedEndDate,
    min: 0,
    max: 10
  };

  return (
    <Grid key="activityOverview">
      <CustomFilters
        userFieldOptions={userFieldOptions}
        communityUserFields={communityUserFields}
        countries={countries}
        userFieldUpdate={setUserFieldOptions}
        setSkip={setSkip}
      />

      <GridHeader key="dateRangeHeader">
        {intl.formatMessage({ id: 'dateRange', defaultMessage: 'Date range' })}
      </GridHeader>
      <GridRow key="dateRange">
        <DateRangePicker
          start={{
            label: intl.formatMessage({ id: 'start', defaultMessage: 'Start' }),
            date: startDate,
            onDateChange: (date: Date | null) => {
              setSkip(true);
              setStartDate(date || new Date());
            }
          }}
          end={{
            label: intl.formatMessage({ id: 'end', defaultMessage: 'End' }),
            date: endDate,
            onDateChange: (date: Date | null) => {
              setSkip(true);
              setEndDate(date || new Date());
            }
          }}
        />
      </GridRow>
      <GridHeader key="dataSelectionHeader">{intl.formatMessage({ id: 'show', defaultMessage: 'Show' })}</GridHeader>
      <GridRow key="dataSelection">
        <CheckboxFilter
          id="types"
          name={intl.formatMessage({ id: 'data', defaultMessage: 'Data' })}
          selectedFilters={types.map((type) => StatTypeOptions[StatType[type]])}
          setTypes={setTypes}
          setSkip={setSkip}
          userFieldOptions={userFieldOptions}
          communityUserFields={communityUserFields}
          options={Object.values(StatTypeOptions)}
        />
      </GridRow>
      <GridRow key="updateButton">
        <Button
          priority="secondary"
          onClick={() => {
            setSkip(false);
          }}
        >
          {intl.formatMessage({ id: 'update', defaultMessage: 'Update' })}
        </Button>
      </GridRow>
      <GridRow key="annotationChart">
        <Chart
          chartType="AnnotationChart"
          width="100%"
          height="300px"
          rows={
            rows.length > 0
              ? rows
              : ([[selectedStartDate, ...Array.from({ length: 6 }).fill(0)]] as unknown as GoogleDataTableRow[])
          }
          columns={columns as unknown as GoogleDataTableColumn[]}
          legendToggle
          options={chartOptions}
        />
      </GridRow>
      <GridRow key="scoreCards">
        {finalStats.map((v, k) => {
          const title = getScoreTitle(StatType[k + 1]);
          const typesToDisplay = selectedTypes.length === 0 ? defaultTypes : selectedTypes;
          return typesToDisplay.includes(k + 1) ? (
            <GridCol span={2} key={title}>
              <Score type={k} title={title} value={v} />
            </GridCol>
          ) : null;
        })}
      </GridRow>
    </Grid>
  );
};
