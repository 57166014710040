import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { type SlateToolbarControl } from '@m/alchemy-ui';
import { SlateRTE } from '@m/alchemy-ui';
import { CommunityContext } from '../../../context';
import { getValueOrPath, handleTranslationChange } from '../helpers/translation';
import { type MixedTypes } from '../../../common/interfaces';
import { DEFAULT_LANGUAGE } from '../../shared-constants';
import { LanguageSelector } from './LanguageSelector';
import { StyledInput, Wrapper } from './TranslatableInput.styled';

interface TranslatableRTEProps {
  readonly type: string;
  readonly field: string;
  readonly translateId: string;
  readonly config?: { buttons: SlateToolbarControl[] };
  readonly labelId?: string;
}

function usePrevious<T>(value: T) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export const TranslatableRTE = ({ type, field, translateId, config, labelId }: TranslatableRTEProps) => {
  const community = useContext(CommunityContext);
  const { values, setFieldValue } = useFormikContext<MixedTypes & { language: string }>();
  const { language: allLanguage } = values;

  const [language, setLanguage] = useState<string>(allLanguage || DEFAULT_LANGUAGE);
  const [fieldPath, setFieldPath] = useState<string>('');

  const isDefaultLanguage = language === DEFAULT_LANGUAGE;
  const previousSelectedLanguage = usePrevious(language);

  const initialDefaultvalue = isDefaultLanguage
    ? values[field as keyof MixedTypes]
    : getValueOrPath(values, { language, field, type, translateId });
  const [value, setValue] = useState<string | undefined>(initialDefaultvalue as string);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const path = getValueOrPath(values, { language, field, type, translateId }, true);
    setFieldPath(path);
  }, [field, language, translateId, type, values]);

  useEffect(() => {
    setLanguage(allLanguage);
  }, [allLanguage]);

  // only update the default value when the language changes. otherwise the RTE can function in its usual non-controlled manner.
  // we use the default value as the key for the component. only when this key changes will the component remounted with new state.
  useEffect(() => {
    if (previousSelectedLanguage && language !== previousSelectedLanguage) {
      const value = isDefaultLanguage
        ? values[field as keyof MixedTypes]
        : getValueOrPath(values, { language, field, type, translateId });
      setValue(value as string);

      // it is a trick to force SlateRTE rerender with a new value in editor - because of translation switcher
      setKey(key + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, isDefaultLanguage, language, previousSelectedLanguage, translateId, type, values]);

  const handleChange = useCallback(
    (value: string) => {
      if (isDefaultLanguage) {
        setFieldValue(field, value);
        return;
      }

      if (language === previousSelectedLanguage || previousSelectedLanguage === DEFAULT_LANGUAGE) {
        handleTranslationChange({
          language,
          field,
          type,
          translateId,
          value,
          fieldPath,
          communityId: community.id,
          setFieldValue,
          values
        });
      }
    },
    [
      community.id,
      field,
      fieldPath,
      isDefaultLanguage,
      language,
      previousSelectedLanguage,
      setFieldValue,
      translateId,
      type,
      values
    ]
  );

  useEffect(() => {
    if (value || value === '') {
      handleChange(value);
    }
    // only trigger the change handler when RTE value is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const hideLanguageSelector =
    !community.options?.translationLanguages || community.options?.translationLanguages.length <= 1;

  return (
    <Wrapper>
      <StyledInput>
        <SlateRTE
          labelId={labelId}
          initialValue={SlateRTE.deserialize(value)}
          controls={config?.buttons}
          onChange={(value) => setValue(SlateRTE.serialize(value))}
          key={key}
        />
      </StyledInput>
      {!hideLanguageSelector && (
        <LanguageSelector value={language} onLanguageChange={(lang: string) => setLanguage(lang)} />
      )}
    </Wrapper>
  );
};
