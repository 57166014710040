import { FormattedMessage, useIntl } from 'react-intl';
import { Button, type TabProps } from '@m/alchemy-ui';
import { useEffect, useState } from 'react';
import { type ILeaderboard, type ILeaderboardItem, type IPageItemSettings } from '../../../../../common/interfaces';
import { TabbedPanel } from '../TabbedPanel/TabbedPanel';
import { LeaderboardTabKeys } from '../../../../../common/enums/LeaderboardTabKeys';
import { HUBBUB_LEADERBOARD_URL } from '../../../common/const';
import { Alert, List } from '../common/styles';
import { LeaderboardItem } from './LeaderboardItem';
import { LeaderboardContainer, LeaderboardFooterContainer } from './Leaderboard.styled';

interface ILeaderboardProps {
  data: ILeaderboard;
  settings: IPageItemSettings;
  panelSize: number;
  hasWideSibling: boolean;
}

export const Leaderboard = ({ data, settings, panelSize, hasWideSibling }: ILeaderboardProps) => {
  const intl = useIntl();
  const { leaderboardDefault: defaultTab, leaderboardDisplay: tabsToDisplay } = settings;

  const [currentTab, setCurrentTab] = useState<string>('');
  const [leaderboardData, setLeaderboardData] = useState<{ leaderboard: JSX.Element[]; newbies: JSX.Element[] }>();

  useEffect(() => {
    if (data) {
      const createItems = (items: ILeaderboardItem[], tab: LeaderboardTabKeys) =>
        items?.map((item, index) => (
          <LeaderboardItem
            key={item.user.id}
            id={index + 1}
            data={item}
            tab={tab}
            panelSize={panelSize}
            hasWideSibling={hasWideSibling}
          />
        ));

      const leaderboard = createItems(data.leaderboard, LeaderboardTabKeys.LEADERBOARD);
      const newbies = createItems(data.newbies, LeaderboardTabKeys.NEWBIES);
      const tabToDisplay =
        tabsToDisplay.includes(defaultTab) && defaultTab === LeaderboardTabKeys.LEADERBOARD
          ? LeaderboardTabKeys.LEADERBOARD
          : LeaderboardTabKeys.NEWBIES;

      setCurrentTab(tabToDisplay);
      setLeaderboardData({ leaderboard, newbies });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const leaderboardTabChanged = (tabName: string) => {
    if (leaderboardData && tabName in leaderboardData) {
      setCurrentTab(tabName);
    }
  };

  const tabs = [
    {
      label: intl.formatMessage({ id: 'leaderboard', defaultMessage: 'Leaderboard' }),
      value: LeaderboardTabKeys.LEADERBOARD,
      layout: 'left'
    } as TabProps,
    {
      label: intl.formatMessage({ id: 'newbies', defaultMessage: 'Newbies' }),
      value: LeaderboardTabKeys.NEWBIES,
      layout: 'left'
    } as TabProps
  ];

  const noActivitiesMessage = (
    <FormattedMessage id="page.thereIsNoActivityToShowYet." defaultMessage="There is no activity to show yet." />
  );

  const viewLeaderboardButton = () => (
    <Button priority="secondary" onClick={handleButtonClick}>
      <FormattedMessage id="page.viewLeaderboard." defaultMessage="View Leaderboard" />
    </Button>
  );

  const handleButtonClick = () => {
    window.location.href = HUBBUB_LEADERBOARD_URL;
  };

  const content = () => {
    const currentTabData = leaderboardData ? leaderboardData[currentTab as keyof ILeaderboard] : [];
    return currentTabData?.length === 0 ? <Alert>{noActivitiesMessage}</Alert> : <List>{currentTabData}</List>;
  };

  const footer = <LeaderboardFooterContainer>{viewLeaderboardButton()}</LeaderboardFooterContainer>;

  return (
    <LeaderboardContainer>
      <TabbedPanel
        tabs={tabs}
        content={content()}
        footer={footer}
        defaultActiveTab={tabsToDisplay.length > 1 ? defaultTab : tabsToDisplay[0]}
        customMaxHeight={345}
        onTabChange={leaderboardTabChanged}
      />
    </LeaderboardContainer>
  );
};
