import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';

export const GET_USERRANKING: DocumentNode = gql`
  query getUserranking($challengeId: String, $limit: Int) {
    userRankings(challengeId: $challengeId, limit: $limit) {
      id
      userId
      score
      user {
        id
        displayName
        createdAt
        timezone
        profileImageFile {
          id
          name
          url
          hash
          extension
        }
      }
    }
  }
`;
