export const normalizeText = (
  text: string,
  maxLength: number,
  options?: { suffix?: string; isWide?: boolean; hasWideSibling?: boolean; panelSize?: number }
): string => {
  const suffix = options?.suffix || '...';

  if (options && (options.isWide || options.hasWideSibling || options.panelSize)) {
    const panelSize = options.panelSize || 0;

    if (options.isWide || options.hasWideSibling || panelSize === 1 || panelSize === 3) {
      maxLength = 26;
    } else if (panelSize === 2) {
      maxLength = 16;
    }
  }

  return text.length > maxLength ? text.slice(0, maxLength - suffix.length) + suffix : text;
};
