import { borderRadius, Button, color, Panel, RadioGroup, snap, typeStyle, units } from '@m/alchemy-ui';
import styled from 'styled-components';

export const Container = styled.div`
  width: ${snap(305)};
  max-height: ${snap(475)};

  @media all and (max-width: 990px) {
    width: auto;
    max-height: 100%;
  }
`;

export const SelectorContainer = styled.div`
  position: relative;
`;

export const StyledPanel = styled(Panel)`
  border-radius: ${borderRadius('large')};
`;

export const Header = styled.div`
  ${typeStyle('bodyS')};
  padding: ${units(0.7)} ${units(2)};
`;

export const FooterRadio = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${units(0.5)};
  padding-left: ${units(1)};
`;

export const RadioGroupLabel = styled.div`
  margin-right: ${units(2)};
  ${typeStyle('captionM')};
`;

export const Footer = styled.div`
  padding: ${units(1)};
`;

export const AccessList = styled.div`
  height: ${units(25)};
  padding: ${units(1)} ${units(1.5)};
`;

export const RadioLabel = styled.div`
  ${typeStyle('captionM')};
`;

export const StyledRadioGroup = styled(RadioGroup)`
  margin: 0;
`;

export const InvisibleChallengeMessage = styled.div`
  border: 1px solid ${color('gradient', { palette: 'expressiveWarning' })};
  border-radius: ${borderRadius('large')};
  background-color: ${color('hint', { palette: 'expressiveWarning' })};
  padding: ${units(2)};
`;

export const AddEveryoneButton = styled(Button)`
  padding: 0;
  border: none;
  color: ${color('typeLink')};
  font: inherit;
  min-height: ${units(2)};
  text-decoration: underline;
  :hover {
    background-color: transparent !important;
  }

  @media all and (max-width: 995px) {
    white-space: pre-line;
  }
`;

export const HelpText = styled.div`
  ${typeStyle('captionM')};
  color: ${color('typeSubHeader')};
  margin-top: ${units(1)};
`;

export const StyledLink = styled.a`
  color: ${color('typeLink')};
`;

export const EveryoneMessage = styled.div`
  ${typeStyle('captionM')};
  border: 1px solid ${color('contrast', { palette: 'expressivePositive' })};
  background-color: ${color('hint', { palette: 'expressiveSemiPositive' })};
  color: ${color('contrast', { palette: 'expressivePositive' })};
  padding: ${units(1)};
  margin-top: ${units(1)};
`;
