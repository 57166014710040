import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';

export const DELETE_LINKED_ACCOUNT: DocumentNode = gql`
  mutation deleteUserAccount($id: String!) {
    deleteUserAccount(id: $id) {
      success
    }
  }
`;
