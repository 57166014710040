import { type ChangeEvent, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Field,
  Grid,
  GridCol,
  GridRow,
  Input,
  Popper,
  PopperBox,
  Radio,
  SlateRTE,
  type SlateToolbarControl
} from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik, useFormikContext } from 'formik';
import { MilestoneMessagesType, SelectorType, WarningType } from '../../../common/enums';
import { Body, Container } from '../../../common/components';
import { defaultMilestone, everyoneGroup, type IChallenge } from '../../../common/interfaces';
import { getNewMilestone } from '../helpers/milestone';
import { milestoneValidationSchema } from '../validation-schema';
import { useBeforeUnload, useFieldError } from '../../../hooks';
import { handleBeforeunload } from '../helpers/beforeUnload';
import { type Maybe, type Milestone, MilestoneType } from '../../../common/core-api';
import { DEFAULT_LANGUAGE } from '../../shared-constants';
import { CustomizeForm } from './CustomizeForm';
import * as Styled from './MilestoneForm.styled';
import { AccessSelector, MilestoneMessageRadio, MilestoneVoting, TranslatableInput, Warning } from '.';

const slateConfig = {
  buttons: ['bold', 'italic', 'unorderedList', 'undo', 'redo'] as SlateToolbarControl[]
};

export const MilestoneForm = ({
  currentMilestone,
  currentMilestoneIndex,
  setPopperOpen,
  milestoneType,
  newMilestoneIndex
}: {
  readonly currentMilestone?: Milestone;
  readonly currentMilestoneIndex?: number;
  readonly setPopperOpen: (value: boolean) => void;
  readonly milestoneType?: Maybe<MilestoneType>;
  readonly newMilestoneIndex?: number;
}) => {
  const {
    values: challenge,
    setFieldValue: setChallengeFieldValue,
    errors: challengeErrors,
    touched: challengeTouched,
    status: challengeStatus
  } = useFormikContext<IChallenge>();
  const intl = useIntl();

  const [fieldError] = useFieldError();
  const milestoneErrors = challengeStatus?.milestone?.milestones[currentMilestoneIndex || 0] || {};
  const errorStatus = { ...milestoneErrors, milestone: { ...challengeStatus?.milestone } };

  const milestoneDataChanged = useRef(false);

  useBeforeUnload((event: BeforeUnloadEvent) =>
    handleBeforeunload(
      event,
      intl.formatMessage({
        id: 'changesNotSaved',
        defaultMessage: 'Changes you made may not be saved.'
      }),
      milestoneDataChanged.current
    )
  );

  const { milestone, ideas } = challenge;
  const milestones = milestone?.milestones;
  const milestoneCount = milestones?.length;
  const hasIdeas = Boolean(ideas?.length);

  const defaultMilestoneNames = [
    {
      name: intl.formatMessage({ id: 'approved', defaultMessage: 'Approved' }),
      value: MilestoneType.APPROVAL
    },
    {
      name: intl.formatMessage({ id: 'votes', defaultMessage: 'Votes' }),
      value: MilestoneType.VOTE
    },
    {
      name: intl.formatMessage({ id: 'refine', defaultMessage: 'Refine' }),
      value: MilestoneType.REFINE
    }
  ];

  let milestoneToRender: Milestone = { ...defaultMilestone };
  if (currentMilestone) {
    milestoneToRender = currentMilestone;
    milestoneType = currentMilestone.type;
  } else if (milestoneType) {
    const index = defaultMilestoneNames.findIndex((item) => item.value === milestoneType);
    milestoneToRender = getNewMilestone(milestoneType, defaultMilestoneNames[index].name);
    if (milestoneType === MilestoneType.VOTE) {
      milestoneToRender = {
        ...milestoneToRender,
        voters: { users: [], groups: [everyoneGroup.usergroup] }
      };
    }
  }

  const [useDefaultApproachMessage, setUseDefaultApproachMessage] = useState<boolean>(
    milestoneToRender.approachMessageToAuthors === '' &&
      milestoneToRender.approachMessageToUsers === '' &&
      milestoneToRender.approachEmailMessageToModerators === '' &&
      milestoneToRender.approachEmailMessageToVoters === ''
  );
  const [useDefaultReachMessage, setUseDefaultReachMessage] = useState<boolean>(
    milestoneToRender.reachedEmailMessageToAuthors === ''
  );

  const [milestoneDeleteWarningOpen, setMilestoneDeleteWarningOpen] = useState(false);
  const [milestoneToDelete, setMilestoneToDelete] = useState<Milestone>();

  const handleMilestoneDeleteCancel = () => {
    setMilestoneDeleteWarningOpen(false);
    setMilestoneToDelete(undefined);
  };

  const handleMilestoneDeleteConfirm = () => {
    milestoneToDelete && handleMilestoneDelete();
    setMilestoneDeleteWarningOpen(false);
    setMilestoneToDelete(undefined);
    setPopperOpen(false);
  };

  const handleMilestoneDelete = () =>
    setChallengeFieldValue(
      'milestone.milestones',
      milestones?.filter((_, index) => index !== currentMilestoneIndex)
    );

  const handleMilestoneFormSave = (value: Milestone) => {
    let newMilestonesValue: Milestone[] = [];
    if (newMilestoneIndex) {
      // add new milestone in right order
      milestones?.forEach((milestone, index) => {
        if (index === newMilestoneIndex) {
          const questions = value.questions?.filter((question) => question.milestoneId === value.id) || [];
          newMilestonesValue.push({ ...value, questions });
        }

        let updatedMilestone = milestone;
        if (index < newMilestoneIndex) {
          const questions = value.questions?.filter((question) => question.milestoneId === milestone.id) || [];
          updatedMilestone = { ...milestone, questions };
        }

        // set all milestones' id to new random id if new milestone was inserted in the middle,
        // so milestones can be saved in right id order
        if (milestoneCount && newMilestoneIndex < milestoneCount) {
          const newMilestoneId = Math.random().toString().slice(2, 8); // Random id, to be replaced on save
          newMilestonesValue.push({
            ...updatedMilestone,
            id: newMilestoneId,
            questions:
              updatedMilestone.questions?.map((question) => ({ ...question, milestoneId: newMilestoneId })) || []
          });
        } else {
          newMilestonesValue.push(updatedMilestone);
        }

        // new milestone was added at the end
        if (milestoneCount && index === milestoneCount - 1 && newMilestoneIndex === milestoneCount) {
          const questions = value.questions?.filter((question) => question.milestoneId === value.id) || [];
          newMilestonesValue.push({ ...value, questions });
        }
      });
    } else {
      // update the existing milestone
      newMilestonesValue =
        milestones?.map((milestone, i) => {
          if (currentMilestoneIndex && i < currentMilestoneIndex) {
            const questions = value.questions?.filter((question) => question.milestoneId === milestone.id) || [];
            return { ...milestone, questions };
          }

          if (i === currentMilestoneIndex) {
            const questions = value.questions?.filter((question) => question.milestoneId === value.id) || [];
            return { ...value, questions };
          }

          return milestone;
        }) || [];
    }

    setChallengeFieldValue('milestone', { ...milestone, milestones: newMilestonesValue });
    setPopperOpen(false);
  };

  const sliceEndIndex = currentMilestoneIndex ? currentMilestoneIndex + 1 : newMilestoneIndex;
  const questionsInMilestone =
    challenge.milestone?.milestones?.slice(0, sliceEndIndex).flatMap((m) => m.questions || []) || [];

  return (
    <>
      <Formik
        initialValues={{
          language: DEFAULT_LANGUAGE,
          ...milestoneToRender,
          questions: questionsInMilestone
        }}
        validationSchema={milestoneValidationSchema}
        initialStatus={errorStatus}
        onSubmit={(value: Milestone) => handleMilestoneFormSave(value)}
      >
        {({ values, handleChange, setFieldValue, handleBlur, errors, touched, dirty, status }) => {
          milestoneDataChanged.current = dirty;
          const { id, name, access, moderators, voting } = values;
          const challengeModerators = [
            ...(challenge.access?.users?.filter((user) => user.canModerate) || []),
            ...(challenge.access?.groups?.filter((group) => group.canModerate) || [])
          ];
          const noModerators =
            challengeModerators.length === 0 &&
            (!moderators?.users || moderators.users.length === 0) &&
            (!moderators?.groups || moderators.groups.length === 0);

          return (
            <Form>
              <Grid>
                <Container>
                  <GridRow>
                    <GridCol span={{ small: 12, medium: 6 }}>
                      <Field
                        isFullWidth
                        data-testid="milestoneName"
                        label={intl.formatMessage({ id: 'milestoneName', defaultMessage: 'Milestone Name' })}
                        required
                        status={fieldError('name', { errors, status, touched })}
                        input={
                          <TranslatableInput type="Milestone" field="name" translateId={id}>
                            <Input name="name" value={name || ''} onChange={handleChange} onBlur={handleBlur} />
                          </TranslatableInput>
                        }
                      />
                    </GridCol>
                    <GridCol span={{ small: 12, medium: 6 }}>
                      <Field
                        data-testid="tooltipDescription"
                        isFullWidth
                        label={intl.formatMessage({ id: 'tooltipDescription', defaultMessage: 'Tooltip description' })}
                        input={
                          <SlateRTE
                            // Using original description over formik value to avoid rerenders on change
                            initialValue={SlateRTE.deserialize(milestoneToRender.description || '')}
                            controls={slateConfig?.buttons}
                            onChange={(value) => setFieldValue('description', SlateRTE.serialize(value))}
                          />
                        }
                      />
                    </GridCol>
                  </GridRow>
                </Container>
                {milestoneType === MilestoneType.APPROVAL && (
                  <Container>
                    <Styled.Title>
                      {intl.formatMessage({
                        id: 'approvalModerators',
                        defaultMessage: 'Approval moderators'
                      })}
                    </Styled.Title>
                    <AccessSelector type={SelectorType.MILESTONE_MODERATE} />
                    {noModerators && (
                      <Styled.WarningMessage>
                        <Styled.StyledWarningIcon aria-hidden="true" />
                        {intl.formatMessage({
                          id: 'noMilestoneModeratorWarning',
                          defaultMessage:
                            'No users are configured to approve ideas at this milestone. Please either add approval moderators to this milestone, or add moderators to this challenge.'
                        })}
                      </Styled.WarningMessage>
                    )}
                  </Container>
                )}
                {milestoneType === MilestoneType.VOTE && <MilestoneVoting />}
                <MilestoneMessageRadio
                  radioName="milestoneApproachMessage"
                  title={intl.formatMessage({
                    id: 'milestoneApproachMessage',
                    defaultMessage: 'Message when approaching this milestone'
                  })}
                  data-testid="milestoneApproachMessage"
                  checked={useDefaultApproachMessage}
                  onChange={() => {
                    if (!useDefaultApproachMessage) {
                      setFieldValue('approachMessageToAuthors', '');
                      setFieldValue('approachEmailMessageToModerators', '');
                      setFieldValue('approachEmailMessageToVoters', '');
                      setFieldValue('approachMessageToUsers', '');
                    }
                    setUseDefaultApproachMessage(!useDefaultApproachMessage);
                  }}
                  messageType={MilestoneMessagesType.APPROACH}
                />
                {milestoneType !== MilestoneType.SUBMITTED && (
                  <MilestoneMessageRadio
                    radioName="milestoneReachedMessage"
                    title={intl.formatMessage({
                      id: 'milestoneReachedMessage',
                      defaultMessage: 'Congratulations email message when this milestone has been reached'
                    })}
                    data-testid="milestoneReachedMessage"
                    checked={useDefaultReachMessage}
                    onChange={() => {
                      !useDefaultReachMessage && setFieldValue('reachedEmailMessageToAuthors', '');
                      setUseDefaultReachMessage(!useDefaultReachMessage);
                    }}
                    messageType={MilestoneMessagesType.REACH}
                  />
                )}
              </Grid>
              <Container>
                <Styled.Title>
                  {intl.formatMessage({
                    id: 'options',
                    defaultMessage: 'Options'
                  })}
                </Styled.Title>
                <Checkbox
                  label={intl.formatMessage({
                    id: 'allowMilestoneComment',
                    defaultMessage: 'Allow comments for this milestone'
                  })}
                  data-testid="allowMilestoneComment"
                  checked={access.allowComments}
                  onChange={(e) => setFieldValue('access', { ...access, allowComments: e.target.checked })}
                />
                {milestoneType === MilestoneType.VOTE && (
                  <>
                    <Checkbox
                      label={
                        <Styled.CheckboxWrapper>
                          <FormattedMessage id="showVoted" defaultMessage="Show who voted" />
                          <Popper
                            placement="top"
                            mode="tooltip"
                            overlay={
                              <PopperBox hasArrow colorScheme="alchemyDark">
                                {intl.formatMessage({
                                  id: 'showVotedHint',
                                  defaultMessage: 'When selected, a list of users who voted will be shown on each idea'
                                })}
                              </PopperBox>
                            }
                            trigger={
                              <Styled.StyledIcon
                                aria-label={intl.formatMessage({ id: 'importantTrigger', defaultMessage: 'Important' })}
                              />
                            }
                          />
                        </Styled.CheckboxWrapper>
                      }
                      data-testid="showVoted"
                      checked={voting?.showVoters || undefined}
                      onChange={(e) => setFieldValue('voting', { ...voting, showVoters: e.target.checked })}
                    />
                    <Checkbox
                      data-testid="enableBlindVoting"
                      label={<FormattedMessage id="enableBlindVoting" defaultMessage="Enable blind voting on ideas" />}
                      checked={voting?.isBlind || undefined}
                      onChange={(e) => setFieldValue('voting', { ...voting, isBlind: e.target.checked })}
                    />
                  </>
                )}
              </Container>
              <Container>
                <Styled.Title>
                  {intl.formatMessage({
                    id: 'ideaVisibility',
                    defaultMessage: 'Idea visibility'
                  })}
                </Styled.Title>
                <Radio
                  label={intl.formatMessage({
                    id: 'visibleToAnyone',
                    defaultMessage: 'Visible to anyone with access to the challenge'
                  })}
                  name="ideaVisibility"
                  value="visibleToAnyone"
                  data-testid="visibleToAnyone"
                  checked={!access.privateSubmissionEnabled}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('access', { ...access, privateSubmissionEnabled: !e.target.checked })
                  }
                />
                <Radio
                  label={intl.formatMessage({
                    id: 'visibleToModerators',
                    defaultMessage: 'Visible to challenge moderator(s) only'
                  })}
                  name="ideaVisibility"
                  value="visibleToModerators"
                  data-testid="visibleToModerators"
                  checked={access.privateSubmissionEnabled}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('access', { ...access, privateSubmissionEnabled: e.target.checked })
                  }
                />
              </Container>
              {milestoneType === MilestoneType.REFINE && (
                <Container>
                  <Styled.Title>
                    {intl.formatMessage({
                      id: 'milestoneQuestionsAdd',
                      defaultMessage: 'Add refinement questions'
                    })}
                  </Styled.Title>
                  <Body>
                    <FormattedMessage
                      id="milestoneQuestionsAdd1"
                      defaultMessage="Add additional questions to the idea submission form."
                    />
                  </Body>
                  <Body>
                    <FormattedMessage
                      id="milestoneQuestionsAdd2"
                      defaultMessage="These fields are added in addition to those that are already on the submission form for this challenge."
                    />
                  </Body>
                  <Body>
                    <FormattedMessage
                      id="milestoneQuestionsAdd3"
                      defaultMessage="Make the fields required if you want idea authors to have to complete the field before their idea can progress."
                    />
                  </Body>
                  <CustomizeForm
                    type={SelectorType.MILESTONE}
                    milestones={challenge.milestone?.milestones || undefined}
                    currentMilestoneIndex={currentMilestoneIndex}
                    milestoneErrorsMeta={{ errors: challengeErrors, touched: challengeTouched }}
                  />
                  {questionsInMilestone?.length === 0 && (
                    <Styled.WarningMessage>
                      <Styled.StyledWarningIcon aria-hidden="true" />
                      {intl.formatMessage({
                        id: 'noMilestoneQuestionsWarning',
                        defaultMessage: 'No refinement questions have been configured for this milestone.'
                      })}
                    </Styled.WarningMessage>
                  )}
                </Container>
              )}
              <Styled.ButtonRow>
                {/* If challenge has no ideas, any milestone can be deleted other than the submitted milestone.
                If challenge has ideas, only the last milestone can be deleted. */}
                {!newMilestoneIndex &&
                  currentMilestoneIndex !== 0 &&
                  (!hasIdeas || (milestoneCount && currentMilestoneIndex === milestoneCount - 1)) && (
                    <Button
                      priority="primary"
                      palette="interactiveDestructive"
                      data-testid="delete"
                      onClick={() => {
                        if (hasIdeas) {
                          setMilestoneToDelete(currentMilestone);
                          setMilestoneDeleteWarningOpen(true);
                        } else {
                          handleMilestoneDelete();
                          setPopperOpen(false);
                        }
                      }}
                    >
                      {intl.formatMessage({ id: 'delete', defaultMessage: 'Delete' })}
                    </Button>
                  )}
                <Styled.ButtonGroup>
                  <Button priority="tertiary" onClick={() => setPopperOpen(false)} data-testid="cancel">
                    {intl.formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                  </Button>
                  <Button priority="secondary" type="submit" key="submit" data-testid="confirm">
                    {intl.formatMessage({
                      id: 'confirm',
                      defaultMessage: 'Confirm'
                    })}
                  </Button>
                </Styled.ButtonGroup>
              </Styled.ButtonRow>
            </Form>
          );
        }}
      </Formik>
      <Warning
        isOpen={milestoneDeleteWarningOpen}
        onClose={handleMilestoneDeleteCancel}
        onConfirm={handleMilestoneDeleteConfirm}
        warningType={WarningType.MILESTONE_DELETE}
      />
    </>
  );
};
