import React from 'react';
import { type ColorSchemeKey } from '@m/alchemy-ui';
import { useIsMobile, useIsSmallMobile } from '../../hooks';
import { useRuntimeCommunity, useRuntimeUser } from '../../context';
import { defaultRuntimeUser, type RouteLink } from '../../common/interfaces';
import * as Styled from './Navigation.styled';
import { AdminNavigation } from './components/AdminNavigation';
import { UserNavigation } from './components/UserNavigation';
import { CommunityLogo } from './components/CommunityLogo';

export const Navigation = React.forwardRef(function Navigation(
  {
    links,
    isSticky = true,
    adminLinks
  }: {
    links?: RouteLink[];
    isSticky?: boolean | undefined;
    adminLinks?: RouteLink[];
  },
  ref: React.Ref<HTMLElement>
) {
  const hasAdminRights = (adminLinks?.length || 0) > 0;
  const isAdminPage = window.location.pathname.includes('/admin');
  const isMobile = useIsMobile();
  const isSmallMobile = useIsSmallMobile();
  const { id } = useRuntimeUser();
  const isLoggedIn = id !== defaultRuntimeUser.id;
  const {
    colors: { userNavBg = 'dark', voteBar }
  } = useRuntimeCommunity();

  const isDarkBackground = userNavBg?.toLocaleLowerCase() === 'dark' && !isAdminPage;
  const userNavBgTheme = `alchemy${isDarkBackground ? 'Dark' : 'Light'}`;

  return (
    <Styled.AppBarWrapper ref={ref} isSticky={isSticky}>
      <Styled.AppBar
        priority="adminPrimary"
        colorScheme={userNavBgTheme as ColorSchemeKey}
        backgroundColor={isAdminPage ? `#${voteBar}` : undefined}
      >
        {(!isMobile || (isAdminPage && !isSmallMobile)) && (
          <a href="/" aria-label="home">
            <CommunityLogo lightLogo={isDarkBackground} />
          </a>
        )}
        {isAdminPage && isLoggedIn && hasAdminRights ? (
          <AdminNavigation links={adminLinks} />
        ) : (
          <UserNavigation links={links} isMobile={isMobile} isLoggedIn={isLoggedIn} hasAdminRights={hasAdminRights} />
        )}
      </Styled.AppBar>
    </Styled.AppBarWrapper>
  );
});
