import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';

export const GET_SOCIAL_MEDIA: DocumentNode = gql`
  query socialMedia {
    socialMedia {
      id
      type
      urlProfilePattern
    }
  }
`;
