import { CommunityPageType } from '../../../../common/enums';
import { type IPageContentData, type IPageItem, type Translation } from '../../../../common/interfaces';
import { Activity, Blog, ChallengeCarousel, Ideas, Image, Leaderboard, Line, TextHtml, Video } from '../..';
import { type CustomPageRowProps, isItemTabbed, WIDE_SIZE } from '../../containers/CommunityPage';
import { CommunityPageItem, CommunityPageItemContent, SeparatorDiv } from './PageItem.styled';

interface IPageProps {
  item: IPageItem;
  contentData: IPageContentData;
  translations: Translation[] | undefined;
  rowProps: CustomPageRowProps;
}

export const PageItem = (props: IPageProps) => {
  const { contentData, item, translations, rowProps } = props;
  const { settings } = item;
  const { itemsCount, hasWideItem: rowHasWideItem } = rowProps;

  const isWide = itemsCount === 2 && item?.settings?.size === WIDE_SIZE;
  const isWideSibiling = itemsCount === 2 && rowHasWideItem && !isWide;
  const isTabbed = isItemTabbed(item);
  const isSingleTabbedItem = isTabbed && Number(itemsCount) === 1;

  return (
    <>
      {settings?.type === CommunityPageType.LINE ? (
        <SeparatorDiv isWide={isWide} isWideSibiling={isWideSibiling}>
          <Line />
        </SeparatorDiv>
      ) : (
        <CommunityPageItem
          isWide={isWide}
          isWideSibiling={isWideSibiling}
          isTabbed={isTabbed}
          isSingleTabbedItem={isSingleTabbedItem}
        >
          <CommunityPageItemContent type={settings.type}>
            {getPageComponent({
              item,
              translations,
              contentData,
              panelCount: Number(itemsCount),
              hasWideSiblings: isWideSibiling,
              isWide
            })}
          </CommunityPageItemContent>
        </CommunityPageItem>
      )}
    </>
  );
};

const getPageComponent = (args: {
  item: IPageItem;
  translations: Translation[] | undefined;
  contentData: IPageContentData;
  panelCount: number;
  hasWideSiblings: boolean;
  isWide: boolean;
}) => {
  const { contentData, item, translations, panelCount, hasWideSiblings, isWide } = args;

  if (item?.settings?.type === CommunityPageType.TEXT) {
    return <TextHtml item={item} translations={translations} />;
  }

  if (item?.settings?.type === CommunityPageType.IMAGE) {
    return <Image item={item} translations={translations} />;
  }

  if (item?.settings?.type === CommunityPageType.VIDEO) {
    return <Video item={item} />;
  }

  if (item?.settings?.type === CommunityPageType.BLOG_CAROUSEL) {
    return (
      <Blog
        blogDataSlides={contentData.blog ?? []}
        panelSize={panelCount}
        hasWideSibling={hasWideSiblings}
        isWide={isWide}
      />
    );
  }

  if (item?.settings?.type === CommunityPageType.CHALLENGE_CAROUSEL) {
    return (
      <ChallengeCarousel
        slidesData={contentData?.challengeCarousel?.panels.find((panel) => panel.id === item?.id)?.items ?? []}
        settings={item.settings}
      />
    );
  }

  if (item?.settings?.type === CommunityPageType.ACTIVITY) {
    return <Activity data={contentData.activity ?? []} panelSize={panelCount} hasWideSibling={hasWideSiblings} />;
  }

  if (item?.settings?.type === CommunityPageType.LEADERBOARD) {
    return (
      <Leaderboard
        data={contentData.leaderboard ?? []}
        settings={item.settings}
        panelSize={panelCount}
        hasWideSibling={hasWideSiblings}
      />
    );
  }

  if (item?.settings?.type === CommunityPageType.IDEAS) {
    return (
      <Ideas
        data={contentData?.ideas ?? []}
        settings={item.settings}
        panelSize={panelCount}
        hasWideSibling={hasWideSiblings}
      />
    );
  }

  return <></>;
};
