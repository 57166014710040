import { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useQuery } from '@apollo/client';
import { borderRadius, color, snap, typeStyle, units } from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { SEARCH_USERGROUPS, SEARCH_USERS } from '../../../graphql/queries';
import { SelectorType } from '../../../common/enums';
import { type User, type UserGroup } from '../../../common/core-api';
import { CommunityContext } from '../../../context';
import { getAvailableUserGroupTypes } from '../../../helpers/userGroup';
import { EVERYONE_GROUP_ID } from '../../../common/interfaces';
import { USER_GROUP_SELECTION, USER_SELECTION } from './AccessSelector';
import { SearchListItem } from '.';

interface ContainerProps {
  open: boolean;
}

const SearchResultContainer = styled.div<ContainerProps>`
  width: ${snap(300)};
  border-radius: ${borderRadius('medium')};
  z-index: 10;
  position: absolute;
  bottom: ${units(4)};
  right: calc(-100% - ${units(1)});
  background-color: ${color('container')};
  border: 1px solid ${color('fill')};
  max-height: ${snap(245)};
  overflow: auto;
  cursor: pointer;
  ${typeStyle('bodyS')};
  ${(props) =>
    props.open
      ? ''
      : css`
          display: none;
        `}

  @media all and (max-width: 700px) {
    right: auto;
    height: fit-content;
    width: 100%;
    bottom: calc(30% - 0px);
  }
`;

export const SearchResult = ({
  searchString,
  setSearchString,
  radioSelection,
  type
}: {
  readonly searchString: string;
  readonly setSearchString: (string: string) => void;
  readonly radioSelection: string;
  readonly type: SelectorType;
}) => {
  const intl = useIntl();
  const community = useContext(CommunityContext);
  const [searchResults, setSearchResults] = useState<User[] | UserGroup[]>([]);
  const [loading, setLoading] = useState(false);

  const usersResult = useQuery(SEARCH_USERS, {
    variables: {
      searchText: searchString
    },
    skip: searchString === '' || radioSelection === USER_GROUP_SELECTION
  });

  const userGroupsResult = useQuery(SEARCH_USERGROUPS, {
    variables: {
      searchText: searchString,
      types: getAvailableUserGroupTypes(community)
    },
    skip: searchString === '' || radioSelection === USER_SELECTION
  });

  useEffect((): void => {
    if (!searchString) {
      setSearchResults([]);
      return;
    }

    if (radioSelection === USER_SELECTION) {
      const { data, loading } = usersResult;
      const users: User[] = data?.searchUsers || [];
      setLoading(loading);
      setSearchResults(users);
    }

    if (radioSelection === USER_GROUP_SELECTION) {
      const { data, loading } = userGroupsResult;
      let groups: UserGroup[] = data?.searchUsergroups || [];

      if (type === SelectorType.MILESTONE_MODERATE) {
        groups = groups.filter((searchUsergroup) => searchUsergroup.id !== EVERYONE_GROUP_ID);
      }

      setLoading(loading);
      setSearchResults(groups);
    }
  }, [searchString, radioSelection, usersResult, userGroupsResult, type]);

  if (searchString && loading) {
    return (
      <SearchResultContainer open>
        {intl.formatMessage({
          id: 'accessSearchingLoadingMessage',
          defaultMessage: 'Searching...'
        })}
      </SearchResultContainer>
    );
  }

  return (
    <SearchResultContainer open={Boolean(searchString)} tabIndex={-1} ref={(el) => el?.focus()}>
      {searchResults.length > 0 ? (
        searchResults.map((item: User | UserGroup) => (
          <SearchListItem
            key={item.id}
            item={item}
            setSearchString={setSearchString}
            setSearchResults={setSearchResults}
            type={type}
          />
        ))
      ) : (
        <FormattedMessage
          id="accessNoSearchResultsMessage"
          defaultMessage="No results for {searchString}."
          values={{
            searchString
          }}
        />
      )}
    </SearchResultContainer>
  );
};
