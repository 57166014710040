import { Button, Toast } from '@m/alchemy-ui';
import { FieldArray, type FieldArrayRenderProps, Form, Formik } from 'formik';
import { type SetStateAction, useMemo, useState } from 'react';
import { type DropResult } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { ButtonRow, ReorderableList } from '../../../common/components';
import { type ToastMessageType } from '../../../common/enums/ToastMessageType';
import { getErrorToastMessage } from '../../../helpers';
import { reorder as reorderItems } from '../helpers/reorder';
import { type LabelManagerProps } from '../../../features';
import { CustomLabel } from './CustomLabel';

const Wrapper = styled.div`
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  margin: 15px auto 16px;
  padding: 8px;
  width: 100%;
`;

const DEFATULT_COLOR = '4E5DCA';

interface LabelItem {
  id: string;
  name: string;
  enabled: boolean;
  currentColor: string;
  isNew?: boolean;
  position: number;
}

export const ManageLabelsForm = ({ items, closePopUp, user, callback }: LabelManagerProps) => {
  const [messages, setMessages] = useState<ToastMessageType[]>();
  const intl = useIntl();
  const itemsToRemove: string[] = [];

  const parsedItems = useMemo(() => {
    const initialItems: LabelItem[] = [];

    Object.keys(items).forEach((itemId) => {
      const data = items[itemId]?.data;

      if (data) {
        const {
          ideastatus_name: name,
          ideastatus_id: id,
          ideastatus_color: currentColor,
          ideastatus_enabled: enabled,
          ideastatus_order: position
        } = data;

        initialItems.push({
          id,
          name,
          enabled,
          currentColor,
          position
        });
      }
    });

    return initialItems.sort((a, b) => a.position - b.position);
  }, [items]);

  const handleDragEnd = (
    result: DropResult,
    { items }: { items: LabelItem[] },
    setFieldValue: (values: SetStateAction<{ items: LabelItem[] }>) => void
  ) => {
    if (!result.destination) {
      return;
    }

    const itemsReordered = reorderItems<LabelItem>(items, result.source.index, result.destination.index);

    setFieldValue({
      items: itemsReordered
    });

    return itemsReordered;
  };

  const componentsItem = (
    items: LabelItem[],
    remove: (index: number) => void,
    handleChange: (field: string, value: boolean | string) => void
  ) =>
    items?.map((item, index) => ({
      id: `label-${item.id}`,
      content: (
        <CustomLabel
          index={index}
          name={item.name}
          enabled={item.enabled}
          currentColor={item.currentColor}
          handleDelete={() => {
            itemsToRemove.push(item.id);
            remove(index);
          }}
          handleChange={handleChange}
        />
      )
    }));

  const handleSubmit = async ({ items }: { items: LabelItem[] }) => {
    const formData = new FormData();
    let sorted = '';

    items.forEach(({ id, enabled, name, currentColor, isNew }: LabelItem, index) => {
      const statusType = isNew ? 'newCustom' : 'custom';

      if (enabled) {
        formData.append(`${statusType}StatusEnabled[${id}]`, '1');
      }

      formData.append(`${statusType}StatusName[${id}]`, name);
      formData.append(`${statusType}StatusColor[${id}]`, currentColor);

      sorted += `customStatusRow[]=${id}${index < items.length - 1 ? '&' : ''}`;
    });

    itemsToRemove.forEach((id) => {
      formData.append(`customStatusDelete[${id}]`, '1');
    });

    formData.append('ak', user?.actionKey || '');
    formData.append('sorted', sorted);

    try {
      const response = await fetch('/ideastatus/admin_manage', {
        method: 'POST',
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        body: formData
      });

      if (response.ok) {
        callback();
      } else {
        setMessages(
          getErrorToastMessage(intl.formatMessage({ id: 'customLabelFailed', defaultMessage: 'Save label failed' }))
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Formik initialValues={{ items: parsedItems }} onSubmit={handleSubmit} enableReinitialize>
        {({ isSubmitting, setFieldValue, values, setValues }) => (
          <Form>
            <FieldArray name="items">
              {({
                push,
                remove
              }: Omit<FieldArrayRenderProps, 'remove'> & { remove: <T>(index: number) => T | undefined }) => (
                <>
                  <Wrapper>
                    <ReorderableList
                      items={componentsItem(values.items, remove, setFieldValue)}
                      onDragEnd={(result) => handleDragEnd(result, values, setValues)}
                    />
                  </Wrapper>
                  <Button
                    priority="secondary"
                    onClick={() =>
                      push({
                        name: '',
                        id: values?.items?.length,
                        currentColor: DEFATULT_COLOR,
                        enabled: true,
                        isNew: true
                      })
                    }
                  >
                    {intl.formatMessage({ id: 'addCustomLabel', defaultMessage: 'Add custom label' })}
                  </Button>
                  <ButtonRow>
                    <Button priority="secondary" onClick={() => closePopUp()}>
                      {intl.formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                    </Button>
                    <Button priority="primary" type="submit" isLoading={isSubmitting}>
                      {intl.formatMessage({ id: 'save', defaultMessage: 'Save' })}
                    </Button>
                  </ButtonRow>
                </>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
      <Toast messages={messages} />
    </>
  );
};
