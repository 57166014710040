import { FormattedMessage, useIntl } from 'react-intl';
import { flushSync } from 'react-dom';
import type React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { ArrowDownIcon, ArrowUpIcon, DialogHeader, LoadingIndicator } from '@m/alchemy-ui';
import { hasValue } from '@m/magic-typescript';
import { type User, type UserFieldType } from '../../common/core-api';
import { GET_USER, type GetUserQuery } from '../../graphql/queries';
import { CommunityContext, UserContext } from '../../context';
import { evalScripts } from '../../../src/helpers';
import { NotificationStream } from '../notification-stream/NotificationStream';
import { useIsMobile } from '../../hooks';
import { Badges, Header, Menu, SocialMedia, TabContent, UserFields } from './components';
import FollowingContent from './components/FollowingTab/FollowingContent';
import { Settings } from './components/settings/Settings';
import * as Styled from './Profile.styled';

export interface DialogState {
  isOpened: boolean;
  headerContent: string;
  bodyContent: string;
}

export interface ProfileTab {
  component?: React.ReactNode;
  label: React.ReactNode | string;
  url: string;
  isPublic?: boolean;
}

export const USER_TABS: ProfileTab[] = [
  {
    label: <FormattedMessage id="activity" defaultMessage="Activity" />,
    url: 'user/activity',
    isPublic: true
  },
  {
    label: <FormattedMessage id="following" defaultMessage="Following" />,
    url: 'user/following',
    isPublic: true
  },
  {
    label: <FormattedMessage id="subscriptions" defaultMessage="Subscriptions" />,
    url: 'user/subscriptions',
    isPublic: true
  },
  {
    label: <FormattedMessage id="settings" defaultMessage="Settings" />,
    url: 'user/settings',
    component: <Settings />,
    isPublic: false
  },
  {
    label: <FormattedMessage id="badges" defaultMessage="Badges" />,
    url: 'user/badges',
    isPublic: false
  }
];

export const Profile = () => {
  const intl = useIntl();
  const community = useContext(CommunityContext);
  const currentUser = useContext(UserContext);

  const initialTab = window.location.pathname.slice(1);
  const [selectedTab, setSelectedTab] = useState(initialTab);

  const selectedUserId = window.location.pathname.split('/')?.[3] || currentUser.id;
  const {
    loading: loadingUser,
    data,
    refetch
  } = useQuery<GetUserQuery>(GET_USER, {
    variables: { id: selectedUserId }
  });

  const [dialogData, setDialogData] = useState({
    isOpened: false,
    headerContent: '',
    bodyContent: ''
  });

  const defaultPaginationValue = 100;
  const [userFollowing, setUserFollowing] = useState(defaultPaginationValue);
  const [userFollowers, setUserFollowers] = useState(defaultPaginationValue);

  const isMobileView = useIsMobile();
  const [showCollapseButton, setShowCollapseButton] = useState(isMobileView);
  const [userInfoOpen, setUserInfoOpen] = useState(!isMobileView);
  const [badgeDialogState, setBadgeDialogState] = useState(false);

  useEffect(() => {
    if (!showCollapseButton && isMobileView) {
      setShowCollapseButton(true);
      setUserInfoOpen(badgeDialogState);
    }

    if (showCollapseButton && !isMobileView) {
      setShowCollapseButton(false);
      setUserInfoOpen(true);
    }
  }, [badgeDialogState, isMobileView, showCollapseButton]);

  useEffect(() => {
    if (initialTab === 'user/ideastream') {
      setSelectedTab(initialTab);
    }
  }, [initialTab]);

  const closeDialog = () => setDialogData((prevState: DialogState) => ({ ...prevState, isOpened: false }));

  const onEditPopupLoaded = (result: Document) => {
    const header = result.querySelector('header');
    const form = result.querySelector('form');
    const scripts = result.querySelectorAll<HTMLScriptElement>('script');

    if (header && form && scripts.length > 0) {
      flushSync(() => {
        setDialogData({
          headerContent: header.outerHTML,
          bodyContent: form.outerHTML,
          isOpened: true
        });
      });

      evalScripts(scripts);
    }
  };

  const handleSaveProfile = useCallback(() => {
    closeDialog();
    refetch();
  }, [refetch]);

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    window.addEventListener('notifyPopupClosed', handleSaveProfile, false);

    return () => {
      window.removeEventListener('notifyPopupClosed', handleSaveProfile, false);
    };
  }, [handleSaveProfile]);

  const { user } = data || {};

  if (loadingUser || !user) {
    return <LoadingIndicator inline />;
  }

  const OWNER_TABS: ProfileTab[] = [
    {
      label: <FormattedMessage id="ideasstream" defaultMessage="Ideas Stream" />,
      url: 'user/ideastream',
      component: <NotificationStream />
    },
    community.options?.allowMessaging && {
      label: <FormattedMessage id="messaging" defaultMessage="Messaging" />,
      url: 'message/inbox'
    }
  ].filter(hasValue);

  const bioUserField: UserFieldType = {
    id: 'bio',
    name: intl.formatMessage({ id: 'bio', defaultMessage: 'Bio' }),
    value: user.bio,
    hidden: false
  };
  const userFields = [bioUserField, ...(user.userFields as UserFieldType[])].filter((field: UserFieldType) =>
    Boolean(field.value)
  );

  const isFollowingTab = selectedTab === `user/following` || selectedTab === `user/following/${user.id}`;
  const isProfileAvatarsDisabled = community?.options?.profileAvatarsDisabled;

  return (
    <>
      <Styled.ProfileWrapper>
        <Styled.ProfileSidebar>
          <Header
            user={user as User}
            showUserScores={community.options?.showUserScores || undefined}
            onEditPopupLoaded={onEditPopupLoaded}
            refetchUsers={handleSaveProfile}
          />
          <Menu
            ownerTabs={OWNER_TABS}
            userId={selectedUserId}
            userTabs={USER_TABS}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <Styled.BottomSection>
            {userInfoOpen && (
              <Styled.UserInfo>
                <Badges
                  user={user as User}
                  handleSaveBadges={handleRefetch}
                  handleBadgeDialogState={setBadgeDialogState}
                />
                {community.options?.allowSocialMediaLinksInProfile && <SocialMedia socialLinks={user?.socialLinks} />}
                <UserFields fields={userFields} />
              </Styled.UserInfo>
            )}
            {showCollapseButton &&
              (userInfoOpen ? (
                <Styled.UserInfoButton priority="tertiary" onClick={() => setUserInfoOpen(false)}>
                  {intl.formatMessage({ id: 'collapseUserProfile', defaultMessage: 'Collapse User Profile' })}
                  <ArrowUpIcon size={20} aria-hidden="true" />
                </Styled.UserInfoButton>
              ) : (
                <Styled.UserInfoButton priority="tertiary" onClick={() => setUserInfoOpen(true)}>
                  {intl.formatMessage({ id: 'expandUserProfile', defaultMessage: 'Expand User Profile' })}
                  <ArrowDownIcon size={20} aria-hidden="true" />
                </Styled.UserInfoButton>
              ))}
          </Styled.BottomSection>
        </Styled.ProfileSidebar>
        <Styled.TabContentWrapper>
          {isFollowingTab ? (
            <FollowingContent
              user={user as User}
              refetchUsers={handleRefetch}
              userFollowing={userFollowing}
              setUserFollowing={setUserFollowing}
              userFollowers={userFollowers}
              setUserFollowers={setUserFollowers}
            />
          ) : (
            <TabContent
              url={selectedTab}
              component={[...USER_TABS, ...OWNER_TABS].find((tab) => tab.url === selectedTab)?.component}
            />
          )}
        </Styled.TabContentWrapper>
      </Styled.ProfileWrapper>

      <Styled.ProfileDialog
        isCentered
        hasCloseIcon
        isOpen={dialogData.isOpened}
        onClose={closeDialog}
        size="extraLarge"
        padding={20}
        focusTrapOptions={{
          initialFocus: isProfileAvatarsDisabled
            ? false
            : user.profileImageFile
              ? '#deleteProfileImage'
              : '#fileButton-onboard'
        }}
      >
        <DialogHeader dangerouslySetInnerHTML={{ __html: dialogData.headerContent }} />
        <Styled.ProfileDialogBody dangerouslySetInnerHTML={{ __html: dialogData.bodyContent }} />
      </Styled.ProfileDialog>
    </>
  );
};
