import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';

export const GET_USER_FIELDS: DocumentNode = gql`
  query userFields {
    userFields {
      id
      name
      type
      options
    }
  }
`;

export const GET_USERFIELDS_FILTERED_USERIDS: DocumentNode = gql`
  query filteredUsers($filters: UserSearchFilters!) {
    filteredUsers(filters: $filters)
  }
`;
