export const generateScriptTag = (script: HTMLScriptElement) => {
  const newScript = document.createElement('script');
  script.type && (newScript.type = script.type);
  script.src && (newScript.src = script.src);

  const inlineScript = document.createTextNode(script.textContent || '');
  newScript.appendChild(inlineScript);
  newScript.dataset.dynamic = 'true';

  document.body.appendChild(newScript);
};

/**
 * It will eval the javascript code returned by the server with the objetive
 * of executing the code in the local scope.
 */
export const evalScripts = (scripts: NodeListOf<HTMLScriptElement>) => {
  scripts.forEach((script) => {
    if (script.src || script.type === 'x-template') {
      generateScriptTag(script);
    } else {
      try {
        /* Eslint disabled reason: It is not recommended to use eval or similiar code
         * like new Function because it has security implications but to avoid problems
         * with scope of the code we should use this  method.
         */
        // eslint-disable-next-line @typescript-eslint/no-implied-eval, no-new-func
        new Function(`"use strict"; ${script.textContent || ''}`)();
      } catch (e) {
        console.log(e);
      }
    }
  });
};
