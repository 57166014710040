import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  color,
  ConfirmCheckmarkIcon,
  EditIcon,
  IconButton,
  Popper,
  PopperBox,
  snap,
  ThumbsUpIcon,
  units
} from '@m/alchemy-ui';
import { useFormikContext } from 'formik';
import { type IntlShape, useIntl } from 'react-intl';
import FocusTrap from 'focus-trap-react';
import { type IChallenge } from '../../../common/interfaces';
import { type Milestone, MilestoneType } from '../../../common/core-api';
import { RocketIcon } from '../../../common/icons';
import { useChallenge } from '../context/Challenge';
import { MilestoneForm } from '.';

const MilestoneFormPopper = styled(PopperBox)`
  width: ${snap(1000)};
`;

const MilestoneIndicatorWrapper = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color('lineContrast')};
  border-radius: 50%;
  width: ${units(5)};
  height: ${units(5)};
  svg {
    color: ${color('inverse')};
    width: ${units(2.5)};
    height: ${units(2.5)};
  }
  cursor: pointer;
`;

function getIconByType(type: MilestoneType | null | undefined, intl: IntlShape) {
  switch (type) {
    case MilestoneType.SUBMITTED:
      return (
        <RocketIcon
          aria-label={intl.formatMessage({
            id: 'editSubmittedPhase',
            defaultMessage: 'Edit submitted phase'
          })}
        />
      );
    case MilestoneType.APPROVAL:
      return (
        <ConfirmCheckmarkIcon
          aria-label={intl.formatMessage({
            id: 'editApprovalPhase',
            defaultMessage: 'Edit approval phase'
          })}
        />
      );
    case MilestoneType.VOTE:
      return (
        <ThumbsUpIcon
          aria-label={intl.formatMessage({
            id: 'editVotePhase',
            defaultMessage: 'Edit vote phase'
          })}
        />
      );
    default:
      return (
        <EditIcon
          aria-label={intl.formatMessage({
            id: 'editPhase',
            defaultMessage: 'Edit Phase'
          })}
        />
      );
  }
}

export const MilestoneIndicator = ({
  milestone,
  milestoneIndex
}: {
  readonly milestone: Milestone;
  readonly milestoneIndex: number;
}) => {
  const { type } = milestone;
  const { challenge } = useChallenge();
  const { status } = useFormikContext<IChallenge>();

  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const hasError =
    challenge.focusError?.name?.includes('milestone.milestones') && challenge.focusError?.itemIndex === milestoneIndex;

  const handleMilestoneClick = (value = false) => {
    const popupsArray = document.querySelectorAll('.milestonePopper');
    const body = document.querySelector('#fullContainer') as HTMLElement;

    if (body && value) {
      /**
       * 1000px max default height of popup
       */
      body.style.paddingBottom = '1000px';
    } else if (body && !value && popupsArray.length < 2) {
      body.style.paddingBottom = '0';
    }

    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (hasError && !status?.saving) {
      setIsOpen(hasError);
    }
  }, [hasError, status?.saving]);

  return (
    <Popper
      shouldCloseOnOutsideInteraction={false}
      placement="bottom"
      isOpen={isOpen}
      trigger={
        <MilestoneIndicatorWrapper icon={getIconByType(type, intl)} onClick={() => handleMilestoneClick(true)} />
      }
      overlay={
        <FocusTrap
          focusTrapOptions={{
            // https://github.com/focus-trap/focus-trap-react#testing-in-jsdom
            tabbableOptions: { displayCheck: 'none' }
          }}
        >
          <div className="milestonePopper">
            <MilestoneFormPopper hasArrow hasPadding maxWidth={snap(1000)}>
              <MilestoneForm
                setPopperOpen={handleMilestoneClick}
                currentMilestone={milestone}
                currentMilestoneIndex={milestoneIndex}
              />
            </MilestoneFormPopper>
          </div>
        </FocusTrap>
      }
    />
  );
};
