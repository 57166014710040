import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';

export const GET_SCORECARDS: DocumentNode = gql`
  query getScorecards($id: String!) {
    getScorecards(id: $id) {
      id
      title
      isFunnel
      isMilestone
      voting {
        scorecards {
          cards {
            id
            name
            description
            order
          }
          labels {
            name
            rating
          }
        }
      }
      phases {
        id
        name
        voting {
          scorecards {
            cards {
              id
              name
              description
              order
            }
            labels {
              name
              rating
            }
          }
        }
      }
      milestone {
        milestones {
          id
          name
          voting {
            scorecards {
              cards {
                id
                name
                description
                order
              }
              labels {
                name
                rating
              }
            }
          }
        }
      }
    }
  }
`;
