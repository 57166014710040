import { type ChangeEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  borderRadius,
  ChangeUserIcon,
  Checkbox,
  color,
  ErrorIcon,
  typeStyle,
  units,
  UserGradientIcon
} from '@m/alchemy-ui';
import styled from 'styled-components';
import { type FormikProps, useFormikContext } from 'formik';
import { EVERYONE_GROUP_ID, type MixedTypes } from '../../../common/interfaces';
import { SelectorType } from '../../../common/enums';
import { hasModerationStatus, isUser } from '../helpers/access';
import { useFile } from '../../../hooks';
import {
  type AccessOption,
  type User,
  type UserGroup,
  type UserGroupWithModerationStatus,
  type UsersAndGroups,
  type UserWithModerationStatus
} from '../../../common/core-api';

const ListContianer = styled.div`
  border-bottom: 1px solid ${color('fill')};
  padding: ${units(0.6)} 0;
  display: flex;
  align-items: center;
`;
const ImageContainer = styled.div`
  color: ${color('lineContrast')};
  margin-right: ${units(1)};
  display: flex;
  align-items: center;
  min-width: ${units(3)};
  svg {
    width: ${units(3)};
    height: ${units(3)};
  }
`;
const CheckboxContainer = styled.div`
  background-color: ${color('fill')};
  padding: ${units(0.3)} ${units(0.5)};
  border-radius: ${borderRadius('medium')};
  margin-left: auto;
`;
const ModerateLabel = styled.span`
  display: flex;
  align-items: center;
  height: ${units(2.5)};
  ${typeStyle('captionS')};
`;
const RemoveAccessIcon = styled(ErrorIcon)`
  display: none;
  min-width: ${units(2)};
  margin-right: ${units(1)};
  color: ${color('base')};
  ${ListContianer}:hover &,
  ${ListContianer}:focus-within & {
    display: block;
  }
`;
const NameContainer = styled.div`
  max-width: ${units(20)};
  text-overflow: ellipsis;
`;
const UserProfileImage = styled.img`
  width: ${units(3)};
  height: ${units(3)};
  border-radius: 50%;
`;

export const AccessListItem = ({
  item,
  type
}: {
  readonly item: User | UserGroup | UserGroupWithModerationStatus | UserWithModerationStatus;
  readonly type: SelectorType;
}) => {
  const { values, setFieldValue }: FormikProps<MixedTypes> = useFormikContext();
  const [getFile] = useFile();
  const intl = useIntl();
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  useEffect(() => {
    const getAuthUrl = async () => {
      const file = isUser(item) && (hasModerationStatus(item) ? item.user.profileImageFile : item.profileImageFile);
      let url = '';
      if (file) {
        url = await getFile({ file, width: 22, height: 22 });
      }

      setProfilePictureUrl(url);
    };

    getAuthUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  let access: AccessOption;
  let moderators: UsersAndGroups | null | undefined;
  if (type === SelectorType.CHALLENGE || type === SelectorType.PHASE) {
    access = values.access;
  }

  if (type === SelectorType.MILESTONE_MODERATE) {
    moderators = values.moderators;
  }

  if (type === SelectorType.MILESTONE_VOTE) {
    moderators = values.voters;
  }

  const { name, id } = isUser(item)
    ? hasModerationStatus(item)
      ? { name: item.user.displayName, id: item.user.id }
      : { name: item.displayName, id: item.id }
    : hasModerationStatus(item)
      ? { name: item.usergroup.name, id: item.usergroup.id }
      : { name: item.name, id: item.id };

  const getUserProfileImage = () => {
    const file = isUser(item) && (hasModerationStatus(item) ? item.user.profileImageFile : item.profileImageFile);
    if (file) {
      return (
        <UserProfileImage
          src={profilePictureUrl}
          alt={intl.formatMessage({ id: 'profileImageAlt', defaultMessage: 'Profile image of {name}' }, { name })}
        />
      );
    }

    return <UserGradientIcon aria-hidden="true" />;
  };

  const handleModerateStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = isUser(item)
      ? access?.users?.map((user: UserWithModerationStatus) =>
          hasModerationStatus(item) && user.user.id === item.user.id ? { ...user, canModerate: e.target.checked } : user
        )
      : access?.groups?.map((group: UserGroupWithModerationStatus) =>
          hasModerationStatus(item) && group.usergroup.id === item.usergroup.id
            ? { ...group, canModerate: e.target.checked }
            : group
        );
    const newAccessValue = isUser(item) ? { ...access, users: newValue } : { ...access, groups: newValue };

    if (type === SelectorType.CHALLENGE || type === SelectorType.PHASE) {
      setFieldValue('access', newAccessValue);
    }
  };

  const handleAccessRemoveClick = () => {
    let newValue;
    let newAccessValue;
    if (hasModerationStatus(item)) {
      newValue = isUser(item)
        ? access?.users?.filter((user) => user.user.id !== item.user.id)
        : access?.groups?.filter((group) => group.usergroup.id !== item.usergroup.id);
      newAccessValue = isUser(item) ? { ...access, users: newValue } : { ...access, groups: newValue };
    } else {
      newValue = isUser(item)
        ? moderators?.users?.filter((user) => user.id !== item.id)
        : moderators?.groups?.filter((group) => group.id !== item.id);
      newAccessValue = isUser(item) ? { ...moderators, users: newValue } : { ...moderators, groups: newValue };
    }

    if (type === SelectorType.CHALLENGE || type === SelectorType.PHASE) {
      setFieldValue('access', newAccessValue);
    }

    if (type === SelectorType.MILESTONE_MODERATE) {
      setFieldValue('moderators', newAccessValue);
    }

    if (type === SelectorType.MILESTONE_VOTE) {
      setFieldValue('voters', newAccessValue);
    }
  };

  return (
    <ListContianer tabIndex={0}>
      <RemoveAccessIcon
        data-testid="removeAccessButton"
        onClick={handleAccessRemoveClick}
        onKeyDown={(ev) => ev.key === 'Enter' && handleAccessRemoveClick}
        tabIndex={0}
        role="button"
        aria-label={intl.formatMessage({ id: 'removeAccessIcon', defaultMessage: 'Remove Access' })}
      />
      <ImageContainer data-testid="user-image">
        {isUser(item) ? getUserProfileImage() : <ChangeUserIcon aria-hidden="true" />}
      </ImageContainer>
      <NameContainer data-testid="user-name">{name}</NameContainer>
      {hasModerationStatus(item) &&
        (id === EVERYONE_GROUP_ID ? null : (
          <CheckboxContainer>
            <Checkbox
              data-testid="accessModerationStatus"
              label={
                <ModerateLabel>
                  {intl.formatMessage({ id: 'moderationStatus', defaultMessage: 'Moderate' })}
                </ModerateLabel>
              }
              name="accessModerationStatus"
              checked={item.canModerate}
              onChange={handleModerateStatusChange}
            />
          </CheckboxContainer>
        ))}
    </ListContianer>
  );
};
