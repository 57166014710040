import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';
import { apiRequestParts } from '../queries/api';

export const SAVE_API_REQUEST: DocumentNode = gql`
  mutation saveApiRequest($input: ApiRequestInput!) {
    saveApiRequest(input: $input) {
      ...apiRequest
    }
  }
  ${apiRequestParts}
`;

export const SAVE_API_CLIENT: DocumentNode = gql`
  mutation saveApiClient($input: ApiClientInput!) {
    saveApiClient(input: $input) {
      id
      password
    }
  }
`;
