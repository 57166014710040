import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox, CheckboxGroup, Toast } from '@m/alchemy-ui';
import axios from 'axios';
import { CommunityContext, UserContext, useRuntimeUser } from '../../../../context';
import { type ToastMessageType, UseroidTypes } from '../../../../common/enums';
import { getConfirmToastMessage, getErrorToastMessage } from '../../../../helpers';
import { defaultNotificationSettingsValues, type NotificationSettingsValues } from '../../../../common/interfaces';
import * as Styled from './Notifications.styled';

type BinaryKeyType = Record<string, unknown> | number | string | null;

export const Notifications = () => {
  const intl = useIntl();

  const community = useContext(CommunityContext);
  const user = useContext(UserContext);
  const { actionKey } = useRuntimeUser();

  const useroidProviders = user.useroids?.map((useroid) => useroid.provider);

  const [messages, setMessages] = useState<ToastMessageType[]>();

  const [values, setValues] = useState<NotificationSettingsValues>(defaultNotificationSettingsValues);

  useEffect(() => {
    if (user) {
      setValues({
        action: 'saveEmailSettings',
        useraccess_receiveinstancebulkemails: user.userAccess?.receiveEmails,
        user_emailforpostcomment: user.emailforpostcomment,
        user_emailforpostvote: user.emailforpostvote,
        user_emailforpostfollow: user.emailforpostfollow,
        user_emailforuserfollow: user.emailforuserfollow,
        user_emailforcommentreply: user.emailforcommentreply,
        user_emailforcommentvote: user.emailforcommentvote,
        user_slackforpostcomment: user.notificationSettings?.slackforpostcomment,
        user_slackforpostvote: user.notificationSettings?.slackforpostvote,
        user_slackforpostfollow: user.notificationSettings?.slackforpostfollow
      });
    }
  }, [user]);

  const globalNotificationsItems = [
    {
      name: 'useraccess_receiveinstancebulkemails',
      label: <FormattedMessage id="receiveEmails" defaultMessage="Receive emails from community admins" />,
      checked: values.useraccess_receiveinstancebulkemails
    }
  ];

  const communityNotification = [
    {
      name: 'user_emailforpostcomment',
      label: <FormattedMessage id="someoneComments" defaultMessage="Someone comments on my idea" />,
      checked: values.user_emailforpostcomment
    },
    {
      name: 'user_emailforpostvote',
      label: <FormattedMessage id="myIdeaVotes" defaultMessage="My idea receives a vote" />,
      checked: values.user_emailforpostvote
    },
    {
      name: 'user_emailforpostfollow',
      label: <FormattedMessage id="myIdeaFollowers" defaultMessage="My idea is followed" />,
      checked: values.user_emailforpostfollow
    },
    {
      name: 'user_emailforuserfollow',
      label: <FormattedMessage id="someoneStarts" defaultMessage="Someone starts following me" />,
      checked: values.user_emailforuserfollow
    },
    {
      name: 'user_emailforcommentreply',
      label: <FormattedMessage id="someoneReplies" defaultMessage="Someone replies to my comment" />,
      checked: values.user_emailforcommentreply
    },
    {
      name: 'user_emailforcommentvote',
      label: <FormattedMessage id="someoneLikes" defaultMessage="Someone likes or dislikes my comment" />,
      checked: values.user_emailforcommentvote
    }
  ];

  const slackNotification = [
    {
      name: 'user_slackforpostcomment',
      label: (
        <FormattedMessage
          id="commentsOnIdeaSubscribed"
          defaultMessage="Someone comments on an idea to which I am subscribed"
        />
      ),
      checked: values.user_slackforpostcomment
    },
    {
      name: 'user_slackforpostvote',
      label: <FormattedMessage id="myIdeaVotes" defaultMessage="My idea receives a vote" />,
      checked: values.user_slackforpostvote
    },
    {
      name: 'user_slackforpostfollow',
      label: <FormattedMessage id="myIdeaFollowers" defaultMessage="My idea is followed" />,
      checked: values.user_slackforpostfollow
    }
  ];

  const handleSelectionChange = (selection: BinaryKeyType | BinaryKeyType[]) => {
    if (!Array.isArray(selection)) {
      return;
    }

    const changedItem = selection[selection.length - 1];

    if (changedItem && typeof changedItem === 'object') {
      setValues({ ...values, [String(changedItem.name)]: !changedItem.checked });
    }
  };

  const saveNotifications = () => {
    const data = new FormData();
    data.append('JsonResponse', 'true');
    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        data.append(key, String(value));
      }
    });

    return axios({
      url: `/user/emailsettings?ak=${actionKey}`,
      method: 'POST',
      data
    }).then((res) => {
      if (res.data.success) {
        setMessages(
          getConfirmToastMessage(intl.formatMessage({ id: 'saveSuccess', defaultMessage: 'Save successful' }))
        );
      } else {
        setMessages(getErrorToastMessage(intl.formatMessage({ id: 'saveFail', defaultMessage: 'Save failed' })));
      }
    });
  };

  return (
    <Styled.NotificationsWrapper>
      <Styled.Title>
        <FormattedMessage
          id="notificationsFrom"
          defaultMessage="Notifications from {communityName}"
          values={{
            communityName: community?.name
          }}
        />
      </Styled.Title>
      <Styled.HelpText>
        <FormattedMessage
          id="settingsOnly"
          defaultMessage="These settings only apply to this Medallia Ideas community."
        />
      </Styled.HelpText>
      <CheckboxGroup
        selection={globalNotificationsItems.filter((item) => item.checked).map((item) => item.name)}
        onChange={(selection) => handleSelectionChange(selection)}
      >
        {({ isSelected, handleChange }) =>
          globalNotificationsItems.map((item) => (
            <Checkbox
              label={item.label}
              key={item.name}
              name={item.name}
              checked={isSelected(item.name)}
              onChange={() => handleChange(item)}
            />
          ))
        }
      </CheckboxGroup>

      {/* Slack Notifications */}
      {community.options?.enableSlackIntegration && useroidProviders?.includes(UseroidTypes.PROVIDER_SLACK) && (
        <>
          <Styled.Subtitle>
            <FormattedMessage id="SlackNotification" defaultMessage="Send a Slack notification when" />
          </Styled.Subtitle>
          <Styled.HelpText>
            <FormattedMessage
              id="settingsOnly"
              defaultMessage="These settings only apply to this Medallia Ideas community."
            />
          </Styled.HelpText>
          <CheckboxGroup
            selection={slackNotification.filter((item) => item.checked).map((item) => item.name)}
            onChange={(selection) => handleSelectionChange(selection)}
          >
            {({ isSelected, handleChange }) =>
              slackNotification.map((item) => (
                <Checkbox
                  label={item.label}
                  key={item.name}
                  name={item.name}
                  checked={isSelected(item.name)}
                  onChange={() => handleChange(item)}
                />
              ))
            }
          </CheckboxGroup>
        </>
      )}

      {/* Email Notifications */}
      <Styled.Subtitle>
        <FormattedMessage
          id="NotificationFromCommunity"
          defaultMessage="Notifications from any Medallia Ideas community"
        />
      </Styled.Subtitle>
      <Styled.HelpText>
        <FormattedMessage
          id="settingsApply"
          defaultMessage="These settings apply to notifications from any Medallia Ideas community that you participate in."
        />
      </Styled.HelpText>
      <CheckboxGroup
        selection={communityNotification.filter((item) => item.checked).map((item) => item.name)}
        onChange={(selection) => handleSelectionChange(selection)}
      >
        {({ isSelected, handleChange }) =>
          communityNotification.map((item) => (
            <Checkbox
              label={item.label}
              key={item.name}
              name={item.name}
              checked={isSelected(item.name)}
              onChange={() => handleChange(item)}
            />
          ))
        }
      </CheckboxGroup>

      <Styled.SaveButton type="button" priority="primary" onClick={saveNotifications}>
        <FormattedMessage id="save" defaultMessage="Save" />
      </Styled.SaveButton>
      <Toast messages={messages} />
    </Styled.NotificationsWrapper>
  );
};
