import { type ReactNode, useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  borderRadius,
  Button,
  color,
  DeleteIcon,
  ExclamationTriangleIcon,
  ProgressBar,
  snap,
  units
} from '@m/alchemy-ui';
import { useDropzone } from 'react-dropzone';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { FileUploadType } from '../enums';
import { type IChallenge } from '../interfaces';
import { useApiV2, useFile } from '../../hooks';
import { type File as FileType } from '../core-api';
import { UserContext } from '../../context';
import { DeleteIconButton } from '.';

interface ImagePreviewProps {
  url: string;
}

const Wrapper = styled.div`
  display: flex;
  border: 2px dashed ${color('line')};
  border-radius: ${borderRadius('large')};
`;
const ChildrenContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: ${units(2)};
  & button {
    margin-top: ${units(2)};
  }
`;
const ImagePreview = styled.div<ImagePreviewProps>`
  width: 100%;
  height: ${snap(200)};
  border-radius: ${borderRadius('large')};
  ${({ url }) => css`
    background: url(${url}) no-repeat center;
    background-size: cover;
  `}
`;
const StyledDeleteIconButton = styled(DeleteIconButton)`
  position: absolute;
  bottom: ${units(0.5)};
  right: ${units(0.5)};
  margin: ${units(2)};
`;
const StyledProgressBar = styled(ProgressBar)`
  padding: 0 ${units(1)};
  margin-top: ${units(1)};
`;
const UploadFailMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${color('base', { palette: 'interactiveDestructive' })};
  margin-top: ${units(1)};
`;
const StyledWarningIcon = styled(ExclamationTriangleIcon)`
  margin-right: ${units(1)};
`;

interface UploadProps {
  readonly buttonLabel: string;
  readonly children: ReactNode;
  readonly fileTypes: string[];
  readonly type: FileUploadType;
  readonly labelId?: string;
  readonly describedBy?: string;
}

export const SingleUpload = ({ buttonLabel, children, fileTypes = [], type, labelId, describedBy }: UploadProps) => {
  const { values: challenge, setFieldValue } = useFormikContext<IChallenge>();
  const intl = useIntl();
  const user = useContext(UserContext);

  const [getFile] = useFile();
  const [uploadFile] = useApiV2();

  const [percent, setPercent] = useState(0);
  const [uploadedFile, setUploadedFile] = useState<FileType>();
  const [uploadFailedFile, setUploadFailedFile] = useState<File>();
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  useEffect(() => {
    const getAuthUrl = async () => {
      let url = '';
      if (uploadedFile) {
        url = await getFile({ file: uploadedFile, height: 300 });
      }

      setImagePreviewUrl(url);
    };

    getAuthUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFile]);

  useEffect(() => {
    if (!uploadedFile) {
      if (type === FileUploadType.CHALLENGE_HEADER) {
        setUploadedFile(challenge.files?.header || undefined);
      }

      if (type === FileUploadType.CHALLENGE_LISTING) {
        setUploadedFile(challenge.files?.list || undefined);
      }
    }
  }, [challenge.files, type, uploadedFile]);

  const onDrop = (acceptedFiles: File[]) => {
    uploadFile({ file: acceptedFiles[0], updateProgress: setPercent })
      .then((res) => {
        if (res.data.response && !res.data.response.success) {
          setUploadFailedFile(acceptedFiles[0]);
          return;
        }

        if (uploadFailedFile) {
          setUploadFailedFile(undefined);
        }

        const { id, name, storage, hash, ext } = res.data;
        const newFile = {
          id: String(id),
          name,
          url: storage.url,
          hash,
          extension: ext,
          userId: user.id
        };
        setPercent(100);

        if (type === FileUploadType.CHALLENGE_HEADER) {
          setFieldValue('files', { ...challenge.files, header: newFile });
        }

        if (type === FileUploadType.CHALLENGE_LISTING) {
          setFieldValue('files', { ...challenge.files, list: newFile });
        }
      })
      .catch(() => {
        setUploadFailedFile(acceptedFiles[0]);
      });
  };

  const acceptFileTypes = fileTypes.map((type) => '.' + type).join(', ');
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptFileTypes
  });

  const handleFileDeleteClick = () => {
    setPercent(0);
    setUploadedFile(undefined);

    if (type === FileUploadType.CHALLENGE_HEADER) {
      setFieldValue('files', { ...challenge.files, header: null });
    }

    if (type === FileUploadType.CHALLENGE_LISTING) {
      setFieldValue('files', { ...challenge.files, list: null });
    }
  };

  return (
    <Wrapper>
      {uploadedFile ? (
        <ImagePreview url={imagePreviewUrl}>
          <StyledDeleteIconButton
            icon={<DeleteIcon aria-label={intl.formatMessage({ id: 'deleteIcon', defaultMessage: 'Delete icon' })} />}
            tooltipContents={intl.formatMessage({ id: 'delete', defaultMessage: 'Delete' })}
            onClick={handleFileDeleteClick}
          />
        </ImagePreview>
      ) : (
        <ChildrenContainer>
          <div
            data-testid="dropzone"
            {...getRootProps({ className: 'dropzone' })}
            aria-labelledby={`${labelId} ${describedBy}`}
          >
            <input {...getInputProps()} multiple={false} />
            {children}
            <Button priority="secondary">{buttonLabel}</Button>
          </div>
          {!uploadFailedFile && percent > 0 && percent <= 100 && <StyledProgressBar value={percent / 100} />}
          {uploadFailedFile && (
            <UploadFailMessage>
              <StyledWarningIcon aria-hidden="true" />
              {intl.formatMessage(
                {
                  id: 'fileUploadFailMessage',
                  defaultMessage: 'Failed to upload {name}'
                },
                { name: uploadFailedFile.name }
              )}
            </UploadFailMessage>
          )}
        </ChildrenContainer>
      )}
    </Wrapper>
  );
};
