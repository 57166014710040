import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';

export const USER_UNFOLLOW: DocumentNode = gql`
  mutation unfollow($targetUser: String!) {
    unfollowUser(targetUser: $targetUser) {
      success
      message
    }
  }
`;

export const USER_FOLLOW: DocumentNode = gql`
  mutation follow($targetUser: String!) {
    followUser(targetUser: $targetUser) {
      success
      message
    }
  }
`;
