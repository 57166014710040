import { forwardRef, useEffect, useRef, useState } from 'react';
import {
  ActivityEvent,
  ActivityFeed,
  AddIcon,
  breakpoint,
  Button,
  color,
  Dialog,
  LoadingIndicator,
  Pill,
  Select,
  typeStyle,
  units
} from '@m/alchemy-ui';
import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CLONE_CHALLENGE,
  type CloneMutation,
  CREATE_CHALLENGE,
  type CreateMutation,
  type TemplateMutation
} from '../../graphql/mutations';
import { type ChallengesQuery, GET_CHALLENGES, GET_TEMPLATES, type TemplatesQuery } from '../../graphql/queries';
import { DELETE_TEMPLATE } from '../../graphql/mutations/template';
import { CloneTemplateIcon } from '../../common/icons';
import { useFile, useUrlPath } from '../../hooks';
import { ChallengeArchived } from '../../common/enums';
import { type Challenge, type GlobalTemplate, type Milestone, type Phase, type Template } from '../../common/core-api';
import { Card } from './components/Card';
import { useChallengeDispatch } from './context/Challenge';
import { ChallengeActionTypes } from './reducers/challenge';
import { TemplateCard } from './components/TemplateCard';

const AddButton = styled(Button)`
  height: 100%;
  width: 100%;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledAddIcon = styled(AddIcon)`
  height: ${units(4)};
  width: ${units(4)};
`;

export interface Messages {
  messages: {
    default: {
      name: string;
    };
  };
}

export interface TemplateWithDetails extends Template {
  detail?: {
    phases?: [Messages & Partial<Phase>];
    milestone?: {
      milestones: [Messages & Partial<Milestone>];
    };
  };
  isGlobal: boolean;
}

export interface GlobalTemplateWithDetails extends GlobalTemplate {
  detail?: {
    phases?: [Messages & Partial<Phase>];
    milestone?: {
      milestones: [Messages & Partial<Milestone>];
    };
  };
  isGlobal: boolean;
}

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    @media (min-width: ${breakpoint('large')}px) {
      width: calc(20% - ${units(2)});
    }
    @media (max-width: ${breakpoint('medium')}px) {
      width: calc(50% - ${units(2)});
    }
    @media (max-width: ${breakpoint('small')}px) {
      width: calc(100% - ${units(2)});
    }
    margin: ${units(1)};
  }
`;

const StyledLabel = styled.div`
  &:before {
    content: '— ';
  }
  &:after {
    content: ' —';
  }
`;

const ActivityEventIndicator = styled.div`
  width: ${units(0.5)};
  height: ${units(0.5)};
  padding: ${units(0.5)};
  background-color: ${color('brandPrimary')};
`;

const StyledSelect = styled(Select)`
  & > div {
    padding-right: ${units(4.37)};
  }
`;

const StyledPill = styled(Pill)`
  margin-right: ${units(1)};
  ${typeStyle('captionM')}
`;

const Term = styled.dt`
  width: 30%;
  color: ${color('typeMeta')};
`;
const Description = styled.dd`
  flex: 70%;
`;
const TemplateDetails = styled.dl`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  & > * {
    margin-bottom: ${units(1)};
  }
`;

interface CreateProps {
  readonly dispatchTabChange: (action: { type: string; value?: string }) => void;
  readonly userId: string;
}

export const Create = forwardRef<HTMLDivElement, CreateProps>(({ dispatchTabChange, userId }, ref) => {
  const intl = useIntl();
  const dispatch = useChallengeDispatch();
  const [getFile] = useFile();

  const templateRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const [isTemplateDetailsOpen, setIsTemplateDetailsOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<GlobalTemplateWithDetails | TemplateWithDetails | null>(
    null
  );
  const closeTemplateDetails = (id: string | undefined) => {
    setIsTemplateDetailsOpen(false);
    setTimeout(() => {
      templateRefs.current[id || '']?.focus();
    }, 100);
  };

  const { data, loading: getChallengesLoading } = useQuery<ChallengesQuery>(GET_CHALLENGES, {
    variables: {
      includeUserCreated: false
    }
  });
  const { data: templates, loading: templatesLoading } = useQuery<TemplatesQuery>(GET_TEMPLATES);
  const [createChallenge, { loading: createChallengeLoading }] = useMutation<CreateMutation>(CREATE_CHALLENGE);
  const [cloneChallenge, { loading: cloneChallengeLoading }] = useMutation<CloneMutation>(CLONE_CHALLENGE);
  const [deleteTemplate] = useMutation<TemplateMutation>(DELETE_TEMPLATE);

  const [communityTemplates, setCommunityTemplates] = useState<Template[]>();
  const [isCreatingNow, setIsCreatingNow] = useState<boolean>(false);

  const [addUrlPath] = useUrlPath();

  useEffect(() => {
    const setAuthImages = async () => {
      if (templates?.templates && !templatesLoading) {
        const updatedCommunityTemplates = templates.templates.community.map(async (template) => {
          const file = JSON.parse(template.template)?.files?.list;

          if (file) {
            const url = await getFile({ file, width: 180, height: 90 });
            file.url = url;
            const jsonTemplate = JSON.parse(template.template);
            jsonTemplate.files.list = file;
            return { ...template, template: JSON.stringify(jsonTemplate) };
          }

          return template;
        });

        Promise.all(updatedCommunityTemplates).then((updatedTemplates) => {
          setCommunityTemplates(updatedTemplates);
        });
      }
    };

    setAuthImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates, templatesLoading]);

  const isCommunityTemplate = (item: GlobalTemplate | Template): item is Template =>
    (item as Template).userId !== undefined;

  const setChallenge = ({ challenge, createNow }: { challenge: Challenge; createNow?: boolean }) => {
    dispatch({
      type: ChallengeActionTypes.SET_CHALLENGE,
      value: { ...challenge, isNew: true, loading: false }
    });

    if (createNow) {
      window.location.href = challenge.isFunnel ? '/category/admin_view' : `/category/${challenge.id}`;
    } else {
      addUrlPath(challenge.id);
      dispatchTabChange({ type: 'FORWARD' });
    }
  };

  const handleCreateClick = async (templateId?: string, archived = 1, isGlobal = false, createNow = false) => {
    setIsCreatingNow(Boolean(createNow));
    const { data } = await createChallenge({ variables: { input: { templateId, archived, isGlobal } } });
    if (data?.createChallenge) {
      setChallenge({ challenge: data.createChallenge, createNow });
    }
  };

  const handleCloneClick = async (id: string) => {
    const { data } = await cloneChallenge({ variables: { id } });
    if (data?.cloneChallenge) {
      setChallenge({ challenge: data?.cloneChallenge });
    }
  };

  const handleTemplateSelect = (template: GlobalTemplate | Template) => {
    setIsTemplateDetailsOpen(true);
    setSelectedTemplate({
      ...template,
      detail: JSON.parse(template.template),
      isGlobal: !isCommunityTemplate(template)
    });
  };

  const handleTemplateDelete = async (id: string) => {
    const { data } = await deleteTemplate({ variables: { id } });

    if (data?.deleteTemplate) {
      const templatesValue = communityTemplates?.filter((template) => template.id !== id);
      templatesValue && setCommunityTemplates(templatesValue);
    }
  };

  const DEFAULT_CHALLENGE_TITLE = 'New challenge name';

  const publishedStatus: Record<number, string> = {
    [ChallengeArchived.PUBLISHED]: intl.formatMessage({ id: 'published', defaultMessage: 'Published' }),
    [ChallengeArchived.UNPUBLISHED]: intl.formatMessage({ id: 'unpublished', defaultMessage: 'Unpublished' }),
    [ChallengeArchived.ARCHIVED]: intl.formatMessage({ id: 'archived', defaultMessage: 'Archived' })
  };

  const challengeOptions =
    (!getChallengesLoading &&
      data?.challenges?.map(({ id, archived, title }) => ({
        label: (
          <>
            {archived === ChallengeArchived.PUBLISHED ? null : (
              <StyledPill>{archived && publishedStatus[archived]}</StyledPill>
            )}
            {title || DEFAULT_CHALLENGE_TITLE}
          </>
        ),
        value: id,
        archived
      }))) ||
    [];

  return (
    <FlexContainer>
      <Card
        ref={ref}
        cardImage={
          <AddButton>
            <StyledAddIcon aria-hidden="true" />
          </AddButton>
        }
        cardTitle={
          createChallengeLoading ? (
            <LoadingIndicator />
          ) : (
            intl.formatMessage({ id: 'createWithoutTemplate', defaultMessage: 'Create without template' })
          )
        }
        onCardClick={() => handleCreateClick()}
      />
      <Card
        cardImage={<CloneTemplateIcon />}
        cardTitle={
          cloneChallengeLoading ? (
            <LoadingIndicator />
          ) : (
            <StyledSelect
              isLoading={getChallengesLoading}
              placeholder={intl.formatMessage({ id: 'cloneChallenge', defaultMessage: 'Clone a challenge' })}
              options={[
                {
                  label: (
                    <StyledLabel>
                      {intl.formatMessage({ id: 'selectChallenge', defaultMessage: 'Select challenge' })}
                    </StyledLabel>
                  ),
                  disabled: true,
                  value: '',
                  selected: true
                },
                ...challengeOptions
              ]}
              onChange={({ value }) => handleCloneClick(value)}
            />
          )
        }
        onCardClick={() => {}}
      />

      {templates?.templates &&
        !templatesLoading &&
        templates?.templates.global.map((template) => (
          <TemplateCard
            template={template}
            community={false}
            handleTemplateSelect={handleTemplateSelect}
            handleTemplateDelete={handleTemplateDelete}
            userId={userId}
            key={template.id}
            ref={(el) => {
              templateRefs.current[template.id] = el;
            }}
          />
        ))}

      {communityTemplates?.map((template) => (
        <TemplateCard
          template={template}
          community
          handleTemplateSelect={handleTemplateSelect}
          handleTemplateDelete={handleTemplateDelete}
          userId={userId}
          key={template.id}
          ref={(el) => {
            templateRefs.current[template.id] = el;
          }}
        />
      ))}

      <Dialog
        isOpen={isTemplateDetailsOpen}
        onClose={() => closeTemplateDetails(selectedTemplate?.id)}
        size="medium"
        headerContent="Review your challenge information"
        footerButtons={[
          {
            label: intl.formatMessage({
              id: 'cancel',
              defaultMessage: 'Cancel'
            }),
            disabled: createChallengeLoading,
            onClick: () => closeTemplateDetails(selectedTemplate?.id),
            priority: 'tertiary'
          },
          {
            label: intl.formatMessage({
              id: 'customise',
              defaultMessage: 'Customise'
            }),
            isLoading: !isCreatingNow && createChallengeLoading,
            disabled: isCreatingNow && createChallengeLoading,
            onClick: () => {
              (async () => {
                await handleCreateClick(
                  selectedTemplate?.id,
                  ChallengeArchived.UNPUBLISHED,
                  selectedTemplate?.isGlobal
                );
              })();
            },
            priority: 'secondary'
          },
          {
            label: intl.formatMessage({
              id: 'createNow',
              defaultMessage: 'Create Now'
            }),
            isLoading: isCreatingNow && createChallengeLoading,
            disabled: !isCreatingNow && createChallengeLoading,
            onClick: () => {
              (async () => {
                await handleCreateClick(
                  selectedTemplate?.id,
                  ChallengeArchived.PUBLISHED,
                  selectedTemplate?.isGlobal,
                  true
                );
              })();
            }
          }
        ]}
        bodyContent={
          <TemplateDetails>
            <Term>
              <FormattedMessage id="templateDetailsTitle" defaultMessage="Template title:" />
            </Term>
            <Description>{selectedTemplate?.name}</Description>
            {selectedTemplate?.description && (
              <>
                <Term>
                  <FormattedMessage id="templateDetailsDescription" defaultMessage="Template description:" />
                </Term>
                <Description>{selectedTemplate?.description}</Description>
              </>
            )}
            <Term>
              <FormattedMessage id="templateDetailsChallengeType" defaultMessage="Challenge type:" />
            </Term>
            {selectedTemplate?.detail && (
              <Description>
                {selectedTemplate.detail.phases && <FormattedMessage id="funnel" defaultMessage="Funnel" />}
                {selectedTemplate.detail.milestone && <FormattedMessage id="milestone" defaultMessage="Milestone" />}
                {!selectedTemplate.detail.milestone && !selectedTemplate.detail.phases && (
                  <FormattedMessage id="standard" defaultMessage="Standard" />
                )}
              </Description>
            )}
            {selectedTemplate?.detail && selectedTemplate.detail.phases && (
              <>
                <Term>
                  <FormattedMessage id="templateDetailsPhases" defaultMessage="Phases" />
                </Term>
                <Description>
                  <ActivityFeed>
                    {selectedTemplate.detail.phases &&
                      selectedTemplate.detail.phases.map((phase) => (
                        <ActivityEvent
                          title={phase?.messages?.default?.name || '<unnamed phase>'}
                          customIndicator={<ActivityEventIndicator />}
                          key={phase.id}
                        />
                      ))}
                  </ActivityFeed>
                </Description>
              </>
            )}
            {selectedTemplate?.detail && selectedTemplate.detail.milestone && (
              <>
                <Term>
                  <FormattedMessage id="templateDetailsMilestones" defaultMessage="Milestone names & types" />
                </Term>
                <Description>
                  <ActivityFeed>
                    {selectedTemplate.detail.milestone &&
                      selectedTemplate.detail.milestone.milestones.map((milestone) => (
                        <ActivityEvent
                          title={milestone.messages.default.name}
                          meta={
                            <FormattedMessage
                              id="milestoneType"
                              defaultMessage="Type: {milestoneType}"
                              values={{
                                milestoneType: milestone.type?.toLowerCase()
                              }}
                            />
                          }
                          customIndicator={<ActivityEventIndicator />}
                          key={milestone.id}
                        />
                      ))}
                  </ActivityFeed>
                </Description>
              </>
            )}
          </TemplateDetails>
        }
      />
    </FlexContainer>
  );
});

Create.displayName = 'Create';
