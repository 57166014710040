import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';

export const UPDATE_STEALTH_MODE: DocumentNode = gql`
  mutation stealth($enabled: Boolean!) {
    updateStealth(enabled: $enabled) {
      success
    }
  }
`;
