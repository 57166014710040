import { useIntl } from 'react-intl';
import * as Styled from '../Navigation.styled';
import { useRuntimeCommunity } from '../../../context';

export const DEFAULT_COMMUNITY_LOGO = '/r/templates/common/icons/medallia-ideas-logo-white.svg';

export const CommunityLogo = ({ lightLogo = false }: { readonly lightLogo?: boolean } = { lightLogo: false }) => {
  const { logo, name } = useRuntimeCommunity();

  const intl = useIntl();

  const logoSrc = lightLogo && logo.includes('medallia-ideas') ? DEFAULT_COMMUNITY_LOGO : logo;

  return logo ? (
    <Styled.AppLogo shouldHideOnMobile>
      <Styled.CommunityLogo
        aria-label={name}
        src={logoSrc}
        data-testid="CommunityLogo"
        alt={intl.formatMessage({
          id: 'communityLogo',
          defaultMessage: 'Community logo'
        })}
      />
    </Styled.AppLogo>
  ) : (
    <span data-testid="EmptyCommunityLogo" />
  );
};
