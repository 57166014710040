/* eslint-disable @typescript-eslint/unbound-method */
import { useMutation, useQuery } from '@apollo/client';
import { Button, DeleteIcon, Input, LoadingIndicator, Select, Toast } from '@m/alchemy-ui';
import { FieldArray, Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import { ButtonRow, Container, DeleteIconButton, Title } from '../../../../common/components';
import { type SocialLinkType, SocialMediaType } from '../../../../common/core-api';
import { UserContext } from '../../../../context';
import { SAVE_SOCIAL_MEDIA, type SaveSocialMediaMutation } from '../../../../graphql/mutations';
import { GET_SOCIAL_MEDIA, type SocialMediaQuery } from '../../../../graphql/queries';
import { type ToastMessageType } from '../../../../common/enums';
import { getConfirmToastMessage, getErrorToastMessage } from '../../../../helpers';
import { useFormikError } from '../../../../hooks';
import * as Styled from './SocialMedia.styled';

export const SocialMedia = () => {
  const [messages, setMessages] = useState<ToastMessageType[]>([]);
  const { data, loading: getSocialMediaLoading } = useQuery<SocialMediaQuery>(GET_SOCIAL_MEDIA);
  const [saveSocialMedia, { loading: saveSocialMediaLoading }] =
    useMutation<SaveSocialMediaMutation>(SAVE_SOCIAL_MEDIA);
  const user = useContext(UserContext);
  const [fieldError] = useFormikError();
  const intl = useIntl();

  const throwSaveFailToast = () =>
    setMessages(getErrorToastMessage(intl.formatMessage({ id: 'saveFail', defaultMessage: 'Save failed' })));

  const handleSubmit = async ({ social }: { social: SocialLinkType[] }) => {
    const input = social.map(({ id, type, url }) => ({
      id,
      type: data?.socialMedia?.find((item) => type.toLowerCase() === item.type.toLowerCase())?.id,
      url
    }));

    try {
      const saveResult = await saveSocialMedia({ variables: { input } });

      if (saveResult && saveResult.data?.saveSocialMedia?.success) {
        setMessages(
          getConfirmToastMessage(intl.formatMessage({ id: 'saveSuccess', defaultMessage: 'Save successful' }))
        );
      } else {
        throwSaveFailToast();
      }
    } catch (e) {
      throwSaveFailToast();
    }
  };

  if (getSocialMediaLoading) {
    return <LoadingIndicator />;
  }

  const defaultSocialLink = {
    id: '',
    type: SocialMediaType.Facebook,
    url: ''
  };

  const validationSchema = yup.object().shape({
    social: yup.array().of(
      yup.object().shape({
        id: yup.string().optional(),
        type: yup.string().required(),
        url: yup
          .string()
          .required()
          .url()
          .test({
            message: { id: 'invalidURL', defaultMessage: 'Invalid URL' },
            test(value) {
              // eslint-disable-next-line react/no-this-in-sfc
              const formType: string = (this.parent.type as string)?.toLowerCase();
              const socialMediaItem = data?.socialMedia?.find((item) => formType === item.type.toLowerCase());
              const urlProfilePattern: string | undefined = socialMediaItem?.urlProfilePattern;
              const result = urlProfilePattern ? value?.match(new RegExp(urlProfilePattern?.slice(1, -1))) : null;
              return Boolean(result);
            }
          })
      })
    )
  });

  return (
    <Container>
      <Title>
        <FormattedMessage id="socialMedia" defaultMessage="Social media" />
      </Title>
      <Formik
        initialValues={{ social: user.socialLinks || [] }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ handleChange, values, isValid, errors, touched, handleBlur }) => (
          <Form>
            <FieldArray name="social">
              {({ remove, push }) => (
                <>
                  {values.social?.map((social, index) => (
                    <Styled.FieldRow key={social.id || `__${index}__`}>
                      <div>
                        <Styled.Field
                          required
                          input={
                            <Select
                              options={data?.socialMedia?.map(({ type }) => ({ label: type, value: type }))}
                              value={social.type}
                              onChange={({ value }) =>
                                handleChange({ target: { name: `social.${index}.type`, value } })
                              }
                              onBlur={handleBlur}
                            />
                          }
                        />
                        <Styled.Field
                          required
                          isFullWidth
                          status={fieldError(`social.${index}.url`, { errors, touched })}
                          input={
                            <Input
                              placeholder={intl.formatMessage({ id: 'profileURL', defaultMessage: 'Profile URL' })}
                              name={`social.${index}.url`}
                              onChange={handleChange}
                              value={social.url}
                              onBlur={handleBlur}
                            />
                          }
                        />
                      </div>
                      <DeleteIconButton
                        icon={
                          <DeleteIcon
                            aria-label={intl.formatMessage({ id: 'deleteIcon', defaultMessage: 'Delete icon' })}
                          />
                        }
                        tooltipContents={intl.formatMessage({ id: 'delete', defaultMessage: 'Delete' })}
                        onClick={() => remove(index)}
                      />
                    </Styled.FieldRow>
                  ))}
                  <Button type="button" priority="secondary" onClick={() => push(defaultSocialLink)}>
                    <FormattedMessage id="addAnotherPlatform" defaultMessage="Add another platform" />
                  </Button>
                </>
              )}
            </FieldArray>
            <ButtonRow>
              <Button type="submit" disabled={!isValid} isLoading={saveSocialMediaLoading}>
                <FormattedMessage id="save" defaultMessage="Save" />
              </Button>
            </ButtonRow>
          </Form>
        )}
      </Formik>
      <Toast messages={messages} />
    </Container>
  );
};
