import styled from 'styled-components';
import { units } from '@m/alchemy-ui';
import { useIntl } from 'react-intl';
import { SelectorType } from '../../../common/enums';
import { CustomizeForm } from '.';

const PhaseQuestionsWrapper = styled.div`
  padding: ${units(2)};
`;

const HelpText = styled.p`
  margin-bottom: ${units(2)};
`;

export const PhaseQuestions = () => {
  const intl = useIntl();

  return (
    <PhaseQuestionsWrapper tabIndex={0} role="tabpanel">
      <HelpText>
        {intl.formatMessage({
          id: 'phaseQuestionsHelpText',
          defaultMessage:
            "Add questions to the idea submission form. Only questions that are ticked under 'Use' will be used in this phase. If you want to add questions that are always visible, use the Customise form option at the top of the page."
        })}
      </HelpText>
      <HelpText>
        {intl.formatMessage({
          id: 'phaseQuestionsChangesHelpText',
          defaultMessage: 'Changes here will affect all phases these questions appear in.'
        })}
      </HelpText>
      <CustomizeForm type={SelectorType.PHASE} />
    </PhaseQuestionsWrapper>
  );
};
