import { type IChallengeCarouselItem, type IPageItemSettings } from '../../../../common/interfaces';
import { Carousel } from '../Carousel/Carousel';
import { ChallengeCarouselSlide } from './ChallengeCarouselSlide';

interface IChallengeCarouselProps {
  slidesData: IChallengeCarouselItem[];
  settings: IPageItemSettings;
}

export const ChallengeCarousel = ({ slidesData, settings }: IChallengeCarouselProps) => {
  const { hideDescription } = settings;
  const slides = slidesData.map((slide) => (
    <ChallengeCarouselSlide key={slide.id} slideData={slide} hideDescription={Boolean(hideDescription)} />
  ));

  return <Carousel data-testid="challenge-carousel" slides={slides} />;
};
