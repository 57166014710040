import { useQuery } from '@apollo/client';
import { GET_PAGE_BY_ID, type PageQuery } from '../../../graphql/queries/';
import { type Page } from '../../../common/core-api';
import { type IPageItem, type Translation } from '../../../common/interfaces/';
import { useRuntimeUser } from '../../../context';
import { type IPageContentData } from '../../../common/interfaces';
import { CommunityPageType } from '../../../common/enums';
import { PageItem } from '..';
import { CommunityMainPage, CustomPageRow } from './CommunityPage.styled';

export interface CustomPageRowProps {
  hasWideItem: boolean;
  hasTabbedItem: boolean;
  hasOnlyTabbedItems: boolean;
  itemsCount: number;
}

export const WIDE_SIZE = 'wide';

export const isItemTabbed = (item: IPageItem) =>
  item.settings.type === CommunityPageType.LEADERBOARD ||
  item.settings.type === CommunityPageType.IDEAS ||
  item.settings.type === CommunityPageType.ACTIVITY;

export const CommunityPage = ({ pageId }: { pageId: string }) => {
  const { language = 'en_GB' } = useRuntimeUser();
  const { data } = useQuery<PageQuery>(GET_PAGE_BY_ID, {
    variables: { id: Number(pageId), language }
  });
  const pageData = data?.page as Page;
  const translations = pageData?.translations as Translation[];
  const { panelData } = JSON.parse(pageData?.settings || '{}');

  const isItemWide = (item: IPageItem) => item?.settings?.size === WIDE_SIZE;
  const tabbedItemsCount = (item: IPageItem[]) => item.filter(isItemTabbed).length;

  const getRowProps = (row: IPageItem[]): CustomPageRowProps => {
    const hasWideItem = row.some(isItemWide);
    const numberOfTabItems = tabbedItemsCount(row);
    const hasOnlyTabbedItems = numberOfTabItems > 0 && row.length === numberOfTabItems;

    return {
      hasWideItem,
      hasTabbedItem: numberOfTabItems > 0,
      hasOnlyTabbedItems,
      itemsCount: row.length
    };
  };

  return (
    <CommunityMainPage data-testid="communityPageComponent">
      {(panelData as IPageItem[][])?.map((row: IPageItem[], index: number) => {
        const rowProps = getRowProps(row);

        return (
          // eslint-disable-next-line react/no-array-index-key
          <CustomPageRow key={index} {...rowProps}>
            {row?.map((item: IPageItem) => (
              <PageItem
                key={item.id}
                item={item}
                contentData={pageData as IPageContentData}
                translations={translations?.filter((l) => l.field.includes(item.id))}
                rowProps={rowProps}
              />
            ))}
          </CustomPageRow>
        );
      })}
    </CommunityMainPage>
  );
};
