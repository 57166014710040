import { Checkbox, ColorField, DeleteIcon, Field, Flex, Input } from '@m/alchemy-ui';
import { useIntl } from 'react-intl';
import { DeleteIconButton } from '../../../common/components';

export const CustomLabel = ({
  index,
  name,
  enabled,
  currentColor,
  handleDelete,
  handleChange
}: {
  index: number;
  name: string;
  enabled: boolean;
  currentColor: string;
  handleDelete: () => void;
  handleChange: (field: string, value: boolean | string) => void;
}) => {
  const intl = useIntl();

  return (
    <Flex direction="row" gap={10} inline wrap="wrap">
      <Checkbox
        label={intl.formatMessage({ id: 'customLabelCheck', defaultMessage: 'Visible' })}
        shouldVisuallyHideLabel
        checked={enabled}
        onChange={(ev) => handleChange(`items.${index}.enabled`, ev?.currentTarget?.checked)}
      />
      <Field
        label={intl.formatMessage({ id: 'customLabelNamme', defaultMessage: 'Label name' })}
        shouldVisuallyHideLabel
        isFullWidth={false}
        required
        input={
          <Input defaultValue={name} onInput={(ev) => handleChange(`items.${index}.name`, ev?.currentTarget?.value)} />
        }
      />
      <Field
        label={intl.formatMessage({ id: 'customLabelColor', defaultMessage: 'Color' })}
        shouldVisuallyHideLabel
        isFullWidth={false}
        input={
          <ColorField
            defaultColor={`#${currentColor}`}
            value={`#${currentColor}`}
            hasVariantLabel={false}
            onColorChange={({ color }) => handleChange(`items.${index}.currentColor`, color.slice(1))}
          />
        }
      />
      <Field
        isFullWidth={false}
        input={
          <DeleteIconButton
            icon={<DeleteIcon aria-hidden="true" />}
            tooltipContents={intl.formatMessage({ id: 'Delete', defaultMessage: 'Delete label' })}
            onClick={() => handleDelete()}
          />
        }
      />
    </Flex>
  );
};
