import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';

export const apiRequestParts: DocumentNode = gql`
  fragment apiRequest on ApiRequest {
    id
    applied
    description
    status
    client {
      id
      status
    }
  }
`;

export const GET_API_REQUESTS: DocumentNode = gql`
  query apiRequests {
    apiRequests {
      ...apiRequest
    }
  }
  ${apiRequestParts}
`;
