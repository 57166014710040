import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';
import { challengeFragment } from '../queries/challenge';

export const UPDATE_CHALLENGE: DocumentNode = gql`
  mutation update($input: ChallengeInput!) {
    updateChallenge(input: $input) {
      ...ChallengeWithData
    }
  }
  ${challengeFragment}
`;

export const CREATE_CHALLENGE: DocumentNode = gql`
  mutation create($input: CreateChallengeInput!) {
    createChallenge(input: $input) {
      ...ChallengeWithData
    }
  }
  ${challengeFragment}
`;

export const CLONE_CHALLENGE: DocumentNode = gql`
  mutation clone($id: ID!) {
    cloneChallenge(id: $id) {
      ...ChallengeWithData
    }
  }
  ${challengeFragment}
`;
