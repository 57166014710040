import { type MouseEvent } from 'react';

declare global {
  interface Window {
    popUp: (path: string, options: { popUpTitle: string; height: number }) => void;
    openNewIdeaForm: (
      categoryId: string,
      isExternal: boolean,
      target: HTMLElement,
      challengeSelectionNotRequired: boolean
    ) => void;
  }
}

interface ModalProps {
  url?: string;
  height?: number;
  title?: string;
}

export const useMainModal = () => {
  const open = (e: MouseEvent<HTMLAnchorElement>, { height, url, title: popUpTitle }: ModalProps) => {
    e.preventDefault();
    const {
      currentTarget: { href, title }
    } = e;
    // eslint-disable-next-line no-restricted-globals
    parent.popUp(url || href, { popUpTitle: popUpTitle || title, height: height || 390 });
    return false;
  };

  const openNewIdea = (
    e: MouseEvent<HTMLAnchorElement>,
    categoryId: string,
    isExternal: boolean,
    challengeSelectionNotRequired: boolean
  ) => {
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    parent.openNewIdeaForm(categoryId, isExternal, e.currentTarget, challengeSelectionNotRequired);
  };

  return [open, openNewIdea] as const;
};
