import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Dialog, LoadingIndicator, Toast } from '@m/alchemy-ui';
import { useIntl } from 'react-intl';
import axios from 'axios';
import styled from 'styled-components';
import { type ScoreResetProps } from '../../features';
import { GET_GLOBAL_SCORE_RESETS, GET_USER_SCORE_RESETS } from '../../graphql/queries/score-resets';
import {
  type GlobalScoreResetsQuery,
  type UserScoreResetsQuery
} from '../../graphql/queries/__generated__/score-resets.generated';
import { UserContext } from '../../context';
import { type IScoreReset } from '../../common/interfaces/ScoreReset';
import {
  type GlobalScoreResetMutation,
  type GlobalScoreUndoMutation,
  type UserScoreResetMutation,
  type UserScoreUndoMutation
} from '../../graphql/mutations/__generated__/score-reset.generated';
import {
  CREATE_GLOBAL_SCORE_RESET,
  CREATE_USER_SCORE_RESET,
  UNDO_GLOBAL_SCORE_RESET,
  UNDO_USER_SCORE_RESET
} from '../../graphql/mutations/score-reset';
import { type ToastMessageType } from '../../common/enums';
import { getConfirmToastMessage, getErrorToastMessage } from '../../helpers';
import { UserScoreContext } from '../user-score/context/UserScoreContext';
import { ScoreResetSubmission } from './components/ScoreResetSubmission';
import { ScoreResetList } from './components/ScoreResetList';
import { LoadingWrapper } from './ScoreReset.styled';
import { ScoreResetView } from './enums/ScoreResetView';

const StyledDialog = styled(Dialog)`
  z-index: 1050;
`;

export const ScoreReset = (props: ScoreResetProps) => {
  const user = useContext(UserContext);
  const { refetchScores } = useContext(UserScoreContext);

  const intl = useIntl();
  const dialogConfirm = intl.formatMessage({
    id: 'confirm',
    defaultMessage: 'Confirm'
  });
  const dialogCancel = intl.formatMessage({
    id: 'cancel',
    defaultMessage: 'Cancel'
  });
  const dialogUndoHeader = intl.formatMessage({
    id: 'undoScore',
    defaultMessage: 'Undo Score Reset'
  });
  const dialogUndoBody = intl.formatMessage({
    id: 'areYouSureUndoScore',
    defaultMessage: 'Are you sure you want to undo the Score Reset?'
  });
  const dialogResetHeader = intl.formatMessage({
    id: 'scoreReset',
    defaultMessage: 'Score Reset'
  });
  const dialogResetBody = intl.formatMessage({
    id: 'areYouSureScoreReset',
    defaultMessage: 'Are you sure you want to reset the Score?'
  });

  const resetSuccessful = intl.formatMessage({ id: 'resetSuccess', defaultMessage: 'Reset successful' });
  const resetFailed = intl.formatMessage({ id: 'resetFail', defaultMessage: 'Reset failed' });
  const undoSuccessful = intl.formatMessage({ id: 'undoSuccess', defaultMessage: 'Undo successful' });
  const undoFailed = intl.formatMessage({ id: 'undoFail', defaultMessage: 'Undo failed' });

  const { scoreResetView, scoreResetUserId } = props;
  const isGlobalView = scoreResetView === ScoreResetView.GLOBAL;
  const {
    loading: globalLoading,
    data: globalData,
    refetch: globalRefetch,
    error: globalError
  } = useQuery<GlobalScoreResetsQuery>(GET_GLOBAL_SCORE_RESETS, { skip: !isGlobalView });
  const {
    loading: userLoading,
    data: userData,
    refetch: userRefetch,
    error: userError
  } = useQuery<UserScoreResetsQuery>(GET_USER_SCORE_RESETS, {
    skip: isGlobalView,
    variables: { userId: scoreResetUserId }
  });
  const [saveGlobalScoreReset, { loading: saveGlobalScoreResetLoading }] =
    useMutation<GlobalScoreResetMutation>(CREATE_GLOBAL_SCORE_RESET);
  const [saveUserScoreReset, { loading: saveUserScoreResetLoading }] =
    useMutation<UserScoreResetMutation>(CREATE_USER_SCORE_RESET);
  const [undoGlobalScoreReset, { loading: undoGlobalScoreResetLoading }] =
    useMutation<GlobalScoreUndoMutation>(UNDO_GLOBAL_SCORE_RESET);
  const [undoUserScoreReset, { loading: undoUserScoreResetLoading }] =
    useMutation<UserScoreUndoMutation>(UNDO_USER_SCORE_RESET);

  const [messages, setMessages] = useState<ToastMessageType[]>([]);
  const [scoreResetList, setScoreResetList] = useState<GlobalScoreResetsQuery['globalScoreResets'] | undefined>();
  const [isUndoDialogOpen, setIsUndoDialogOpen] = useState<boolean>(false);
  const [isResetDialogOpen, setIsResetDialogOpen] = useState<boolean>(false);
  const [undoEventId, setUndoEventId] = useState<string>('');
  const [resetValues, setResetValues] = useState<IScoreReset>();

  useEffect(() => {
    if (isGlobalView) {
      !globalLoading &&
        !globalError &&
        globalData?.globalScoreResets &&
        setScoreResetList(globalData.globalScoreResets);
    } else {
      !userLoading && !userError && userData?.userScoreResets && setScoreResetList(userData.userScoreResets);
    }
  }, [globalData, globalLoading, globalError, userData, userLoading, userError, isGlobalView]);

  const closeUndoDialog = () => setIsUndoDialogOpen(false);
  const handleUndoClick = (eventId: string) => {
    setUndoEventId(eventId);
    setIsUndoDialogOpen(true);
  };

  const handleUndoConfirmationClick = async () => {
    closeUndoDialog();
    if (undoEventId) {
      try {
        if (isGlobalView) {
          const undoResults = await undoGlobalScoreReset({ variables: { eventId: undoEventId } });
          undoResults?.data?.globalScoreUndo && setMessages(getConfirmToastMessage(undoSuccessful));
          await globalRefetch();
        } else {
          const undoResults = await undoUserScoreReset({
            variables: { eventId: undoEventId, userId: scoreResetUserId }
          });
          undoResults?.data?.userScoreUndo && setMessages(getConfirmToastMessage(undoSuccessful));
          await axios({
            url: `/user/admin_scorerecalculate/${scoreResetUserId}?ak=${props.user?.actionKey}`,
            method: 'POST'
          });
          await userRefetch();
          refetchScores.current = true;
        }
      } catch (error) {
        setMessages(getErrorToastMessage(undoFailed));
      }
    }
  };

  const closeResetDialog = () => setIsResetDialogOpen(false);
  const handleResetClick = (values: IScoreReset) => {
    setResetValues(values);
    setIsResetDialogOpen(true);
  };

  const handleResetConfirmationClick = async () => {
    closeResetDialog();
    if (resetValues) {
      const { date, excludeBadges } = resetValues;
      try {
        if (isGlobalView) {
          const saveResult = await saveGlobalScoreReset({ variables: { date, excludeBadges } });
          saveResult?.data?.globalScoreReset && setMessages(getConfirmToastMessage(resetSuccessful));
          await globalRefetch();
        } else {
          const saveResult = await saveUserScoreReset({ variables: { date, excludeBadges, userId: scoreResetUserId } });
          saveResult?.data?.userScoreReset && setMessages(getConfirmToastMessage(resetSuccessful));
          await axios({
            url: `/user/admin_scorerecalculate/${scoreResetUserId}?ak=${props.user?.actionKey}`,
            method: 'POST'
          });
          await userRefetch();
          refetchScores.current = true;
        }
      } catch (error) {
        setMessages(getErrorToastMessage(resetFailed));
      }
    }
  };

  const displayLoading = isGlobalView ? globalLoading : userLoading || scoreResetList === undefined || user.loading;

  useEffect(() => {
    if (!displayLoading && scoreResetList !== undefined) {
      const event = new CustomEvent('popUpCompleteFromAlchemy');
      window.dispatchEvent(event);
    }
  }, [displayLoading, scoreResetList]);

  return (
    <div data-testid="score-reset-container">
      {displayLoading ? (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      ) : (
        <>
          <StyledDialog
            isOpen={isUndoDialogOpen}
            onClose={closeUndoDialog}
            size="small"
            headerContent={dialogUndoHeader}
            bodyContent={dialogUndoBody}
            footerButtons={[
              { label: dialogCancel, onClick: closeUndoDialog, priority: 'tertiary' },
              {
                label: dialogConfirm,
                onClick: () => {
                  handleUndoConfirmationClick();
                }
              }
            ]}
          />
          <StyledDialog
            isOpen={isResetDialogOpen}
            onClose={closeResetDialog}
            size="small"
            headerContent={dialogResetHeader}
            bodyContent={dialogResetBody}
            footerButtons={[
              { label: dialogCancel, onClick: closeResetDialog, priority: 'tertiary' },
              {
                label: dialogConfirm,
                onClick: () => {
                  handleResetConfirmationClick();
                }
              }
            ]}
          />

          <ScoreResetSubmission
            loading={saveGlobalScoreResetLoading || saveUserScoreResetLoading}
            view={scoreResetView}
            userTimezone={user.timezone || 'UTC'}
            lastResetDate={scoreResetList?.[0]?.date}
            onSubmit={(values) => handleResetClick(values)}
          />

          <ScoreResetList
            view={scoreResetView}
            list={scoreResetList}
            undoLoading={undoGlobalScoreResetLoading || undoUserScoreResetLoading}
            handleUndoClick={handleUndoClick}
          />
        </>
      )}
      <Toast messages={messages} />
    </div>
  );
};
