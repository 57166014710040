import { FormattedMessage, useIntl } from 'react-intl';
import { type TabProps } from '@m/alchemy-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { type IPageIdeaItem, type IPageIdeas, type IPageItemSettings } from '../../../../../common/interfaces';
import { TabbedPanel } from '../TabbedPanel/TabbedPanel';
import { Alert, List } from '../common/styles';
import { IdeasTabKeys } from '../../../../../common/enums/IdeasTabKeys';
import { GET_IDEAS_BY_CHALLENGE_ID } from '../../../../../graphql/queries/community-page';
import { type PageIdeasQuery } from '../../../../../graphql/queries';
import { IdeaItem } from './IdeaItem';
import { IdeaSelect } from './IdeaSelect';

interface IIdeasProps {
  data: IPageIdeas;
  settings: IPageItemSettings;
  panelSize: number;
  hasWideSibling: boolean;
}

export const Ideas = ({ data, settings, panelSize, hasWideSibling }: IIdeasProps) => {
  const intl = useIntl();
  const challengeId = Number(data?.voted[0]?.id);
  const { ideasDefault: defaultTab, ideasDisplay: tabsToDisplay } = settings;

  const { data: ideasForTopVoted, refetch } = useQuery<PageIdeasQuery>(GET_IDEAS_BY_CHALLENGE_ID, {
    variables: { id: challengeId, skip: !challengeId }
  });
  const ideasForTopVotedData = useMemo(
    () => (ideasForTopVoted?.pageIdeas as IPageIdeaItem[]) || [],
    [ideasForTopVoted]
  );

  const [currentTab, setCurrentTab] = useState<string>('');
  const [ideasData, setIdeasData] = useState<{
    trend: JSX.Element[];
    latest: JSX.Element[];
    voted: JSX.Element[];
    random: JSX.Element[];
    commented: JSX.Element[];
  }>();

  const onIdeaSelectValueChange = useCallback(
    (challengeId: string) => {
      refetch({ id: Number(challengeId) });
    },
    [refetch]
  );

  const noActivitiesMessage = useMemo(
    () => <FormattedMessage id="page.thereIsNoActivityToShowYet." defaultMessage="There is no activity to show yet." />,
    []
  );

  const noActivitiesAlert = useMemo(() => <Alert>{noActivitiesMessage}</Alert>, [noActivitiesMessage]);

  useEffect(() => {
    if (data) {
      const createItems = (items: IPageIdeaItem[]) =>
        items?.map((item) => (
          <IdeaItem key={item.sourceUrl} data={item} panelSize={panelSize} hasWideSibling={hasWideSibling} />
        ));

      const trend = createItems(data.trend);
      const latest = createItems(data.latest);
      const random = createItems(data.random);
      const commented = createItems(data.commented);
      let votedItems: JSX.Element[] = [];

      if (ideasForTopVotedData?.length > 0) {
        votedItems = createItems(ideasForTopVotedData);
      }

      const items = votedItems.length > 0 ? votedItems : [noActivitiesAlert];

      const voted = [
        <IdeaSelect key="ideaSelect" challenges={data.voted} onValueChange={onIdeaSelectValueChange} />,
        ...items
      ];
      const tabToDisplay = currentTab || (tabsToDisplay.includes(defaultTab) ? defaultTab : tabsToDisplay[0]);

      setCurrentTab(tabToDisplay);
      setIdeasData({ trend, latest, voted, random, commented });
    }
  }, [
    currentTab,
    data,
    defaultTab,
    hasWideSibling,
    ideasForTopVotedData,
    noActivitiesAlert,
    onIdeaSelectValueChange,
    panelSize,
    tabsToDisplay
  ]);

  const ideaTabChanged = (tabName: string) => {
    if (ideasData && tabName in ideasData) {
      setCurrentTab(tabName);
    }
  };

  const tabs = [
    {
      label: intl.formatMessage({ id: 'page.trending', defaultMessage: 'Trending' }),
      value: IdeasTabKeys.TRENDING,
      layout: 'left'
    } as TabProps,
    {
      label: intl.formatMessage({ id: 'page.latest', defaultMessage: 'Latest' }),
      value: IdeasTabKeys.LATEST,
      layout: 'left'
    } as TabProps,
    {
      label: intl.formatMessage({ id: 'page.topVoted', defaultMessage: 'Top Voted' }),
      value: IdeasTabKeys.TOP_VOTED,
      layout: 'left'
    } as TabProps,
    {
      label: intl.formatMessage({ id: 'page.random', defaultMessage: 'Random' }),
      value: IdeasTabKeys.RANDOM,
      layout: 'left'
    } as TabProps,
    {
      label: intl.formatMessage({ id: 'page.mostDiscussed', defaultMessage: 'Most Discussed' }),
      value: IdeasTabKeys.MOST_DISCUSSED,
      layout: 'left'
    } as TabProps
  ];

  const content = () => {
    const currentTabData = ideasData ? ideasData[currentTab as keyof IPageIdeas] : [];
    return currentTabData?.length === 0 ? noActivitiesAlert : <List>{currentTabData}</List>;
  };

  return (
    <div data-testid="ideas-tab-panel">
      <TabbedPanel
        tabs={tabs.filter((tab) => tabsToDisplay?.includes(tab.value))}
        content={content()}
        defaultActiveTab={tabsToDisplay.length > 1 ? defaultTab : tabsToDisplay[0]}
        onTabChange={ideaTabChanged}
      />
    </div>
  );
};
