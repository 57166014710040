/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { type CustomMenuItemActivateEventType, type MenuListItemProps } from '@m/alchemy-ui';
import { useMutation } from '@apollo/client';
import { useRuntimeCommunity, useRuntimeUser } from '../../../../context';
import { SubscribeMode } from '../../../../common/enums/SubscribeMode';
import { CHALLENGE_MODERATOR_FOLLOW_SAVE } from '../../../../graphql/mutations/notification';
import { type SaveChallengeModeratorFollowMutation } from '../../../../graphql/mutations';
import { SubscribeSingleButton } from '../SubscribeSingleButton/SubscribeSingleButton';
import { SubscribeMultiSelect } from '../SubscribeMultiSelect/SubscribeMultiSelect';
import { ENTIRE_CHALLENGE, THEMES_ONLY, TOAST_FAILED, TOAST_SUCCESS } from '../../common/const';
import { type SubscribeData } from '../../../../common/interfaces/SubscribeButton';

declare global {
  function addNotification(message: string, icon: string): void;
}

interface SubscribeButtonProps {
  readonly data: SubscribeData;
  readonly isModeratorSubscribe: boolean;
  readonly challengeId: string;
}

export const SubscribeButton = (props: SubscribeButtonProps) => {
  const { challengeId, data, isModeratorSubscribe } = props;
  const { themes, isSubscribedToEntireChallenge, isSubscribedToTopLevel, isSubscribedToThemesOnly } = data;
  const { colors } = useRuntimeCommunity();
  const { actionKey } = useRuntimeUser();
  const intl = useIntl();

  const [saveChallengeModeratorFollow] = useMutation<SaveChallengeModeratorFollowMutation>(
    CHALLENGE_MODERATOR_FOLLOW_SAVE
  );
  const isThemeless = themes.length === 0;
  const subscribeTranslationString = isModeratorSubscribe
    ? intl.formatMessage({ id: 'moderatorSubscribe', defaultMessage: 'Moderator Subscribe' })
    : intl.formatMessage({ id: 'subscribe', defaultMessage: 'Subscribe' });
  const subscribedTranslationString = isModeratorSubscribe
    ? intl.formatMessage({ id: 'moderatorSubscribed', defaultMessage: 'Moderator Subscribed' })
    : intl.formatMessage({ id: 'subscribeSuccess', defaultMessage: 'Subscribed' });

  const singleButtonToolTipTranslationString = isModeratorSubscribe
    ? intl.formatMessage({
        id: 'subscribe.singleModeratorSubscriptionButtonTooltip',
        defaultMessage: 'You will receive a moderator notification each time an idea is posted to this challenge'
      })
    : intl.formatMessage({
        id: 'subscribe.singleSubscriptionButtonTooltip',
        defaultMessage: 'You will receive a notification each time an idea is posted to this challenge'
      });

  const multiSelectButtonToolTipTranslationString = isModeratorSubscribe
    ? intl.formatMessage({
        id: 'subscribe.multiSelectModeratorButtonTooltip',
        defaultMessage:
          'You will receive a moderator notification each time an idea is posted to subscribed challenge or themes'
      })
    : intl.formatMessage({
        id: 'subscribe.multiSelectButtonTooltip',
        defaultMessage: 'You will receive a notification each time an idea is posted to subscribed challenge or themes'
      });

  const [selected, setSelected] = useState<string[]>([]);
  const [selectedBackup, setSelectedBackup] = useState<string[]>([]);
  const [selectedAsString, setSelectedAsString] = useState<string>('');
  const [subscribedToEntireChallenge, setSubscribedToEntireChallenge] =
    useState<boolean>(isSubscribedToEntireChallenge);

  const getFilteredAllMenuItems = (excludeIds: string[]) =>
    allMenuItems.map((item) => item.id || '').filter((item) => !excludeIds.includes(item) && Boolean(item));

  const onChecked = (id: string) => {
    setSelected((prevSubscribed) => {
      if (prevSubscribed.includes(id)) {
        return id === ENTIRE_CHALLENGE || id === THEMES_ONLY ? [] : prevSubscribed.filter((item) => item !== id);
      }
      if (id === ENTIRE_CHALLENGE) {
        return getFilteredAllMenuItems([THEMES_ONLY]);
      }
      if (id === THEMES_ONLY) {
        return getFilteredAllMenuItems([ENTIRE_CHALLENGE, challengeId]);
      }
      return [...prevSubscribed, id];
    });
  };

  const buildSelectedString = (ids: string[]) => {
    const selectedSize = ids.length;
    const selectedStatus = ids.includes(ENTIRE_CHALLENGE)
      ? intl.formatMessage({ id: 'all', defaultMessage: 'All' })
      : ids.includes(THEMES_ONLY)
        ? intl.formatMessage({ id: 'themes', defaultMessage: 'Themes' })
        : selectedSize;
    return selectedSize > 0 ? `${subscribedTranslationString} (${selectedStatus})` : subscribeTranslationString;
  };

  const defaultOptions = [
    {
      label: (
        <strong>{intl.formatMessage({ id: 'subscribe.EntireChallenge', defaultMessage: 'Entire challenge' })}</strong>
      ),
      id: ENTIRE_CHALLENGE,
      onClick: (_: CustomMenuItemActivateEventType, item: MenuListItemProps) => item?.id && onChecked(item.id),
      checked: selected.includes(ENTIRE_CHALLENGE)
    },
    {
      label: <strong>{intl.formatMessage({ id: 'subscribe.ThemesOnly', defaultMessage: 'Themes Only' })}</strong>,
      id: THEMES_ONLY,
      onClick: (_: CustomMenuItemActivateEventType, item: MenuListItemProps) => item?.id && onChecked(item.id),
      checked: selected.includes(THEMES_ONLY)
    },
    { label: 'separator', isSeparator: true },
    {
      label: intl.formatMessage({ id: 'subscribe.Unassigned', defaultMessage: 'Unassigned' }),
      id: challengeId,
      onClick: (_: CustomMenuItemActivateEventType, item: MenuListItemProps) => item?.id && onChecked(item.id),
      checked: selected.includes(challengeId)
    }
  ] as MenuListItemProps[];

  const allMenuItems = [
    ...defaultOptions,
    ...themes.map((theme) => ({
      ...theme,
      onClick: (_: CustomMenuItemActivateEventType, item: MenuListItemProps) => {
        if (item?.id) {
          onChecked(item?.id);
        }
      },
      checked: selected.includes(theme.id)
    }))
  ];

  useEffect(() => {
    let alreadySubscribed = themes.filter((theme) => theme.subscribed).map((theme) => theme.id);

    let additionalIds: string[] = [];
    if (subscribedToEntireChallenge) {
      additionalIds = [...additionalIds, ENTIRE_CHALLENGE];
    }
    if (isSubscribedToTopLevel) {
      additionalIds = [...additionalIds, challengeId];
    }
    if (isSubscribedToThemesOnly) {
      additionalIds = [...additionalIds, THEMES_ONLY];
    }

    alreadySubscribed = [...alreadySubscribed, ...additionalIds];
    setSelectedAsString(
      alreadySubscribed?.length > 0 ? buildSelectedString(alreadySubscribed) : subscribeTranslationString
    );
    setSelected(alreadySubscribed);
    setSelectedBackup(alreadySubscribed);
  }, []);

  const onSubscribeApply = async (ids: string[]) => {
    const handleModeratorSubscribe = async (ids: string[]) => {
      if (isThemeless && !subscribedToEntireChallenge) {
        ids.push(ENTIRE_CHALLENGE);
        setSubscribedToEntireChallenge(!subscribedToEntireChallenge);
      } else if (isThemeless && subscribedToEntireChallenge) {
        setSubscribedToEntireChallenge(!subscribedToEntireChallenge);
      }

      if (!isThemeless && ids.includes(ENTIRE_CHALLENGE) && ids.length !== allMenuItems.length - 2) {
        ids = ids.filter((id) => id !== ENTIRE_CHALLENGE);
        setSelected(ids);
      }

      if (!isThemeless && ids.includes(THEMES_ONLY) && ids.length !== allMenuItems.length - 3) {
        ids = ids.filter((id) => id !== THEMES_ONLY);
        setSelected(ids);
      }

      const response = await saveChallengeModeratorFollow({
        variables: { challengeId, themeIds: ids }
      });

      if (response?.data) {
        const { message = '', success = false } = response.data.saveChallengeModeratorFollow;
        if (success) {
          setSelectedAsString(buildSelectedString(ids));
          setSelectedBackup(ids);
        }
        notify(message || '', success);
      }
    };

    const handleRegularSubscribe = (ids: string[]) => {
      const hasIds = ids.length > 0;
      let hasEntireChallenge = ids.includes(ENTIRE_CHALLENGE);
      let hasThemesOnly = ids.includes(THEMES_ONLY);

      if (hasEntireChallenge && ids.length !== allMenuItems.length - 2) {
        ids = ids.filter((id) => id !== ENTIRE_CHALLENGE);
        hasEntireChallenge = false;
        setSelected(ids);
      }

      if (hasThemesOnly && ids.length !== allMenuItems.length - 3) {
        ids = ids.filter((id) => id !== THEMES_ONLY);
        hasThemesOnly = false;
        setSelected(ids);
      }

      if (hasThemesOnly) {
        setSelected(getFilteredAllMenuItems([ENTIRE_CHALLENGE, challengeId]));
      }

      if (hasEntireChallenge) {
        setSelected(getFilteredAllMenuItems([THEMES_ONLY]));
      }

      save(hasIds ? SubscribeMode.ADD : SubscribeMode.REMOVE, ids, hasEntireChallenge, hasThemesOnly);
    };

    if (isModeratorSubscribe) {
      await handleModeratorSubscribe(ids);
    } else if (isThemeless) {
      save(subscribedToEntireChallenge ? SubscribeMode.REMOVE : SubscribeMode.ADD, [], true);
    } else {
      handleRegularSubscribe(ids);
    }
  };

  const onCancel = () => {
    setSelected(selectedBackup);
  };

  const save = (mode: string, themeIds: string[] = [], all: boolean = false, themesOnly: boolean = false) => {
    const themeDataParam = all ? '' : `&themeData=${themesOnly ? 'themesOnly' : themeIds.join(',')}`;
    const url = `/categoryfollow/${mode}/${challengeId}?ak=${actionKey}${themeDataParam}`;

    axios.get(url).then((response) => {
      if (isThemeless && response.data.success) {
        setSubscribedToEntireChallenge(!subscribedToEntireChallenge);
      } else {
        setSelectedAsString(buildSelectedString(themeIds));
        setSelectedBackup(themeIds);
      }

      if (response && response.data) {
        notify(response.data.message as string, response.data.success as boolean);
      }
    });
  };

  const notify = (message: string, success: boolean) =>
    addNotification('<p>' + message + '</p>', success ? TOAST_SUCCESS : TOAST_FAILED);

  return isThemeless ? (
    <SubscribeSingleButton
      onClick={onSubscribeApply}
      buttonColor={colors.button}
      buttonCaption={subscribedToEntireChallenge ? subscribedTranslationString : subscribeTranslationString}
      buttonToolTip={singleButtonToolTipTranslationString}
    />
  ) : (
    <SubscribeMultiSelect
      selectedAsString={selectedAsString}
      items={allMenuItems}
      colors={colors}
      onSubscribeApply={onSubscribeApply}
      onCancel={onCancel}
      selected={selected}
      buttonToolTip={multiSelectButtonToolTipTranslationString}
    />
  );
};
