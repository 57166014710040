import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';

export const GET_IDEAS_AND_COUNT: DocumentNode = gql`
  query getIdeasAndCount(
    $challengeSelection: String
    $filters: UserSearchFilters
    $startDate: String
    $endDate: String
    $order: [[String!]!]
    $offset: Int
    $phaseSelection: Int
    $milestoneSelection: Int
  ) {
    ideasAndCount(
      challengeSelection: $challengeSelection
      filters: $filters
      startDate: $startDate
      endDate: $endDate
      order: $order
      offset: $offset
      limit: 10
      phaseSelection: $phaseSelection
      milestoneSelection: $milestoneSelection
    ) {
      count
      rows {
        id
        title
        text
        createdAt
        challenge {
          title
        }
        theme {
          challenge {
            title
          }
        }
        stats {
          comments
          followers
          views
          votes
        }
        user {
          ... on User {
            id
            displayName
          }
          ... on AnonymousUser {
            id
            displayName
          }
        }
        voteCount
        score
      }
    }
  }
`;

export const GET_COAUTHOR_IDEAS: DocumentNode = gql`
  query coauthorIdeas($filters: UserSearchFilters!, $customFiltersUserIds: [Int!]!) {
    coauthorIdeas(filters: $filters, customFiltersUserIds: $customFiltersUserIds)
  }
`;

export const GET_THEME_IDEAS_AND_COUNT: DocumentNode = gql`
  query getThemeIdeasAndCount(
    $challengeSelection: String!
    $themeSelection: String!
    $filters: UserSearchFilters
    $order: [[String!]!]
    $offset: Int
  ) {
    themeIdeasAndCount(
      challengeSelection: $challengeSelection
      themeSelection: $themeSelection
      filters: $filters
      order: $order
      offset: $offset
      limit: 10
    ) {
      count
      rows {
        id
        title
        text
        createdAt
        challenge {
          title
        }
        theme {
          challenge {
            title
          }
        }
        stats {
          comments
          followers
          views
          votes
        }
        user {
          ... on User {
            id
            displayName
          }
          ... on AnonymousUser {
            id
            displayName
          }
        }
        voteCount
        score
      }
    }
  }
`;

export const GET_TAG_IDEAS_AND_COUNT: DocumentNode = gql`
  query getTagIdeasAndCount(
    $challengeSelection: String!
    $tagSelection: String!
    $filters: UserSearchFilters
    $order: [[String!]!]
    $offset: Int
  ) {
    tagIdeasAndCount(
      challengeSelection: $challengeSelection
      tagSelection: $tagSelection
      filters: $filters
      order: $order
      offset: $offset
      limit: 10
    ) {
      count
      rows {
        id
        title
        text
        createdAt
        challenge {
          title
        }
        theme {
          challenge {
            title
          }
        }
        stats {
          comments
          followers
          views
          votes
        }
        user {
          ... on User {
            id
            displayName
          }
          ... on AnonymousUser {
            id
            displayName
          }
        }
        voteCount
        score
      }
    }
  }
`;

export const GET_STATUS_IDEAS_AND_COUNT: DocumentNode = gql`
  query getStatusIdeasAndCount(
    $challengeSelection: String!
    $statusSelection: String!
    $filters: UserSearchFilters
    $order: [[String!]!]
    $offset: Int
  ) {
    statusIdeasAndCount(
      challengeSelection: $challengeSelection
      statusSelection: $statusSelection
      filters: $filters
      order: $order
      offset: $offset
      limit: 10
    ) {
      count
      rows {
        id
        title
        text
        createdAt
        challenge {
          title
        }
        theme {
          challenge {
            title
          }
        }
        stats {
          comments
          followers
          views
          votes
        }
        user {
          ... on User {
            id
            displayName
          }
          ... on AnonymousUser {
            id
            displayName
          }
        }
        voteCount
        score
      }
    }
  }
`;
