import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';

export const GET_FOLLOWING: DocumentNode = gql`
  query checkFollowing($userId: String!, $targetUser: String!) {
    checkFollowing(userId: $userId, targetUser: $targetUser) {
      success
    }
  }
`;
