import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';

export const SAVE_SOCIAL_MEDIA: DocumentNode = gql`
  mutation saveSocialMedia($input: [SocialLinkInput!]!) {
    saveSocialMedia(input: $input) {
      success
      message
    }
  }
`;
