import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';

export const NOTIFICATION_FOLLOW: DocumentNode = gql`
  mutation notificationFollow($input: NotificationFollowInput!) {
    follow(input: $input) {
      success
      message
    }
  }
`;

export const NOTIFICATION_UNFOLLOW: DocumentNode = gql`
  mutation notificationUnfollow($input: NotificationFollowInput!) {
    unfollow(input: $input) {
      success
      message
    }
  }
`;

export const CHALLENGE_MODERATOR_FOLLOW_SAVE: DocumentNode = gql`
  mutation saveChallengeModeratorFollow($challengeId: String!, $themeIds: [String!]!) {
    saveChallengeModeratorFollow(challengeId: $challengeId, themeIds: $themeIds) {
      message
      success
      code
    }
  }
`;
