import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';

export const CREATE_GLOBAL_SCORE_RESET: DocumentNode = gql`
  mutation globalScoreReset($date: String!, $excludeBadges: Boolean!) {
    globalScoreReset(date: $date, excludeBadges: $excludeBadges) {
      id
      date
      excludeBadges
      type
    }
  }
`;

export const CREATE_USER_SCORE_RESET: DocumentNode = gql`
  mutation userScoreReset($date: String!, $excludeBadges: Boolean!, $userId: String!) {
    userScoreReset(userId: $userId, date: $date, excludeBadges: $excludeBadges) {
      id
      date
      excludeBadges
      type
    }
  }
`;

export const UNDO_GLOBAL_SCORE_RESET: DocumentNode = gql`
  mutation globalScoreUndo($eventId: String!) {
    globalScoreUndo(eventId: $eventId) {
      id
    }
  }
`;

export const UNDO_USER_SCORE_RESET: DocumentNode = gql`
  mutation userScoreUndo($eventId: String!, $userId: String!) {
    userScoreUndo(eventId: $eventId, userId: $userId) {
      id
    }
  }
`;

export const UPDATE_USER_SCORE: DocumentNode = gql`
  mutation userScorelogUpdate($scorelogId: String!, $isRemoved: Boolean!) {
    userScorelogUpdate(scorelogId: $scorelogId, isRemoved: $isRemoved) {
      id
      isRemoved
    }
  }
`;
