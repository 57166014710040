import type React from 'react';

interface RocketIconProps {
  'aria-label'?: string;
  'aria-hidden'?: 'true' | true;
}

export const RocketIcon: React.FC<RocketIconProps> = ({ 'aria-label': ariaLabel, 'aria-hidden': ariaHidden }) => (
  <svg viewBox="0 0 16 16" width="24" height="24" aria-label={ariaLabel} aria-hidden={ariaHidden}>
    <path
      fill="currentColor"
      d="M11 1L6 6H3l-3 4s3.178-.885 5.032-.47L0 16l6.592-5.127C7.512 12.977 6 16 6 16l4-3v-3l5-5 1-5-5 1z"
    />
  </svg>
);
