import styled from 'styled-components';
import { Grid, GridCol, GridRow, units } from '@m/alchemy-ui';
import { useIntl } from 'react-intl';
import { SelectorType } from '../../../common/enums';
import { AccessOptions, AccessSelector } from '.';

const PhaseAccessWrapper = styled.div`
  padding: ${units(2)};
`;

const Message = styled.div`
  margin: ${units(1)} 0;
`;

const Container = styled.div`
  margin-top: ${units(2)};
`;

export const PhaseAccess = () => {
  const intl = useIntl();

  return (
    <PhaseAccessWrapper tabIndex={0} role="tabpanel">
      <Message>
        {intl.formatMessage({
          id: 'phaseAccessHelpMessage',
          defaultMessage:
            'You can choose who is able to see this phase - just type the name of a user or group into the search box, and click their name.'
        })}
      </Message>
      <Message>
        {intl.formatMessage({
          id: 'phaseAccessEveryoneHelpMessage',
          defaultMessage: 'Search for everyone if you want this phase to be visible to all users.'
        })}
      </Message>
      <Container>
        <Grid>
          <GridRow>
            <GridCol span={{ small: 12, medium: 5 }}>
              <AccessSelector type={SelectorType.PHASE} />
            </GridCol>
            <GridCol span={{ small: 12, medium: 6 }}>
              <AccessOptions type={SelectorType.PHASE} />
            </GridCol>
          </GridRow>
        </Grid>
      </Container>
    </PhaseAccessWrapper>
  );
};
