import { type ReactNode, useState } from 'react';
import styled from 'styled-components';
import { borderRadius, Button, color, GridCol, GridRow, Panel, units } from '@m/alchemy-ui';
import { useDropzone } from 'react-dropzone';
import { useFormikContext } from 'formik';
import { FileUploadType } from '../enums';
import { type IChallenge } from '../interfaces';
import { type File as FileType } from '../core-api';
import { FileListItem } from '.';

const Wrapper = styled.div`
  display: flex;
  border: 2px dashed ${color('line')};
  border-radius: ${borderRadius('large')};
`;
const ChildrenContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: ${units(2)};
  & button {
    margin-top: ${units(2)};
  }
`;
const FileListContainer = styled(Panel)`
  border: 1px solid ${color('line')};
  border-radius: ${borderRadius('large')};
`;

interface UploadProps {
  readonly buttonLabel: string;
  readonly children: ReactNode;
  readonly fileTypes: string[];
  readonly type: FileUploadType;
  readonly labelId?: string;
  readonly describedBy?: string;
}

export const MultipleUpload = ({ buttonLabel, children, fileTypes = [], type, labelId, describedBy }: UploadProps) => {
  const { values: challenge } = useFormikContext<IChallenge>();

  const initialFiles = type === FileUploadType.CHALLENGE_ATTACHMENT ? challenge.files?.attachments || [] : [];
  const [files, setFiles] = useState<(File | FileType)[]>(initialFiles);

  const onDrop = (acceptedFiles: File[]) => {
    setFiles([...files, ...acceptedFiles]);
  };

  const acceptFileTypes = fileTypes.map((type) => '.' + type).join(', ');
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptFileTypes
  });

  const isFileType = (file: File | FileType): file is FileType => (file as FileType).id !== undefined;

  return (
    <GridRow>
      <GridCol span={{ small: 12, medium: 6 }}>
        <Wrapper>
          <ChildrenContainer>
            <div
              data-testid="dropzone"
              {...getRootProps({ className: 'dropzone' })}
              aria-labelledby={`${labelId} ${describedBy}`}
            >
              <input {...getInputProps()} />
              {children}
              <Button priority="secondary">{buttonLabel}</Button>
            </div>
          </ChildrenContainer>
        </Wrapper>
      </GridCol>
      <GridCol span={{ small: 12, medium: 6 }}>
        <FileListContainer variant="summary">
          {files.map((file) => {
            const key = isFileType(file) ? file.id : String(file.lastModified) + '_' + file.name.replace('.', '_');
            return <FileListItem key={key} file={file} type={type} files={files} setFiles={setFiles} />;
          })}
        </FileListContainer>
      </GridCol>
    </GridRow>
  );
};
