import { useEffect, useState } from 'react';
import { BlogItem, BlogList } from '../..';
import { type IBlog } from '../../../../common/interfaces';
import { BlogCard, BlogContainer, BlogListContainer } from './Blog.styled';

interface IBlogProps {
  blogDataSlides: IBlog[];
  panelSize: number;
  hasWideSibling: boolean;
  isWide: boolean;
}

const LIST_ITEM_CAP = 7;

export const Blog = ({ blogDataSlides, panelSize, hasWideSibling, isWide }: IBlogProps) => {
  const [responsivePanelSize, setResponsivePanelSize] = useState(panelSize);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setResponsivePanelSize(3);
      } else if (window.innerWidth <= 1000) {
        setResponsivePanelSize(2);
      } else {
        setResponsivePanelSize(panelSize);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [panelSize]);

  const slideCount = blogDataSlides.length;

  const displayList = () =>
    (hasWideSibling && responsivePanelSize === 2 && slideCount > 1) ||
    (responsivePanelSize === 3 && slideCount > 1) ||
    (responsivePanelSize === 2 && slideCount > 2) ||
    (responsivePanelSize === 1 && slideCount > 3);

  const itemsToDisplay = () => {
    switch (responsivePanelSize) {
      case 1:
        return slideCount <= 3 ? slideCount : 2;
      case 2:
        return slideCount === 1 && hasWideSibling ? 1 : hasWideSibling ? 0 : slideCount <= 2 ? slideCount : 1;
      case 3:
        return slideCount === 1 ? slideCount : 0;
      default:
        return 0;
    }
  };

  const listItems = blogDataSlides.slice(-Math.abs(blogDataSlides.length - itemsToDisplay()));
  const data =
    responsivePanelSize > 1 && listItems.length > LIST_ITEM_CAP
      ? listItems.slice(0, Math.min(LIST_ITEM_CAP, listItems.length - responsivePanelSize))
      : listItems;

  return (
    <BlogContainer $panelSize={responsivePanelSize}>
      {itemsToDisplay() > 0 && (
        <BlogCard $panelSize={responsivePanelSize}>
          {blogDataSlides
            ?.slice(0, itemsToDisplay())
            .map((blogItem) => (
              <BlogItem
                key={`${blogItem.posted}-${blogItem.title}`}
                data={blogItem}
                itemsToDisplay={itemsToDisplay()}
                panelSize={responsivePanelSize}
                isWide={isWide}
              />
            ))}
        </BlogCard>
      )}
      {displayList() && (
        <BlogListContainer data-testid="blog-list" className={`card${itemsToDisplay()}`}>
          <BlogList data={data} panelSize={responsivePanelSize} hasWideSibling={hasWideSibling} isWide={isWide} />
        </BlogListContainer>
      )}
    </BlogContainer>
  );
};
