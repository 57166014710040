import styled, { css } from 'styled-components';
import {
  Checkbox,
  Field,
  FieldStatus,
  Grid,
  GridCol,
  GridRow,
  ImportantIcon,
  Input,
  SlateRTE,
  type SlateToolbarControl,
  typeStyle,
  units
} from '@m/alchemy-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { type IChallenge } from '../../../common/interfaces';
import { useFieldError } from '../../../hooks';

interface TextWithInputProps {
  enabled: boolean | undefined;
}

const MilestoneOptionsContainer = styled.div`
  padding: ${units(2)} 7%;
`;
const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${units(1.5)};
  & > * {
    margin-right: ${units(1.5)};
  }
`;
const TextWithInput = styled.div<TextWithInputProps>`
  ${typeStyle('bodyS')};
  display: flex;
  align-items: center;
  & > * {
    margin: 0 ${units(0.5)};
  }
  ${(props) =>
    props.enabled
      ? css`
          input {
            min-width: 0;
          }
        `
      : css`
          opacity: 0.65;
          pointer-events: false;
          input {
            min-width: 0;
          }
        `}
`;
const OptionTitle = styled.div`
  ${typeStyle('labelL')};
`;
const StyledHelpText = styled.span`
  ${typeStyle('captionM')};
`;
const StyledTimeLimitInput = styled(Input)`
  width: ${units(8)};
`;
const StyledVotingWalletInput = styled(Input)`
  width: ${units(8)};
`;

const slateConfig = {
  buttons: ['bold', 'italic', 'unorderedList', 'undo', 'redo'] as SlateToolbarControl[]
};

export const MilestoneOptions = () => {
  const { values: challenge, setFieldValue, handleChange, handleBlur } = useFormikContext<IChallenge>();
  const { milestone, voting } = challenge;

  const isTimeLimitEnabled = Boolean(milestone?.timeLimitEnabled);

  /*
    Rich Text Editor defaultValue stored (and never updated) in state
    If defaultValue is unstable it causes rerenders on every input
  */
  const defaultValues = {
    completeMessageToAuthors: milestone?.completeMessageToAuthors || '',
    completeMessageToUsers: milestone?.completeMessageToUsers || ''
  };

  const intl = useIntl();

  const [fieldError] = useFieldError();

  return (
    <MilestoneOptionsContainer>
      <Grid>
        <GridRow>
          <GridCol>
            <FlexRow>
              <Checkbox
                label={
                  <OptionTitle>
                    {intl.formatMessage({ id: 'milestoneTimeLimit', defaultMessage: 'Set a time limit' })}
                  </OptionTitle>
                }
                data-testid="milestone-time-limit-checkbox"
                checked={isTimeLimitEnabled}
                onChange={({ target: { checked } }) => {
                  const payload = checked
                    ? { ...milestone, timeLimitEnabled: true }
                    : { ...milestone, timeLimitEnabled: false, daysLimit: 0 };

                  setFieldValue('milestone', payload);
                }}
              />
              <ImportantIcon aria-hidden="true" />
              <TextWithInput enabled={isTimeLimitEnabled}>
                <FormattedMessage
                  id="milestoneTimeLimitDetail"
                  defaultMessage="Each idea will have {input} {daysCount, plural, one {day} other {days}} to reach the final milestone"
                  values={{
                    input: (
                      <StyledTimeLimitInput
                        value={milestone?.daysLimit || ''}
                        isFullWidth
                        maxLength={4}
                        data-testid="milestone-daysLimit"
                        name="milestone.daysLimit"
                        onChange={handleChange}
                        disabled={!isTimeLimitEnabled}
                        onBlur={handleBlur}
                        aria-label={intl.formatMessage({
                          id: 'limitMilestone',
                          defaultMessage: 'Limit to reach the final milestone'
                        })}
                        {...(fieldError('milestone.daysLimit')
                          ? {
                              'aria-describedby': 'walletAllowanceError',
                              'aria-invalid': 'true'
                            }
                          : {})}
                      />
                    ),
                    daysCount: milestone?.daysLimit
                  }}
                />
                {fieldError('milestone.daysLimit') && (
                  <FieldStatus id="walletAllowanceError" message="" {...fieldError('milestone.daysLimit')} />
                )}
              </TextWithInput>
            </FlexRow>
          </GridCol>
        </GridRow>

        <GridRow>
          <GridCol>
            <FlexRow>
              <Checkbox
                label={
                  <OptionTitle>
                    {intl.formatMessage({ id: 'milestoneVotingWallet', defaultMessage: 'Enable voting Wallet' })}
                  </OptionTitle>
                }
                name="voting.wallet.isEnabled"
                data-testid="voting.wallet.isEnabled"
                checked={Boolean(voting?.wallet?.isEnabled)}
                onChange={handleChange}
              />
              <TextWithInput enabled={voting?.wallet?.isEnabled || undefined}>
                <FormattedMessage
                  id="milestoneVotingWalletDetail"
                  defaultMessage="Users will have an allowance of {input} {voteCount, plural, one {vote} other {votes}}"
                  values={{
                    input: (
                      <StyledVotingWalletInput
                        value={voting?.wallet?.allowance || ''}
                        name="voting.wallet.allowance"
                        data-testid="voting.wallet.allowance"
                        isFullWidth
                        maxLength={4}
                        disabled={!voting?.wallet?.isEnabled}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        aria-label={intl.formatMessage({
                          id: 'numberOfVotes',
                          defaultMessage: 'Number of votes'
                        })}
                        {...(fieldError('voting.wallet.allowance')
                          ? {
                              'aria-describedby': 'walletAllowanceError',
                              'aria-invalid': 'true'
                            }
                          : {})}
                      />
                    ),
                    voteCount: voting?.wallet?.allowance
                  }}
                />
                {fieldError('voting.wallet.allowance') && (
                  <FieldStatus id="walletAllowanceError" message="" {...fieldError('voting.wallet.allowance')} />
                )}
              </TextWithInput>
            </FlexRow>
          </GridCol>
        </GridRow>

        <GridRow>
          <GridCol span={{ small: 12, medium: 6 }}>
            <Field
              label={intl.formatMessage({
                id: 'milestoneCompleteMessageToAuthors',
                defaultMessage: 'Message to authors when all milestones completed'
              })}
              labelVariant="emphasized"
              isFullWidth
              helpText={
                <StyledHelpText>
                  {intl.formatMessage({
                    id: 'milestoneCompleteMessageToAuthorsHelpText',
                    defaultMessage:
                      'This message will be displayed to authors above ideas that have completed all milestones'
                  })}
                </StyledHelpText>
              }
              input={
                <SlateRTE
                  data-testid="milestone-completeMessageToAuthors"
                  initialValue={SlateRTE.deserialize(defaultValues.completeMessageToAuthors)}
                  controls={slateConfig.buttons}
                  onChange={(value) => setFieldValue('milestone.completeMessageToAuthors', SlateRTE.serialize(value))}
                />
              }
            />
          </GridCol>

          <GridCol span={{ small: 12, medium: 6 }}>
            <Field
              label={intl.formatMessage({
                id: 'milestoneCompleteMessageToUsers',
                defaultMessage: 'Message to users when all milestones completed'
              })}
              labelVariant="emphasized"
              isFullWidth
              helpText={
                <StyledHelpText>
                  {intl.formatMessage({
                    id: 'milestoneCompleteMessageToUsersHelpText',
                    defaultMessage:
                      'This message will be displayed to users above ideas that have completed all milestones'
                  })}
                </StyledHelpText>
              }
              input={
                <SlateRTE
                  data-testid="milestone-completeMessageToUsers"
                  initialValue={SlateRTE.deserialize(defaultValues.completeMessageToUsers)}
                  controls={slateConfig.buttons}
                  onChange={(value) => setFieldValue('milestone.completeMessageToUsers', SlateRTE.serialize(value))}
                />
              }
            />
          </GridCol>
        </GridRow>
      </Grid>
    </MilestoneOptionsContainer>
  );
};
