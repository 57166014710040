import styled, { css } from 'styled-components';
import { color, onHover, typeStyle, units } from '@m/alchemy-ui';

export const ActivityTimelineContent = styled.div`
  min-width: 185px;
  padding: ${units(2)};
`;

export const ActivityTimelineContentHeader = styled.div`
  display: flex;
  align-items: flex-start;
  svg {
    margin-right: ${units(1.5)};
    color: ${color('base')};
  }
`;

export const ActivityTimelineContentHeaderStart = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ActivityTimelineContentTimestamp = styled.div`
  ${typeStyle('captionM')};
  color: ${color('typeMeta')};
  margin-left: auto;
  & > div {
    display: flex;
  }
  button {
    padding: ${units(1)};
  }
  svg {
    margin: 0;
    width: ${units(3)};
    height: ${units(3)};
  }
`;

export const ActivityTimelineContentTitle = styled.h3`
  display: flex;
  align-items: center;
  ${typeStyle('headerS')};
`;

export const ActivityTimelineContentSubtitle = styled.small`
  ${typeStyle('bodyS')};
  color: ${color('contrast', { palette: 'interactiveNavigational' })};
  margin: 0 0 ${units(1.5)} 0;
  a {
    text-decoration: none;
    color: ${color('typeLink')};
    ${onHover(css`
      text-decoration: underline;
    `)}
  }
`;

export const ActivityTimelineContentBody = styled.div<{ isBodyCollapsed: boolean | undefined }>`
  position: relative;
  ${typeStyle('bodyS')};
  color: ${color('typeHeader')};
  margin: ${units(1.5)} 0 0 0;
  white-space: pre-line;
  max-height: ${({ isBodyCollapsed }) => (isBodyCollapsed ? 0 : 'none')};
  overflow: hidden;
  transition: all 0.6s ease-in;
`;

export const ActivityTimelineContentFooter = styled.div`
  padding-bottom: ${units(1)};
  button {
    display: block;
    margin: auto;
    > div {
      display: flex;
      align-items: center;
    }
    svg {
      margin-left: ${units(1.5)};
    }
  }
`;
