import { type ReactNode } from 'react';
import styled from 'styled-components';
import { color, units } from '@m/alchemy-ui';

const StyledButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${units(4)};
  margin-bottom: ${units(2)};
  padding: ${units(2)} ${units(1)} 0;
  border-top: 1px solid ${color('line')};
  & > * {
    margin-left: ${units(1)};
  }
`;

const LeftGroup = styled.div`
  flex: 1;
  margin: 0;
`;

export const ButtonRowLeftGroup = ({ children }: { readonly children: ReactNode }) => <LeftGroup>{children}</LeftGroup>;
export const ButtonRow = ({ children }: { readonly children: ReactNode }) => (
  <StyledButtonRow>{children}</StyledButtonRow>
);
