import { type Option, Select } from '@m/alchemy-ui';
import { type IPageChallenge } from '../../../../../common/interfaces';

interface IdeaSelectProps {
  challenges: IPageChallenge[];
  onValueChange: (challengeId: string) => void;
}

export const IdeaSelect = ({ challenges, onValueChange }: IdeaSelectProps) => (
  <Select
    data-testid="idea-select"
    defaultValue={challenges?.[0]?.id?.toString()}
    required
    isFullWidth
    options={
      challenges?.map((challenge) => ({
        key: challenge.id.toString(),
        label: challenge.title,
        value: challenge.id.toString()
      })) as Option[]
    }
    onChange={(option) => onValueChange(option.value)}
  />
);
