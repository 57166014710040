import { units } from '@m/alchemy-ui';
import styled from 'styled-components';

export const AccountRow = styled.div`
  display: flex;
  align-items: center;
  & > *:not(a) {
    margin-left: ${units(1)};
  }
`;

export const ProviderName = styled.span`
  display: inline-block;
  &:after {
    content: '—';
    display: inline-block;
    margin: 0 ${units(1)};
  }
`;
