import styled from 'styled-components';

export const LeaderboardFooterContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
`;

export const LeaderboardContainer = styled.div.attrs({
  'data-testid': 'leaderboard-tab-panel'
})``;
