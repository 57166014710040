import axios from 'axios';
import { Button, CloseIcon, IconButton, Input, LoadingIndicator, Toast } from '@m/alchemy-ui';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';
import { Body, ButtonRow, Container, FieldStatusMessage, Title } from '../../../../common/components';
import { isFormValid, validateResetPasswordForm } from '../../helpers/resetPasswordValidation';
import { getToastMessages } from '../../../../helpers';
import { UserContext } from '../../../../context';
import { ToastLevel, type ToastMessageType } from '../../../../common/enums';
import { usePasswordComplexity } from '../../../../context/use-password-complexity';
import { useActionKey } from '../../../../hooks';
import { PasswordResetHint } from './PasswordResetHint';
import * as Styled from './PasswordReset.styled';

export const PasswordResetForm = ({ updateHandler }: { readonly updateHandler: () => void }) => {
  const initialState = [{ message: { id: '', defaultMessage: '' }, type: '' }];
  const [oldPasswordErrors, setOldPasswordErrors] = useState(initialState);
  const [toastMessages, setToastMessages] = useState<ToastMessageType[]>();

  const intl = useIntl();
  const user = useContext(UserContext);
  const {
    passwordComplexityEnabled,
    passwordComplexityLengthMin,
    passwordComplexitySpecialMin,
    passwordComplexitySpecialChars,
    passwordComplexityLengthUpperMin,
    passwordComplexityLengthLowerMin,
    passwordComplexityLengthNumberMin
  } = usePasswordComplexity();

  const actionKey = useActionKey();

  const passwordComplexity = {
    passwordComplexityEnabled,
    passwordComplexityLengthMin,
    passwordComplexitySpecialMin,
    passwordComplexitySpecialChars,
    passwordComplexityLengthUpperMin,
    passwordComplexityLengthLowerMin,
    passwordComplexityLengthNumberMin
  };

  const initialFormValues = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: ''
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validate: (values) => validateResetPasswordForm(values, passwordComplexity),
    // it is just required
    onSubmit: () => {}
  });

  const handleFormSubmit = async ({
    oldPassword = '',
    newPassword = '',
    newPasswordConfirm = ''
  }: {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
  }) => {
    const request = new FormData();
    request.append('isNewProfileUi', 'true');
    request.append('passwordUpdate', 'true');
    request.append('user_screenname', String(user.screenName));
    request.append('newPassword', newPassword);
    request.append('user_passwordConfirm', newPasswordConfirm);
    request.append('oldPassword', oldPassword);
    request.append('bSubmit', 'Save');
    request.append('controller', 'user');
    request.append('action', 'settings');
    request.append('ak', actionKey);
    const { data } = await axios.post('/user/profile_update', request, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    });
    if (data?.object?.user_passwordOld) {
      const message = defineMessage({
        id: 'passwordOldIncorrectError',
        defaultMessage: 'Old password is incorrect.'
      });

      setOldPasswordErrors([
        {
          message,
          type: 'error'
        }
      ]);
    } else {
      setToastMessages(
        getToastMessages(
          ToastLevel.CONFIRM,
          intl.formatMessage({ id: 'saveSuccess', defaultMessage: 'Save successful' })
        )
      );
      setOldPasswordErrors(initialState);
      formik.resetForm();
      if (passwordComplexityEnabled && user.refetch) {
        user.refetch(); // refetch meQuery to have the freshest data for security reasons
      }

      updateHandler();
    }
  };

  const clearField = (fieldName: string) => formik.setFieldValue(fieldName, '');

  const renderStatusMessages = (
    messages: Array<{ message: { id: string; defaultMessage: string; values?: never }; type: string }>
  ) => messages?.map(({ message, type }) => <FieldStatusMessage key={message.id} message={message} type={type} />);

  const isSubmitButtonDisabled =
    !formik.values.oldPassword ||
    !formik.values.newPassword ||
    !formik.values.newPasswordConfirm ||
    // @ts-expect-error
    !isFormValid(formik.errors);

  if (user.loading) {
    return <LoadingIndicator />;
  }

  return (
    <Container>
      <Title>
        <FormattedMessage id="passwordReset" defaultMessage="Password reset" />
      </Title>
      <Body marginStrategy="bottom">
        <FormattedMessage
          id="passwordResetHelpText"
          defaultMessage="You can reset your password and click save. Your password will be instantly reset for the next time you log in."
        />
      </Body>
      <PasswordResetHint />

      <form autoComplete="off">
        <Styled.Field
          label={intl.formatMessage({ id: 'enterCurrentPassword', defaultMessage: 'Enter Current Password' })}
          input={
            <Input
              name="oldPassword"
              value={formik.values.oldPassword}
              onChange={formik.handleChange}
              type="password"
            />
          }
          endAdornment={
            <IconButton
              icon={<CloseIcon aria-hidden="true" size={12} />}
              tooltipProps={{ isOpen: false }}
              onClick={() => clearField('oldPassword')}
            />
          }
        />
        {oldPasswordErrors[0]?.message?.id && renderStatusMessages(oldPasswordErrors)}

        <Styled.Field
          label={intl.formatMessage({ id: 'enterNewPassword', defaultMessage: 'Enter New Password' })}
          input={
            <Input
              name="newPassword"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              type="password"
            />
          }
          endAdornment={
            <IconButton
              icon={<CloseIcon aria-hidden="true" size={12} />}
              tooltipProps={{ isOpen: false }}
              onClick={() => clearField('newPassword')}
            />
          }
        />
        {/* disabled, because formik.errors.newPassword is not a string it is an array */}
        {/* @ts-expect-error */}
        {formik.errors.newPassword && renderStatusMessages(formik.errors.newPassword)}

        <Styled.Field
          label={intl.formatMessage({ id: 'confirmNewPassword', defaultMessage: 'Confirm New Password' })}
          input={
            <Input
              name="newPasswordConfirm"
              value={formik.values.newPasswordConfirm}
              onChange={formik.handleChange}
              type="password"
            />
          }
          endAdornment={
            <IconButton
              icon={<CloseIcon aria-hidden="true" size={12} />}
              tooltipProps={{ isOpen: false }}
              onClick={() => clearField('newPasswordConfirm')}
            />
          }
        />
        {/* disabled, because formik.errors.newPasswordConfirm is not a string it is an array */}
        {/* @ts-expect-error */}
        {formik.errors.newPasswordConfirm && renderStatusMessages(formik.errors.newPasswordConfirm)}

        <Toast messages={toastMessages} />
        <ButtonRow>
          <Button onClick={() => handleFormSubmit(formik.values)} disabled={isSubmitButtonDisabled}>
            <FormattedMessage id="save" defaultMessage="Save" />
          </Button>
        </ButtonRow>
      </form>
    </Container>
  );
};
