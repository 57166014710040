import { forwardRef, useId } from 'react';
import styled, { css } from 'styled-components';
import { Button, color, DeleteIcon, Panel, snap, units, white } from '@m/alchemy-ui';
import { useIntl } from 'react-intl';

export interface Card {
  readonly cardImage?: JSX.Element;
  readonly cardTitle: JSX.Element | string;
  readonly description?: string;
  readonly isTemplate?: boolean;
  readonly onCardClick: () => void;
  readonly onDelete?: () => void;
}

const CardContentContainerBody = styled.div`
  display: none;
`;
const CardContentContainer = styled.div`
  position: absolute;
  width: 100%;
  background: ${white};
  text-align: center;
  min-height: ${snap(50)};
  padding: ${units(1.5)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardContainer = styled(Panel)<{ isTemplate: boolean }>`
  position: relative;
  min-height: ${snap(140)};
  max-width: ${snap(180)};
  margin: ${units(0.5)};
  overflow: hidden;
  cursor: pointer;
  border-radius: ${units(1.5)};
  z-index: 0;
  &:hover,
  &:focus,
  &:focus-within {
    z-index: 1;
    overflow: visible;
    border-color: ${color('base', { palette: 'interactiveConstructive' })};
    box-shadow: 0 0 0 ${units(0.25)} ${color('base', { palette: 'interactiveConstructive' })};

    ${({ isTemplate }) =>
      isTemplate
        ? css`
            ${CardContentContainer} {
              width: calc(100% + calc(3px * 2));
              margin: 0 -3px;
              border: 3px solid ${color('base', { palette: 'interactiveConstructive' })};
              border-top: none;
              border-radius: 0 0 ${units(1.5)} ${units(1.5)};
              display: block;
            }

            ${CardContentContainerBody} {
              display: block;
            }
          `
        : css``}
  }
  [class^='Panelstyled__Header'] {
    overflow: hidden;
    border-radius: ${units(1.5)} ${units(1.5)} 0 0;
  }
`;

const CardBodyContainer = styled.div`
  height: ${snap(90)};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: block;
    max-width: 100%;
  }
`;

const CardTitle = styled.div`
  [class^='Selectstyled__Toggle'] {
    border: none;
    color: ${color('contrast', { palette: 'illustrativeDeepPurple' })};
    background: none;
    box-shadow: none;
    min-height: unset;
    &:hover,
    &:focus {
      border: none;
    }
  }
  color: ${color('contrast', { palette: 'illustrativeDeepPurple' })};
  max-height: ${snap(50)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Description = styled.div`
  margin-bottom: ${units(0.5)};
  max-height: ${snap(100)};
  overflow-y: auto;
`;

const UseThisButton = styled(Button)`
  flex: 1;
  margin-right: ${units(0.5)};
`;

export const Card = forwardRef<HTMLDivElement, Card>(
  ({ cardImage, cardTitle, description, isTemplate = false, onCardClick, onDelete }, ref) => {
    const intl = useIntl();
    const cardTitleString = typeof cardTitle === 'string' ? cardTitle : '';
    const id = `${useId()}-${cardTitleString}-${description || ''}`;

    return (
      <CardContainer
        header={<CardBodyContainer>{cardImage}</CardBodyContainer>}
        // card container should support native dom events and tabIndex attr
        /* @ts-expect-error */
        onKeyDown={(e) => e.key === 'Enter' && onCardClick()}
        onClick={onCardClick}
        tabIndex={0}
        isTemplate={isTemplate}
        aria-label={cardTitleString}
        aria-describedby={id}
        ref={ref}
      >
        <CardContentContainer>
          <CardTitle>{cardTitle}</CardTitle>
          {isTemplate && (
            <CardContentContainerBody>
              {description && (
                <Description tabIndex={-1} id={id}>
                  {description}
                </Description>
              )}
              <ActionButtons>
                <UseThisButton priority="secondary" onClick={onCardClick}>
                  {intl.formatMessage({ id: 'useThis', defaultMessage: 'Use this' })}
                </UseThisButton>
                {onDelete && (
                  <Button
                    priority="secondary"
                    onClick={onDelete}
                    aria-label={`${intl.formatMessage({ id: 'delete', defaultMessage: 'Delete' })} ${cardTitleString}`}
                  >
                    <DeleteIcon aria-hidden="true" />
                  </Button>
                )}
              </ActionButtons>
            </CardContentContainerBody>
          )}
        </CardContentContainer>
      </CardContainer>
    );
  }
);

Card.displayName = 'Card';
