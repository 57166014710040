import { FormattedMessage, useIntl } from 'react-intl';
import { CommentBubbleIcon, Link } from '@m/alchemy-ui';
import { type IBlog } from '../../../../common/interfaces';
import { AVATAR_PLACEHOLDER, NO_IMAGE_PLACEHOLDER } from '../../common/const';
import {
  CardBody,
  CardBodyDescription,
  CardBodyTitle,
  CardFooter,
  CardFooterLink,
  CardHeader,
  CardHeaderImage,
  CardUser,
  CardUserAvatar,
  UserInfoContainer,
  UserName
} from './BlogItem.styled';
import { Card, StyledIcon } from './common/styles';
import { normalizeText } from './common/helpers';

declare function popUp(url: string, options: unknown): void;
interface IBlogItemProps {
  data: IBlog;
  itemsToDisplay: number;
  panelSize: number;
  isWide: boolean;
}

export const BlogItem = ({ data, itemsToDisplay, panelSize, isWide }: IBlogItemProps) => {
  const intl = useIntl();
  const openUserProfile = (userId: string) => {
    const url = `/user/view/${userId}`;
    // It is popUp in main js
    popUp(url, { popUpTitle: intl.formatMessage({ id: 'profile', defaultMessage: 'Profile' }) });
  };

  const imageSrc = data.imageUrl || NO_IMAGE_PLACEHOLDER;
  const largeImageSrc = data.imageLargeUrl || NO_IMAGE_PLACEHOLDER;
  const title = panelSize > 1 && !isWide ? normalizeText(data.title, 20) : data.title;
  const description = panelSize > 1 ? normalizeText(data.description, 130) : data.description;
  const authorName = panelSize === 2 ? data.author.name.slice(0, 20) : data.author.name.slice(0, 35);

  return (
    <Card className="mdl-panel">
      <CardHeader>
        {itemsToDisplay > 1 || panelSize > 1 ? (
          <CardHeaderImage src={imageSrc} alt="" />
        ) : (
          <CardHeaderImage src={largeImageSrc} alt="" />
        )}
      </CardHeader>
      <CardBody>
        <CardUser>
          <Link onClick={() => openUserProfile(data.author.id)}>
            <CardUserAvatar src={data.author.avatarUrl || AVATAR_PLACEHOLDER} />
          </Link>
          <UserInfoContainer>
            <FormattedMessage id="page.by" defaultMessage="by" />
            <UserName onClick={() => openUserProfile(data.author.id)}>{authorName}</UserName>
          </UserInfoContainer>
          <span data-testid="blog-item">{data.posted}</span>
        </CardUser>
        <CardBodyTitle>
          <a href={data.linkUrl} title={data.title}>
            {title}
          </a>
        </CardBodyTitle>
        <CardBodyDescription>{description}</CardBodyDescription>
      </CardBody>
      <CardFooter>
        <CardFooterLink href={data.linkUrl}>
          <FormattedMessage id="page.readMore" defaultMessage="Read more" />
        </CardFooterLink>
        <StyledIcon>
          <CommentBubbleIcon aria-hidden="true" />
        </StyledIcon>
        {` ${data.commentCount || 0}`}
      </CardFooter>
    </Card>
  );
};
