import styled from 'styled-components';
import { CommunityPageType } from '../../../../common/enums';

export const CommunityPageItem = styled.section<{
  isWide: boolean;
  isWideSibiling: boolean;
  isTabbed: boolean;
  isSingleTabbedItem: boolean;
}>`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  width: 0;
  margin: 0;
  background: #fff;

  & + & {
    margin-left: 30px;
  }

  &:first-child {
    margin-left: 4px;
  }

  &:last-child {
    margin-right: 4px;
  }

  @media (max-width: 600px) {
    margin: 12px !important;
    width: auto;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    margin: 12px 20px !important;
    width: auto;
  }

  ${(props) =>
    props.isWide &&
    `
      flex: initial;
      flex-basis: calc(66.6% - 2.5px);
      width: 66.66%;
    `}

  ${(props) =>
    props.isWideSibiling &&
    `
      flex-basis: calc(33.33% - 15px);
      width: 33.33%;
    `}

  ${(props) =>
    props.isTabbed &&
    `
      border: 2px solid #f4f4f4;
      border-bottom: 2px solid #c7c7c7;
      border-radius: 8px;
      max-height: 460px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    `}

  ${(props) =>
    !props.isSingleTabbedItem &&
    `
      min-height: 460px;
    `}
`;

export const SeparatorDiv = styled.section<{ isWide: boolean; isWideSibiling: boolean }>`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  flex: 1;
  justify-content: center;
  margin: 10px 20px;

  ${(props) =>
    props.isWide &&
    `
      flex: initial;
      flex-basis: calc(66.6% - 2.5px);
      width: 66.66%;
    `}

  ${(props) =>
    props.isWideSibiling &&
    `
      flex-basis: calc(33.33% - 15px);
      width: 33.33%;
    `}
`;

export const CommunityPageItemContent = styled.div<{ type: string }>`
  position: relative;
  height: 100%;
  padding-left: 5px;

  ${(props) =>
    (props.type === CommunityPageType.IMAGE ||
      props.type === CommunityPageType.VIDEO ||
      props.type === CommunityPageType.ACTIVITY ||
      props.type === CommunityPageType.LEADERBOARD ||
      props.type === CommunityPageType.IDEAS) &&
    `
      padding: 0;
    `}
`;
