import styled from 'styled-components';
import { typeStyle, units } from '@m/alchemy-ui';
import { useIntl } from 'react-intl';
import { FunnelChallengeIcon, MilestoneChallengeIcon, StandardChallengeIcon } from '../../../common/icons';

const StyledDiv = styled.div`
  padding: ${units(3)} 12%;
`;
const ChallengeTypesContainer = styled.div`
  margin-top: ${units(2)};
`;
const ChallengeType = styled.div`
  margin-bottom: ${units(2)};
  display: flex;
`;
const Icon = styled.div`
  margin-right: ${units(2)};
`;
const Type = styled.div``;
const Header = styled.h3`
  ${typeStyle('labelL')};
`;
const ChallengeTypeHeader = styled.h3`
  ${typeStyle('labelL')};
  text-transform: uppercase;
`;
const ChallengeTypeExplanation = styled.p`
  ${typeStyle('bodyS')};
`;

export const ChallengeTypes = () => {
  const intl = useIntl();

  return (
    <StyledDiv tabIndex={0}>
      <Header>
        {intl.formatMessage({ id: 'challengeTypesHeading', defaultMessage: 'What are the different Challenge Types?' })}
      </Header>
      <ChallengeTypesContainer>
        <ChallengeType>
          <Icon>
            <StandardChallengeIcon />
          </Icon>
          <Type>
            <ChallengeTypeHeader>
              {intl.formatMessage({
                id: 'standard',
                defaultMessage: 'Standard'
              })}
            </ChallengeTypeHeader>
            <ChallengeTypeExplanation>
              {intl.formatMessage({
                id: 'standardChallengeInfo',
                defaultMessage:
                  'For idea submission without a formal structure or process. Users can continuously submit ideas and comment and vote on them. You can return to this form to change a standard challenge into a funnel or milestone challenge at any time.'
              })}
            </ChallengeTypeExplanation>
          </Type>
        </ChallengeType>

        <ChallengeType>
          <Icon>
            <FunnelChallengeIcon />
          </Icon>
          <Type>
            <ChallengeTypeHeader>
              {intl.formatMessage({
                id: 'funnel',
                defaultMessage: 'Funnel'
              })}
            </ChallengeTypeHeader>
            <ChallengeTypeExplanation>
              {intl.formatMessage({
                id: 'funnelChallengeInfo',
                defaultMessage:
                  'Manage your challenge with customisable, timed "phases". Configure phase names, descriptions, start and end dates, user access permissions and idea submission forms.'
              })}
            </ChallengeTypeExplanation>
          </Type>
        </ChallengeType>

        <ChallengeType>
          <Icon>
            <MilestoneChallengeIcon />
          </Icon>
          <Type>
            <ChallengeTypeHeader>
              {intl.formatMessage({
                id: 'milestone',
                defaultMessage: 'Milestone'
              })}
            </ChallengeTypeHeader>
            <ChallengeTypeExplanation>
              {intl.formatMessage({
                id: 'milestoneChallengeInfo',
                defaultMessage:
                  'Use milestones to create a process or workflow through which ideas must progress. Milestone targets can be automatic or require manual approval from selected moderators.'
              })}
            </ChallengeTypeExplanation>
          </Type>
        </ChallengeType>
      </ChallengeTypesContainer>
    </StyledDiv>
  );
};
