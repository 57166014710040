import {
  ColorPicker as AlchemyColorPicker,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImageCap,
  EditIcon
} from '@m/alchemy-ui';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { type ColorPickerProps } from '../../features';

export const ColorPicker = ({ currentColor, name, onChange }: ColorPickerProps) => {
  const intl = useIntl();

  const defaultColor = currentColor || 'FFFFFF';
  const [selectedColor, setSelectedColor] = useState(`#${defaultColor}`);
  const [selectedColorObject, setSelectedColorObject] = useState({
    color: selectedColor,
    hex: selectedColor
  });
  return (
    <>
      <Card>
        <CardImageCap>
          <div
            style={{
              background: selectedColorObject.color,
              height: '80px',
              minWidth: '150px',
              width: '100%'
            }}
          />
        </CardImageCap>
        <CardHeader title={name} />
        <CardBody>
          <p>
            {`${intl.formatMessage({ id: 'color', defaultMessage: 'Color' })}:`} {selectedColorObject.hex}
          </p>
        </CardBody>
        <CardFooter>
          <AlchemyColorPicker
            hasColorField
            hasConfirmButtons
            selectedColor={selectedColor}
            onChange={(newColorObject) => {
              if (newColorObject.isValidCssColor) {
                setSelectedColor(newColorObject.color);
                setSelectedColorObject(newColorObject);

                if (onChange) {
                  onChange(newColorObject.hex);
                }
              }
            }}
            trigger={() => (
              <Button tabIndex={0} icon={<EditIcon aria-hidden />}>
                {intl.formatMessage({ id: 'edit', defaultMessage: 'Edit' })}
              </Button>
            )}
          />
        </CardFooter>
      </Card>
    </>
  );
};
