import { forwardRef } from 'react';
import { type GlobalTemplate, type Template } from '../../../common/core-api';
import {
  DefaultTemplateIcon,
  DiscussTemplateIcon,
  HackathonTemplateIcon,
  IncubatorTemplateIcon,
  PitchTemplateIcon,
  PollTemplateIcon,
  ProductImprovementTemplateIcon
} from '../../../common/icons';
import { GlobalTemplates } from '../../../common/enums';
import { Card } from './Card';

const globalTemplateIcons = [
  { name: GlobalTemplates.HACKATHON, icon: <HackathonTemplateIcon /> },
  { name: GlobalTemplates.INCUBATOR, icon: <IncubatorTemplateIcon /> },
  { name: GlobalTemplates.PITCH, icon: <PitchTemplateIcon /> },
  { name: GlobalTemplates.POLL, icon: <PollTemplateIcon /> },
  { name: GlobalTemplates.PRODUCTIMPROVEMENT, icon: <ProductImprovementTemplateIcon /> },
  { name: GlobalTemplates.DISCUSS, icon: <DiscussTemplateIcon /> }
];

interface TemplateCardProps {
  template: GlobalTemplate | Template;
  community?: boolean;
  handleTemplateSelect: (template: GlobalTemplate | Template) => void;
  handleTemplateDelete: (templateId: string) => void;
  userId: string;
}

export const TemplateCard = forwardRef<HTMLDivElement, TemplateCardProps>(
  ({ template, community = false, handleTemplateSelect, handleTemplateDelete, userId }, ref) => {
    const isCommunityTemplate = (item: GlobalTemplate | Template): item is Template =>
      (item as Template).userId !== undefined;

    const canBeDeleted = community && isCommunityTemplate(template) && template.userId === userId;
    const templateImageFileUrl = community && JSON.parse(template.template).files?.list?.url;

    const templateName = template.name.replace(/\s/g, '').toLowerCase();
    const icon = (community
      ? templateImageFileUrl && <img src={templateImageFileUrl} alt={template.name} />
      : globalTemplateIcons.find((template) => template.name === templateName)?.icon) || <DefaultTemplateIcon />;

    return (
      <Card
        cardImage={icon}
        cardTitle={template.name}
        key={template.id}
        description={template.description || undefined}
        isTemplate
        onCardClick={() => handleTemplateSelect(template)}
        onDelete={canBeDeleted ? () => handleTemplateDelete(template.id) : undefined}
        ref={ref}
      />
    );
  }
);

TemplateCard.displayName = 'TemplateCard';
