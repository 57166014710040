import { gql } from '@apollo/client';
import { type DocumentNode } from 'graphql';

export const GET_ACTIVITY: DocumentNode = gql`
  query getActivity($type: [Int!]!, $startDate: String!, $endDate: String!, $filters: UserSearchFilters) {
    stats(type: $type, startDate: $startDate, endDate: $endDate, filters: $filters) {
      total
      type
      date
    }
  }
`;

export const GET_VOTES_AND_COMMENTS: DocumentNode = gql`
  query getVotesAndComments(
    $publicationStatus: ChallengePublicationStatus
    $filters: UserSearchFilters
    $limit: Int
    $offset: Int
    $order: ColumnSortType
  ) {
    votesAndCommentsCount(
      publicationStatus: $publicationStatus
      filters: $filters
      limit: $limit
      offset: $offset
      order: $order
    ) {
      count
      rows {
        challengeId
        challengeTitle
        challengeCreatedAt
        voteCount
        commentCount
      }
    }
  }
`;

export const GET_DAILY_SUMMARY: DocumentNode = gql`
  query getDailySummary($challengeSelection: String, $filters: UserSearchFilters, $startDate: String) {
    dailySummary(challengeSelection: $challengeSelection, filters: $filters, startDate: $startDate) {
      total
      created
    }
  }
`;

export const GET_THEME_IDEAS_SUMMARY: DocumentNode = gql`
  query getThemeIdeasSummary($challengeSelection: String, $themeSelection: String, $filters: UserSearchFilters) {
    themeIdeasSummary(challengeSelection: $challengeSelection, themeSelection: $themeSelection, filters: $filters) {
      themeId
      themeName
      ideaCount
    }
  }
`;

export const GET_IDEA_STATUSES_SUMMARY: DocumentNode = gql`
  query getIdeaStatusesSummary($challengeSelection: String, $filters: UserSearchFilters) {
    ideaStatusesSummary(challengeSelection: $challengeSelection, filters: $filters) {
      statusId
      statusName
      ideaCount
    }
  }
`;

export const GET_USER_LOGIN: DocumentNode = gql`
  query getUserLogin($startDate: String!, $endDate: String!, $filters: UserSearchFilters) {
    userLoginStats(startDate: $startDate, endDate: $endDate, filters: $filters) {
      total
      created
    }
  }
`;

export const GET_IDEA_TAGS_SUMMARY: DocumentNode = gql`
  query getIdeaTagsSummary($challengeSelection: String, $filters: UserSearchFilters) {
    ideaTagsSummary(challengeSelection: $challengeSelection, filters: $filters) {
      tagId
      tagName
      ideaCount
    }
  }
`;
