import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ArrowRightIcon,
  Button,
  CancelXIcon,
  DeleteIcon,
  EditIcon,
  Field,
  LockIcon,
  OverflowScroller,
  Popper,
  ShowEyeIcon,
  snap,
  TeamIcon,
  ThumbsUpIcon
} from '@m/alchemy-ui';
import { useFormikContext } from 'formik';
import { PhasesEditType, TimelineItemType, WarningType } from '../../../common/enums';
import { type ActivityTimelineItem, defaultPhase, type IChallenge } from '../../../common/interfaces';
import { CommunityContext } from '../../../context';
import { useChallenge } from '../context/Challenge';
import { DEFAULT_DATE, hasPhaseEnded, hasPhaseStarted, updatePhasesStartAndEndDate } from '../helpers/phase';
import { type Phase, VotingType } from '../../../common/core-api';
import { PhaseForm } from './PhaseForm';
import * as Styled from './FunnelEditor.styled';
import { ActivityTimeline, PhaseAddButton, PhaseInfo, PhaseInfoWithWarning, Warning } from '.';

export const FunnelEditor = () => {
  const { challenge } = useChallenge();
  const { values, setFieldValue, status } = useFormikContext<IChallenge>();
  const intl = useIntl();
  const community = useContext(CommunityContext);

  const phases = values.phases || [];
  const phaseCount = phases.length;

  const hasIdeas = Number(values.ideas?.length) > 0;

  const [phaseDeleteWarningOpen, setPhaseDeleteWarningOpen] = useState(false);
  const [phaseToDelete, setPhaseToDelete] = useState<Phase>();
  const [openPhase, setOpenPhase] = useState<number | null>(null);

  const errorPhaseIndex = challenge.focusError?.name?.includes('phases') ? challenge.focusError?.itemIndex : null;

  useEffect(() => {
    const phase = {
      ...defaultPhase,
      access: {
        users: community.defaultAccess?.users,
        groups: defaultPhase.access.groups,
        options: defaultPhase.access.options
      }
    };

    if (phaseCount === 0) {
      setFieldValue('phases', [phase]);
    }

    if (errorPhaseIndex !== null && !status?.saving) {
      setOpenPhase(errorPhaseIndex);
    }
  }, [errorPhaseIndex, status?.saving, community.defaultAccess?.users, phaseCount, setFieldValue]);

  const phaseVotingType = {
    [VotingType.UPDOWN]: intl.formatMessage({ id: 'phaseThumbsUpDownVoting', defaultMessage: 'Up/down voting' }),
    [VotingType.UP]: intl.formatMessage({ id: 'phaseThumbsUpVoting', defaultMessage: 'Up voting' }),
    [VotingType.STARS]: intl.formatMessage({ id: 'phaseStarsVoting', defaultMessage: 'Star rating' }),
    [VotingType.POINTSAWARD]: intl.formatMessage({ id: 'phasePointsVoting', defaultMessage: 'Points rating' }),
    [VotingType.SCORECARD]: intl.formatMessage({ id: 'phaseScorecardVoting', defaultMessage: 'Scorecard' })
  };

  const getPhaseTimeStatus = (startDate: string, endDate: string) => {
    const startTimeInSeconds = Date.parse(startDate) / 1000;
    const endTimeInSeconds = Date.parse(endDate) / 1000;
    const currentTimeInSeconds = Date.now() / 1000;
    const secondsUntilStart = startTimeInSeconds - currentTimeInSeconds;

    if (secondsUntilStart > 0) {
      if (secondsUntilStart > 86_400) {
        const daysLeft = Math.round(secondsUntilStart / 86_400);
        return (
          <FormattedMessage
            id="phaseStartsInDays"
            defaultMessage="Starts in {daysLeft} {daysLeft, plural, one {day} other {days}}"
            values={{
              daysLeft
            }}
          />
        );
      }

      if (secondsUntilStart > 3600) {
        const hoursLeft = Math.round(secondsUntilStart / 3600);
        return (
          <FormattedMessage
            id="phaseStartsInHours"
            defaultMessage="Starts in {hoursLeft} {hoursLeft, plural, one {hour} other {hours}}"
            values={{
              hoursLeft
            }}
          />
        );
      }

      if (secondsUntilStart > 60) {
        const minutesLeft = Math.round(secondsUntilStart / 60);
        return (
          <FormattedMessage
            id="phaseStartsInMinutes"
            defaultMessage="Starts in {minutesLeft} {minutesLeft, plural, one {minute} other {minutes}}"
            values={{
              minutesLeft
            }}
          />
        );
      }

      return intl.formatMessage({ id: 'phaseStartsInLessThanAMinute', defaultMessage: 'Starts in less than a minute' });
    }

    if (
      endTimeInSeconds < currentTimeInSeconds &&
      startTimeInSeconds < currentTimeInSeconds &&
      endDate !== DEFAULT_DATE
    ) {
      return intl.formatMessage({ id: 'finished', defaultMessage: 'Finished' });
    }

    return intl.formatMessage({ id: 'inProgress', defaultMessage: 'In progress' });
  };

  /* eslint-disable-next-line complexity */
  const getPhaseBlock = (phase: Phase, phaseIndex = 0) => {
    const { access, name, voting, startDate, endDate } = phase;

    const isFirstPhase = values.phases?.findIndex((p) => p.startDate === startDate) === 0 || phaseCount === 0;

    const accessUsers = access.users || [];
    const accessGroups = access.groups || [];
    const everyoneGroup = accessGroups.find((group) => group.usergroup.id === '0');

    const userModeratorCount = accessUsers.filter((user) => user.canModerate).length;
    const groupModeratorCount = accessGroups.filter((group) => group.canModerate).length;
    const moderatorCount = userModeratorCount + groupModeratorCount;
    const everyoneIsModerator = everyoneGroup?.canModerate;

    const userAccessCount = accessUsers.filter((user) => !user.canModerate).length;
    const groupAccessCount = accessGroups.filter((group) => !group.canModerate).length;
    const accessCount = userAccessCount + groupAccessCount;
    const everyoneHasAccess = Boolean(everyoneGroup);

    return {
      title: name || '<unnamed phase>',
      subtitle: getPhaseTimeStatus(startDate as string, endDate as string),
      timestamp: (
        <div>
          <Popper
            placement="bottom"
            shouldCloseOnOutsideInteraction={false}
            isOpen={openPhase === phaseIndex}
            trigger={
              <Button
                // This tricky part is due to we're using popper as a dialog instead of use the dialog component
                onFocus={() => openPhase === phaseIndex && setOpenPhase(null)}
                priority="tertiary"
                onClick={() => setOpenPhase(phaseIndex)}
                aria-label={intl.formatMessage({
                  id: 'editPhase',
                  defaultMessage: 'Edit phase'
                })}
              >
                <EditIcon aria-hidden="true" />
              </Button>
            }
            overlay={
              <Styled.PhaseFormPopper hasArrow maxWidth={snap(800)}>
                <PhaseForm currentPhase={phase} phaseIndex={phaseIndex} setPopperOpen={() => setOpenPhase(null)} />
              </Styled.PhaseFormPopper>
            }
          />
          {!isFirstPhase && !hasPhaseStarted(phase.startDate as string) && (
            <Button
              aria-label={intl.formatMessage({
                id: 'deletePhase',
                defaultMessage: 'Delete Phase'
              })}
              priority="tertiary"
              onClick={() => {
                setPhaseToDelete(phase);
                setPhaseDeleteWarningOpen(true);
              }}
            >
              <DeleteIcon aria-hidden="true" />
            </Button>
          )}
        </div>
      ),
      body: (
        <div>
          {!name && (
            <PhaseInfoWithWarning
              label={intl.formatMessage({
                id: 'phaseNameIsEmpty',
                defaultMessage: 'Phase name is empty'
              })}
              message={intl.formatMessage({
                id: 'phaseNameNeedsToBeDefined',
                defaultMessage: 'To be able to continue, phase name should be added'
              })}
              leftMargin
            />
          )}
          {access.options.phaseAutoAdvancement && (
            <PhaseInfo
              icon={<ArrowRightIcon aria-hidden="true" />}
              message={intl.formatMessage({ id: 'phaseIdeaAutoAdvance', defaultMessage: 'Ideas auto-advance' })}
            />
          )}
          {access.options.privateSubmissionEnabled && (
            <PhaseInfo
              icon={<LockIcon aria-hidden="true" />}
              message={intl.formatMessage({ id: 'phasePrivateSubmission', defaultMessage: 'Private idea submissions' })}
            />
          )}
          <PhaseInfo
            icon={<ThumbsUpIcon aria-hidden="true" />}
            message={voting.type ? phaseVotingType[voting.type] : ''}
          />
          {voting.isBlind && !voting.showVoters && (
            <PhaseInfo
              icon={<ShowEyeIcon aria-hidden="true" />}
              message={intl.formatMessage({ id: 'phaseBlindVoting', defaultMessage: 'Blind voting' })}
            />
          )}
          {voting.isBlind && voting.showVoters && (
            <PhaseInfoWithWarning
              label={intl.formatMessage({ id: 'phaseBlindVoting', defaultMessage: 'Blind voting' })}
              message={intl.formatMessage({
                id: 'phaseBlindVotingWithShowVoters',
                defaultMessage:
                  'Show voters is also enabled, so individual user votes will be visible but the score will be hidden'
              })}
              leftMargin
            />
          )}
          {voting.showVoters && (
            <PhaseInfo
              icon={<ShowEyeIcon aria-hidden="true" />}
              message={intl.formatMessage({ id: 'phaseShowVoters', defaultMessage: 'Show voters' })}
            />
          )}
          {access.options.phaseResetVotes && (
            <PhaseInfo
              icon={<CancelXIcon aria-hidden="true" />}
              message={intl.formatMessage({ id: 'phaseVotesReset', defaultMessage: 'Votes reset' })}
            />
          )}
          {access.options.phaseResetComments && (
            <PhaseInfo
              icon={<CancelXIcon aria-hidden="true" />}
              message={intl.formatMessage({ id: 'phaseCommentsCleared', defaultMessage: 'Comments cleared' })}
            />
          )}
          {moderatorCount > 0 && (
            <Styled.PhaseInfo>
              <Styled.PhaseInfoIconWrapper>
                <TeamIcon aria-hidden="true" />
              </Styled.PhaseInfoIconWrapper>
              <Styled.CountLabel>
                {intl.formatMessage({
                  id: 'moderators',
                  defaultMessage: 'Moderators'
                })}
              </Styled.CountLabel>
              {everyoneIsModerator && (
                <PhaseInfoWithWarning
                  label={intl.formatMessage({ id: 'everyone', defaultMessage: 'Everyone' })}
                  message={intl.formatMessage({
                    id: 'everyoneModeratorWarning',
                    defaultMessage: 'Setting all users as moderators is not recommended'
                  })}
                  leftMargin={false}
                />
              )}
              {!everyoneIsModerator && userModeratorCount > 0 && (
                <Styled.Count>
                  <FormattedMessage
                    id="userModerators"
                    defaultMessage="{userModeratorCount} {userModeratorCount, plural, one {user} other {users}}"
                    values={{
                      userModeratorCount
                    }}
                  />
                </Styled.Count>
              )}
              {!everyoneIsModerator && groupModeratorCount > 0 && (
                <Styled.Count>
                  <FormattedMessage
                    id="groupModerators"
                    defaultMessage="{groupModeratorCount} {groupModeratorCount, plural, one {group} other {groups}}"
                    values={{
                      groupModeratorCount
                    }}
                  />
                </Styled.Count>
              )}
            </Styled.PhaseInfo>
          )}
          {moderatorCount === 0 && (
            <PhaseInfoWithWarning
              label={intl.formatMessage({ id: 'noModerators', defaultMessage: 'No moderators' })}
              message={intl.formatMessage({
                id: 'noModeratorMessage',
                defaultMessage: 'No moderators are configured for this phase'
              })}
              leftMargin
            />
          )}
          {accessCount > 0 && (
            <Styled.PhaseInfo>
              <Styled.PhaseInfoIconWrapper>
                <TeamIcon aria-hidden="true" />
              </Styled.PhaseInfoIconWrapper>
              <Styled.CountLabel>
                {intl.formatMessage({
                  id: 'users',
                  defaultMessage: 'Users'
                })}
              </Styled.CountLabel>
              {everyoneHasAccess && <div>{intl.formatMessage({ id: 'everyone', defaultMessage: 'Everyone' })}</div>}
              {!everyoneHasAccess && userAccessCount > 0 && (
                <Styled.Count>
                  <FormattedMessage
                    id="userAccesses"
                    defaultMessage="{userAccessCount} {userAccessCount, plural, one {user} other {users}}"
                    values={{
                      userAccessCount
                    }}
                  />
                </Styled.Count>
              )}
              {!everyoneHasAccess && groupAccessCount > 0 && (
                <Styled.Count>
                  <FormattedMessage
                    id="groupAccesses"
                    defaultMessage="{groupAccessCount} {groupAccessCount, plural, one {group} other {groups}}"
                    values={{
                      groupAccessCount
                    }}
                  />
                </Styled.Count>
              )}
            </Styled.PhaseInfo>
          )}
        </div>
      )
    };
  };

  const getTimelineItems = () => {
    let items: ActivityTimelineItem[] = [];

    phases.forEach((phase, i) => {
      if (i === phaseCount - 1) {
        items = [
          ...items,
          { content: [getPhaseBlock(phase, i)], type: TimelineItemType.PHASE_BLOCK },
          { type: TimelineItemType.PHASE_BULLET },
          {
            customIndicator: {
              indicator: (
                <PhaseAddButton
                  message={intl.formatMessage({ id: 'add', defaultMessage: 'Add' })}
                  previousPhase={phase}
                />
              )
            },
            type: TimelineItemType.PHASE_BUTTON
          },
          { type: TimelineItemType.PHASE_BULLET }
        ];
        return;
      }

      items.push({ content: [getPhaseBlock(phase, i)], type: TimelineItemType.PHASE_BLOCK });

      if (!hasIdeas && !hasPhaseEnded(phase.endDate as string)) {
        items.push({
          customIndicator: {
            indicator: (
              <PhaseAddButton
                message={intl.formatMessage({ id: 'insert', defaultMessage: 'insert' })}
                previousPhase={phase}
                afterPhase={phases[i + 1]}
              />
            )
          },
          type: TimelineItemType.PHASE_BUTTON
        });
      }
    });

    return items;
  };

  const handlePhaseDelete = (phase: Phase) => {
    let indexOfPhase: number = phaseCount;
    let newPhasesValue = phases.filter((p, i) => {
      if (p.startDate === phase.startDate) {
        indexOfPhase = i;
        return false;
      }

      return true;
    });
    newPhasesValue = updatePhasesStartAndEndDate(newPhasesValue, indexOfPhase, PhasesEditType.DELETE);
    setFieldValue('phases', newPhasesValue);
  };

  const handlePhaseDeleteConfirm = () => {
    phaseToDelete && handlePhaseDelete(phaseToDelete);
    setPhaseDeleteWarningOpen(false);
    setPhaseToDelete(undefined);
  };

  const handlePhaseDeleteCancel = () => {
    setPhaseDeleteWarningOpen(false);
    setPhaseToDelete(undefined);
  };

  return (
    <Styled.StyledDiv>
      <Field
        required
        labelVariant="emphasized"
        isFullWidth
        label={intl.formatMessage({ id: 'phases', defaultMessage: 'Phases' })}
        input={
          <OverflowScroller>
            <ActivityTimeline items={getTimelineItems()} />
          </OverflowScroller>
        }
      />
      <Warning
        isOpen={phaseDeleteWarningOpen}
        onClose={handlePhaseDeleteCancel}
        onConfirm={handlePhaseDeleteConfirm}
        warningType={WarningType.PHASE_DELETE}
      />
    </Styled.StyledDiv>
  );
};
