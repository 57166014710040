import { type IChallengeCarouselItem } from '../../../../common/interfaces';
import { NO_IMAGE_PLACEHOLDER } from '../../common/const';
import { useRuntimeCommunity } from '../../../../context';
import {
  Slide,
  SlideDescription,
  SlideImage,
  SlideLink,
  SlideStatIcon,
  SlideStatItem,
  SlideStats,
  SlideSubtitle,
  SlideTitle
} from './ChallengeCarouselSlide.styled';

interface CarouselSlideProps {
  slideData: IChallengeCarouselItem;
  hideDescription: boolean;
}

export const ChallengeCarouselSlide = ({ slideData, hideDescription }: CarouselSlideProps) => {
  const { colors } = useRuntimeCommunity();
  return (
    <Slide data-testid="challenge-carousel-slide">
      <SlideLink href={slideData?.linkUrl} title={slideData?.title}>
        <SlideImage>
          <img src={slideData.imageUrl || NO_IMAGE_PLACEHOLDER} alt={slideData?.title} />
          {!hideDescription && (
            <SlideDescription id="slide-description" bgColor={colors.button}>
              <div>
                <SlideTitle>{slideData?.title}</SlideTitle>
                <SlideSubtitle>{slideData?.subtitle || ''}</SlideSubtitle>
              </div>
              <SlideStats>
                <SlideStatItem>
                  <SlideStatIcon className="mdl-icon -s">
                    <svg>
                      <use href="#idea" />
                    </svg>
                  </SlideStatIcon>
                  {slideData?.ideaCount}
                </SlideStatItem>
              </SlideStats>
            </SlideDescription>
          )}
        </SlideImage>
      </SlideLink>
    </Slide>
  );
};
