import { Checkbox, DateRangePicker, Dialog, Field, Flex, units } from '@m/alchemy-ui';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import axios from 'axios';
import styled from 'styled-components';
import { format } from 'date-fns';

const StyledFlex = styled(Flex)`
  margin-left: ${units(0.3)};
`;
const StyledError = styled.p`
  color: red;
`;
const today = new Date();

export const UsersDownload = () => {
  const intl = useIntl();
  const dialogRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const close = () => setIsOpen(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [includeBadgeAwards, setIncludeBadgeAwards] = useState(false);
  const [error, setError] = useState(false);

  const handleChecboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeBadgeAwards(e.target.checked);
  };

  const handleDownload = () => {
    setError(false);
    try {
      setIsLoading(true);
      axios
        .get(
          `/user/admin_view?getCSV=1&includeBadges=${String(includeBadgeAwards)}&statsBreakdownFromDate=${startDate && format(startDate, 'yyyy-MM-dd')} 00:00:00&statsBreakdownToDate=${endDate && format(endDate, 'yyyy-MM-dd')} 23:59:59&show=all&orderBy:name&userList_total=-1`,
          {
            headers: { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'text/csv' }
          }
        )
        .then((response) => {
          if (dialogRef.current) {
            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'users.csv');
            dialogRef.current.appendChild(link);
            link.click();
            dialogRef.current.removeChild(link);
          }
        });
    } catch (error) {
      setError(true);
      console.log('Error downloading CSV', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div data-testid="users-download-container" ref={dialogRef}>
      <Dialog
        isOpen={isOpen}
        onClose={close}
        headerContent={intl.formatMessage({ id: 'downloadAsCSV', defaultMessage: 'Download as CSV' })}
        bodyContent={
          <>
            <StyledFlex direction="column" alignItems="flex-start" justifyContent="center" gap={10}>
              <p data-testid="labelDownloadAsCSVDesc">
                {intl.formatMessage({
                  id: 'downloadAsCSVDesc',
                  defaultMessage: 'Download the list of all active users as a .csv file.'
                })}
              </p>
              <DateRangePicker
                start={{
                  label: `${intl.formatMessage({ id: 'start', defaultMessage: 'Start' })}:`,
                  description: intl.formatMessage({
                    id: 'showActivityFrom',
                    defaultMessage:
                      'The download includes the total number of ideas, comments and votes that users have posted since the specified date'
                  }),
                  date: startDate
                }}
                end={{
                  label: `${intl.formatMessage({ id: 'end', defaultMessage: 'End' })}:`,
                  date: endDate,
                  description: intl.formatMessage({
                    id: 'showActivityTo',
                    defaultMessage:
                      'The download includes the total number of ideas, comments and votes that users have posted until the end of the day on the specified date'
                  })
                }}
                onDateRangeChange={(newStartDate, newEndDate) => {
                  setStartDate(newStartDate);
                  setEndDate(newEndDate);
                }}
                calendarProps={{ disabled: { after: today } }}
              />
              <Field
                input={
                  <Checkbox
                    onChange={handleChecboxChange}
                    checked={includeBadgeAwards}
                    label={intl.formatMessage({
                      id: 'includeBadgeAwards',
                      defaultMessage: 'Include badge awards'
                    })}
                  />
                }
                description={
                  includeBadgeAwards
                    ? intl.formatMessage({
                        id: 'includeBadgeAwardsDesc',
                        defaultMessage: 'Badge award data is affected by selected date range'
                      })
                    : intl.formatMessage({
                        id: 'notIncludeBadgeAwardsDesc',
                        defaultMessage: 'Badge award data is not affected by selected date range'
                      })
                }
                isFullWidth
              />
              {error && (
                <StyledError data-testid="users-download-container-error">
                  {intl.formatMessage({
                    id: 'somethingWentWrong',
                    defaultMessage: 'Something went wrong.'
                  })}
                </StyledError>
              )}
            </StyledFlex>
          </>
        }
        footerButtons={[
          {
            label: intl.formatMessage({ id: 'downloadAsCSV', defaultMessage: 'Download as CSV' }),
            onClick: handleDownload,
            disabled: !startDate || !endDate,
            isLoading
          }
        ]}
        hasCloseIcon
        shouldFocusContainerOnOpen
        size="medium"
        aria-describedby="customDescribedBy"
      />
    </div>
  );
};
